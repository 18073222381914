import React from 'react';
import moment from 'moment';
import { TableRow, TableCell, TextField, Tooltip, IconButton } from '@material-ui/core';
import { TableRowActions } from '../../shared_elements';
import { displayDateFormatShort } from '../../constants';
import GetAppIcon from '@material-ui/icons/GetApp';
const Attachment = ({currentFormDetail, item, onFieldChange, previewDetail}) => {
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>{item.name}</TableCell>
      <TableCell>
        { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
          <TextField
            id="description"
            fullWidth
            margin="normal"
            value={item.description ? item.description:''}
            InputLabelProps={{shrink: true}}
            onChange={(e) => onFieldChange(e, 'description', e.target.value)}
          />:
          item.description ? item.description:'--'
        }
      </TableCell>
      <TableCell>{item.created_at ? moment(item.created_at).format(displayDateFormatShort):'--'}</TableCell>
      <TableCell>{item.user.name}</TableCell>
      <TableCell className="actions-cell" style={{width: '120px'}}>
        <ul className="list-inline">
          <li className="list-inline-item">
            { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
              <TableRowActions
                actions={['D']}
                previewDetail={(mode) => previewDetail(mode)}
              />:null
            }
          </li>
          <li className="list-inline-item">
            <Tooltip title="Download File" arrow>
              <IconButton size="small" color="primary">
                <a href={item.attachment} download><GetAppIcon fontSize="small" /></a>
              </IconButton>
            </Tooltip>
          </li>
        </ul>
      </TableCell>
    </TableRow>
  )
}
export default Attachment;
