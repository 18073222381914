import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import { useSnackbar } from 'notistack';
import { Paper, Table, TableBody, Grid, Button, Tooltip } from '@material-ui/core';
import ObservationHeader from './ObservationHeader';
import Observation from './Observation';
import SectionDetail from './SectionDetail';
import SortSections from './SortSections';
import ImportTemplate from './ImportTemplate';
import { DeletePopUp, EmptyCollection, PageLoader } from '../../../shared_elements';
import { checkApiStatus, downloadFileType, getLocalStorageInfo, onCheckFormPermission } from '../../../utils_v2';
import { globalGetService, globalPutService, globalPostService, globalDeleteService } from '../../../utils_v2/globalApiServices';
import { Skeleton } from '@material-ui/lab';
import { Height } from '@material-ui/icons';
const ObservationList = ({params, currentFormDetail}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [assetSections, setAssetSections] = useState({list:[]});
    const [section, setSection] = useState({modal:false, mode:'add', data:null});
    const [deleteSection, setDeleteSection] = useState({modal:false, data:null});
    useEffect(() => {
        getAssetSections('skeletonLoader');
    },[]);
    const getAssetSections = (loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true)
        globalGetService(`technical/v2/workorder/${params.workOrderSlug}/asset-sections/`)
        .then(response => {
            if(checkApiStatus(response)){
                setAssetSections(response.data.data);
            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false)
        })
    }
    const onDeleteSection = () => {
        globalDeleteService(`technical/v2/workorder/${params.workOrderSlug}/asset-sections/${deleteSection.data.id}/`)
        .then(response => {
            if(checkApiStatus(response)){
                setDeleteSection({modal:false, data:null});
                getAssetSections('pageLoader');
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }
        })
    }
    const onEditSectionDetail = (section) => {
        globalGetService(`technical/v2/workorder/${params.workOrderSlug}/asset-sections/${section.id}/`)
        .then(response => {
            if(checkApiStatus(response)){
                setSection({modal:true, mode:'edit', data:response.data.data});
            }
        })
    }
    return(
        <>
            {skeletonLoader ? <Skeleton  height={440}/>:
                <div className='observation-table'>
                    {onCheckFormPermission(currentFormDetail) ?
                        <div style={{margin:'10px 0'}}>
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item md={6}>
                                    <ul className='list-inline'>
                                        <li className='list-inline-item'>
                                            <Button onClick={() => setSection({modal:true,mode:'add', data:{name:'',order:assetSections.list.length+1, observations:[]}})} color='primary' size='small' variant='contained'>Add Section</Button>
                                        </li>
                                    </ul>
                                </Grid>
                                <Grid item md={6}>
                                    <ul className='list-inline flex-centered' style={{float:'right'}}>
                                        {assetSections?.list?.length ?
                                            <li className='list-inline-item'>
                                                <SortSections 
                                                    url={`technical/v2/workorder/${params.workOrderSlug}/inspection_asset_sections_order/`}
                                                    toolTipContent='Sort Sections'
                                                    assetSections={assetSections.list.map((section) => {return{name:section.name, id: section.id}})} 
                                                    getResponseBack={() => getAssetSections('pageLoader')}
                                                />
                                            </li>:null
                                        }
                                    </ul>
                                </Grid>
                            </Grid>
                        </div>:null
                    }
                    <Paper>
                        <Table className='mui-table-format'>
                            <ObservationHeader />
                            <TableBody>
                                { assetSections.list.map((section, sectionIndex) =>
                                    <Observation 
                                        currentFormDetail={currentFormDetail}
                                        section={section}
                                        onEdit={(type) => onEditSectionDetail(section)}
                                        onDelete={() => setDeleteSection({modal:true, data:{name:section.name, id:section.id}})}
                                    />
                                )}
                            </TableBody>
                        </Table>
                        {!assetSections?.list?.length ? <div style={{textAlign:'center'}}><EmptyCollection title='No records found' /></div>:null}
                    </Paper>
                </div>
            }
            
            {!skeletonLoader && assetSections?.list.length === 0 && onCheckFormPermission(currentFormDetail) ?
                <ImportTemplate 
                    getResponseBack={() => getAssetSections('pageLoader')}
                />:null
            }
            {section.modal ?
                <SectionDetail 
                    currentFormDetail={currentFormDetail}
                    assetSections={assetSections?.list.map(item => {return{id:item.id, name:item.name}})}
                    section={section} 
                    toggleModalFn={() => setSection({modal:false,mode:'', data:null})}
                    getResponseBack={() => getAssetSections('pageLoader')}
                />:null
            }
            { deleteSection.modal ?
                <DeletePopUp
                    modal={deleteSection.modal}
                    toggleModalFn={() => setDeleteSection({modal:false, data:null})}
                    title={`Delete "${deleteSection.data.name}" Section`}
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={onDeleteSection}
                />:null
            }
            { isLoading ? <PageLoader/>:null}
        </>
        
    )
}
export default withRouter(ObservationList);