import React, { useEffect, useState, Fragment } from 'react';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import { Table, TableBody, TableRow, TableCell, Button, Paper, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import AssignEngineer from './AssignEngineer';
import { CkEditorText, PageLoader } from '../../../shared_elements';
import { globalGetService, globalPostService, globalExportService } from '../../../utils_v2/globalApiServices'
import { checkApiStatus, downloadFileType, onCheckPermission, dateTransformAllSet } from '../../../utils_v2'
import EditIcon from '@material-ui/icons/Edit';
const FormT012 = ({params, project}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [t012Info, setT012Info] = useState({});
    const [rejectionInfo, setRejectionInfo] = useState({modal:false, remarks:''})
    const [engineerAssigned, setAssignedEngineer] = useState({modal:false, selectedEngineer:null, assign_to_all:false})
    useEffect(() => {
        getT012FormInfo();
    },[]);

    const getT012FormInfo = () => {
        globalGetService(`technical/project/${params.project_slug}/misc-form/`)
        .then((response) => {
            if (checkApiStatus(response)) {
                setT012Info(response.data.data);
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        });
    }
    const updateT012FormInfo = () => {
        if(t012Info.title && t012Info.title.trim().length){
            setLoading(true);
            globalPostService(`technical/project/${params.project_slug}/misc-form/`, {title:t012Info.title, comment:t012Info.comments})
            .then((response) => {
                setLoading(false);
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                }else{
                    enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                }
            });
        }else{
            enqueueSnackbar('Please enter the Title of the Form', { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
        }
    }
    const exportT012FormInfo = () => {
        setLoading(true);
        globalExportService(`technical/project/${params.project_slug}/misc-form/`, { download: 'pdf' })
        .then((response) => {
            setLoading(false);
            downloadFileType(response, 'Project_T012.', 'pdf')
            enqueueSnackbar('T012 Form Report exported successfully', { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
        });
    }

    const onAssignEngineer = () => {
        setLoading(true);
        globalPostService(`technical/project/${params.project_slug}/assign-user/`, {assign_to_all:false,user_id:engineerAssigned.selectedEngineer.id})
        .then((response) => {
            setLoading(false);
            if (checkApiStatus(response)) {
                setAssignedEngineer({modal:false, selectedEngineer:null, assign_to_all:false});
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                getT012FormInfo();
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        });
    }
    const changeT012FormStatus = (data) => {
        setLoading(true);
        globalPostService(`technical/project/${params.project_slug}/status-update/`, data)
        .then((response) => {
            setLoading(false);
            if (checkApiStatus(response)) {
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                if(data.status === 3){
                    setRejectionInfo({modal:false, remarks:''})
                }
                getT012FormInfo();
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        });
    }
    const launchT012Form = () => {
        setLoading(true);
        globalGetService(`technical/project/${params.project_slug}/launch/`)
        .then((response) => {
            setLoading(false);
            if (checkApiStatus(response)) {
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                getT012FormInfo();
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }
        });
    }
    const onFieldChange = (e, keyParam, value) => {
        setT012Info({...t012Info, [keyParam]:value})
    }

    return(
        <Paper className='form-t012-section'>
            { t012Info && t012Info.status && t012Info.status.id && t012Info.status.id === 1 && onCheckPermission('technical', 'projects', 'U') ? <Button onClick={launchT012Form} style={{float:'right'}} color='primary' size='small' variant='contained'>Launch</Button>: null }
            
            <div className='form-t012-header'>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{width:'330px'}}>
                                <div style={{width:'90%'}}>
                                    <p>Form Type</p>
                                    <TextField
                                        disabled={t012Info.permissions && t012Info.permissions.can_save ? false:true}
                                        id="title"
                                        fullWidth
                                        margin="none"
                                        value={t012Info.title ? t012Info.title:''}
                                        InputLabelProps={{shrink: true}}
                                        inputProps={{maxLength: 50}}
                                        onChange={(e) => onFieldChange(e, 'title', e.target.value)}
                                        variant='outlined'
                                    />
                                </div>
                            </TableCell>
                            <TableCell>
                                <div>
                                    <p>Status</p>
                                    <h4>{t012Info.status && t012Info.status.label ? t012Info.status.label:''}</h4>
                                </div>
                            </TableCell>
                            <TableCell>
                                <div>
                                    <p>Last Activity</p>
                                    <h4>{t012Info.updated_at ? dateTransformAllSet(t012Info.updated_at):''}</h4>
                                </div>
                            </TableCell>
                            <TableCell>
                                <div>
                                    <p>Project Engineer</p>
                                    <h4>
                                        { t012Info.engineer && t012Info.engineer.length ? 
                                            <Fragment><span>{t012Info.engineer[0].name} { onCheckPermission('technical', 'projects', 'U') ? <EditIcon onClick={() => setAssignedEngineer({...engineerAssigned, modal:true})} style={{fontSize:'15px', cursor:'pointer'}} color="primary" /> :null}</span></Fragment>: onCheckPermission('technical', 'projects', 'U') ? <span onClick={() => setAssignedEngineer({...engineerAssigned, modal:true})}>Assign</span>:null
                                        }
                                    </h4>
                                </div>
                            </TableCell>
                            <TableCell>
                                { t012Info.permissions && t012Info.permissions.can_export ?
                                    <div>
                                        <Button onClick={exportT012FormInfo} color='primary' size='small' variant='outlined'>Export</Button>
                                    </div>:null
                                }
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
            <div className='form-t012-body'>
                <CkEditorText
                    disabled={t012Info.permissions && t012Info.permissions.can_save ? false:true}
                    htmlData={t012Info.comments ? t012Info.comments:''}
                    onChangeData={(data) => onFieldChange('', 'comments', data)}
                />
            </div>
            <div className='form-t012-footer'>
                { t012Info && Object.keys(t012Info).length ?
                    <ul className='list-inline'>
                        { t012Info.permissions.can_save ? 
                            <li className='list-inline-item'>
                                <Button onClick={updateT012FormInfo} color='primary' size='small' variant='contained'>SAVE</Button>
                            </li>:null
                        }
                        { t012Info.permissions.can_submit ?
                            <li className='list-inline-item'>
                                <Button onClick={() => changeT012FormStatus({status:5})} color='primary' size='small' variant='contained'>Submit</Button>
                            </li>:null
                        }
                        { t012Info.permissions.can_accept ?
                            <Fragment>
                                <li className='list-inline-item'>
                                    <Button onClick={() => changeT012FormStatus({status:6})} color='primary' size='small' variant='contained'>Accept</Button>
                                </li>
                                <li className='list-inline-item'>
                                    <Button onClick={() => setRejectionInfo({modal:true, remarks:''})} color='secondary' size='small' variant='contained'>Reject</Button>
                                </li>
                            </Fragment>:null
                        }
                        { t012Info.permissions.can_publish ?
                            <li className='list-inline-item'>
                                <Button onClick={() => changeT012FormStatus({status:7})} color='primary' size='small' variant='contained'>Publish</Button>
                            </li>:null
                        }
                    </ul>:null
                }
                
            </div>
            { engineerAssigned.modal ?
                <AssignEngineer 
                    users={project.engineers ? project.engineers:[]}
                    engineerAssigned={engineerAssigned}
                    toggleModalFn={() => setAssignedEngineer({modal:false, selectedEngineer:null, assign_to_all:false})}
                    selectProjectEngineerFn={(flag, user) => setAssignedEngineer({...engineerAssigned, selectedEngineer:flag?user:null})}
                    onSelectAllForms ={(flag) => setAssignedEngineer({...engineerAssigned, assign_to_all:flag})}
                    assignUserToForm={onAssignEngineer}
                />:null
            }  
            <Dialog
                maxWidth={'md'}
                open={rejectionInfo.modal}
                onClose={() => setRejectionInfo({modal:false, remarks:''})}
                aria-labelledby="scroll-dialog-title"
                className='project-management-modal'
            >
                <DialogTitle id="scroll-dialog-title">
                    Are you sure you want to reject?
                </DialogTitle>
                <DialogContent dividers={true}>
                    <div style={{width:'380px'}}>
                        <TextField
                            disabled={t012Info.permissions && t012Info.permissions.can_save ? false:true}
                            id="remarks"
                            label="Reason of Rejection"
                            fullWidth
                            margin="normal"
                            value={rejectionInfo.remarks}
                            multiline
                            maxRows={6}
                            InputLabelProps={{shrink: true}}
                            inputProps={{maxLength: 250}}
                            onChange={(e) => setRejectionInfo({modal:true, remarks:e.target.value})}
                            variant='outlined'
                        />
                        <div style={{textAlign:'center', marginTop:'10px'}}>
                            <ul className='list-inline'>
                                <li className='list-inline-item'>
                                    <Button onClick={() => setRejectionInfo({modal:false, remarks:''})} color='primary' size='small' variant='outlined'>Cancel</Button>
                                </li>
                                <li className='list-inline-item'>
                                    <Button disabled={rejectionInfo.remarks && rejectionInfo.remarks.trim().length === 0} onClick={() => changeT012FormStatus({status:3, remarks:rejectionInfo.remarks})} color='secondary' size='small' variant='contained'>Reject</Button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>  
            { isLoading ? <PageLoader />:null }        
        </Paper>
    )
}
export default withRouter(FormT012);