import React from 'react';
import { TableRow, TableCell, TextField } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { regexConstants } from '../../../constants/regEx';
export default function AccessoriesList({currentFormDetail, item, error, onFieldChange, previewDetail}){
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>
        { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
          <TextField
            id="ata"
            fullWidth
            margin="normal"
            multiline
            inputProps={{maxLength: 20}}
            rowsMax={4}
            value={item.ata ? item.ata:''}
            onChange={(e) => onFieldChange(e, 'ata', e.target.value)}
            error={error.ata ? true:false}
            helperText={error.ata ? error.ata:''}
            InputLabelProps={{shrink: true}}
          />:
          item.ata ? item.ata:'--'
        }

      </TableCell>
      <TableCell>
        { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
          <TextField
            id="description"
            fullWidth
            margin="normal"
            multiline
            inputProps={{maxLength: 255}}
            rowsMax={4}
            value={item.description ? item.description:''}
            onChange={(e) => onFieldChange(e, 'description', e.target.value)}
            InputLabelProps={{shrink: true}}
          />:
          item.description ? item.description:'--'
        }

      </TableCell>
      <TableCell>
        <table style={{width:'100%'}}>
          <tbody>
            <tr>
              <td style={{padding:'0 5px'}}>
                { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
                  <TextField
                    id="delivery_part_number"
                    fullWidth
                    margin="normal"
                    inputProps={{maxLength: 20}}
                    value={item.delivery_part_number }
                    onChange={(e) => onFieldChange(e, 'delivery_part_number', e.target.value)}
                    error={error.delivery_part_number ? true:false}
                    helperText={error.delivery_part_number ? error.delivery_part_number:''}
                    InputLabelProps={{shrink: true}}
                  />:
                  item.delivery_part_number ? item.delivery_part_number:'--'
                }

              </td>
              <td style={{padding:'0 5px'}}>
                { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
                  <TextField
                    id="delivery_serial_number"
                    fullWidth
                    margin="normal"
                    inputProps={{maxLength: 20}}
                    value={item.delivery_serial_number}
                    onChange={(e) => onFieldChange(e, 'delivery_serial_number', e.target.value)}
                    error={error.delivery_serial_number ? true:false}
                    helperText={error.delivery_serial_number ? error.delivery_serial_number:''}
                    InputLabelProps={{shrink: true}}
                  />:
                  item.delivery_serial_number ? item.delivery_serial_number:'--'
                }

              </td>
            </tr>
          </tbody>
        </table>
      </TableCell>
      <TableCell>
        <table style={{width:'100%'}}>
          <tbody>
            <tr>
              <td style={{padding:'0 5px'}}>
                { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
                  <TextField
                    id="installed_part_number"
                    fullWidth
                    margin="normal"
                    inputProps={{maxLength: 20}}
                    value={item.installed_part_number ? item.installed_part_number:''}
                    onChange={(e) => onFieldChange(e, 'installed_part_number', e.target.value)}
                    error={error.installed_part_number ? true:false}
                    helperText={error.installed_part_number ? error.installed_part_number:''}
                    InputLabelProps={{shrink: true}}
                  />:
                  item.installed_part_number ? item.installed_part_number:'--'
                }

              </td>
              <td style={{padding:'0 5px'}}>
                { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
                  <TextField
                    id="installed_serial_number"
                    fullWidth
                    margin="normal"
                    inputProps={{maxLength: 20}}
                    value={item.installed_serial_number ? item.installed_serial_number:''}
                    onChange={(e) => onFieldChange(e, 'installed_serial_number', e.target.value)}
                    error={error.installed_serial_number ? true:false}
                    helperText={error.installed_serial_number ? error.installed_serial_number:''}
                    InputLabelProps={{shrink: true}}
                  />:
                  item.installed_serial_number ? item.installed_serial_number:'--'
                }

              </td>
            </tr>
          </tbody>
        </table>
      </TableCell>
      <TableCell>
        { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
          <TextField
            id="tsn"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 10}}
            value={item.tsn ? item.tsn:''}
            onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsn', e.target.value): e.preventDefault()}}
            error={false}
            helperText=''
            InputLabelProps={{shrink: true}}
          />:
          item.tsn ? item.tsn:'--'
        }

      </TableCell>
      <TableCell>
        { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
          <TextField
            id="csn"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 10}}
            value={item.csn ?item.csn:''}
            onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'csn', e.target.value) : e.preventDefault() }}
            error={false}
            helperText=''
            InputLabelProps={{shrink: true}}
          />:
          item.csn ?item.csn:'--'
        }

      </TableCell>
      <TableCell>
        { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
          <TextField
            id="remarks"
            fullWidth
            multiline
            rowsMax={4}
            margin="normal"
            inputProps={{maxLength: 255}}
            value={item.remarks ? item.remarks:''}
            onChange={(e) => onFieldChange(e, 'remarks', e.target.value)}
            error={false}
            helperText=''
            InputLabelProps={{shrink: true}}
          />:
          item.remarks ? item.remarks:'--'
        }
      </TableCell>
      { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
        <TableCell className="actions-cell">
          <DeleteOutlineIcon onClick={() => previewDetail('D')} color="error" />
        </TableCell>:null
      }
    </TableRow>
  )
}
