import moment from 'moment';
import { globalGetService, globalPostService, globalDeleteService, globalFileUploadService } from '../../utils_v2/globalApiServices';
import { checkApiStatus, downloadFileType, getLocalStorageInfo } from '../../utils_v2';
import { backendDateFormat } from '../../constants';
import { fieldValidation } from '../../utils_v2/formValidation';
import { regexConstants } from '../../constants/regEx';
import { errorCode} from './index';
export function getCurrentFormDetailApi(props={}){
    globalGetService(`technical/engine-workorder/${props.params.workOrderSlug}/forms/`,{form:props.params.formSlug})
    .then(response => {
      if(checkApiStatus(response)){
        this.setState({
          currentFormDetail:response.data.data
        })
      }
    })
}
export function changeFormStatusApi(props={}, data={}){
    this.setState({pageLoader:true});
    globalPostService(`technical/engine-workorder/${props.params.workOrderSlug}/form/${props.params.formSlug}/status-update/`,data)
    .then(response => {
        this.setState({pageLoader:false});
        if(checkApiStatus(response)){
        this.getCurrentFormDetailApi(this.props);
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }else{
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
    })
}
export function getMrSectionDetailsApi(props={}, query={}){
  globalGetService(`technical/engine-workorder/${props.params.workOrderSlug}/mr-sections/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        mrSections: response.data.data,
        skeletonLoader:false
      })
    }
  })
}
export function exportMrSectionDetailsApi(props={}, file={}){
  this.setState({pageLoader: true});
  globalGetService(`technical/engine-workorder/${props.params.workOrderSlug}/mr-sections/`, {download:file.extension})
  .then(response => {
    downloadFileType(response.data, (`TE006_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`), file.extension);
    this.setState({pageLoader: false});
    this.props.enqueueSnackbar('MR Claim & Invoice Review downloaded successfully', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
  })
}
export function editMrSectionDetailsApi(props={}, data={}){
  let sectionFlag = true;
  let sectionError = [];
  let validationInputs = {
    report_type:errorCode['report_type'][fieldValidation({...errorCode['report_typeObj'], fieldval: data.details.report_type})],
    report_date:errorCode['report_date'][fieldValidation({...errorCode['report_dateObj'], fieldval: data.details.report_date})],
    report_issued_place:errorCode['report_issued_place'][fieldValidation({...errorCode['report_issued_placeObj'], fieldval: data.details.report_issued_place})],
  }
  sectionError = data.sections.map(item => {
    return {
      title: item.title.trim() === '' ? 'Please enter title' :  regexConstants.alphanumericWithHyphenSpace.test(item.title) ? '':'Alphanumeric only',
    }
  })
  sectionError.map(data => {
    if(!Object.keys(data).every((k) => { return data[k] === ''})){
      sectionFlag = false
    }
  });
  if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''}) && sectionFlag){
    this.setState({pageLoader:true});
    globalPostService(`technical/engine-workorder/${props.params.workOrderSlug}/mr-sections/create/`, {data:data})
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else {
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }else{
    // this.setState({error: validationInputs});
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        details: validationInputs,
        sections: sectionError,
      }
    }));
  }
}
export function deleteMrSectionDetailsApi(props={}, data={}){
  globalPostService(`technical/engine-workorder/${props.params.workOrderSlug}/mr-sections/delete/section/`, {id: data.id})
  .then(response => {
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        mrSections: {
          ...prevState.mrSections,
          sections: prevState.mrSections.sections.filter((section, index) => index !== data.deleteIndex)
        },
        deleteModal:false,
        deleteIds:{}
      }))
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else {
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function deleteMrAttachmentApi(props={}, deleteIds={}){
  if(deleteIds.id){
    globalPostService(`technical/engine-workorder/${props.params.workOrderSlug}/mr-sections/delete/attachment/`, {id:deleteIds.id})
    .then(response => {
      if(checkApiStatus(response)){
        this.setState(prevState => ({
          ...prevState,
          mrSections: {
            ...prevState.mrSections,
            attachments: [...prevState.mrSections.attachments].filter((attachment, index) => index !== deleteIds.deleteIndex)
          },
          deleteModal:false,
          deleteIds:{}
        }))
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else {
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }else{
    this.setState(prevState => ({
      ...prevState,
      mrSections: {
        ...prevState.mrSections,
        attachments: [...prevState.mrSections.attachments].filter((item, index) => index !== deleteIds.deleteIndex)
      },
      deleteModal:false,
      deleteIds:{}
    }))
  }
}
export function generateMrAttachmentUrlApi(props={}, file){
  let formData = new FormData();
  formData.append('file', file, file.name);
  formData.append('mr', true);
  globalFileUploadService(`technical/engine-workorder/${props.params.workOrderSlug}/file-upload/`, formData)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        mrSections: {
          ...prevState.mrSections,
          attachments: [...prevState.mrSections.attachments, {
            attachment:response.data.url,
            name:response.data.name,
            description:'',
            created_at:moment().format(backendDateFormat),
            user: {id:getLocalStorageInfo().user.id,name: getLocalStorageInfo().user.name, profile_pic:getLocalStorageInfo().user.profile_pic}
          }]
        }
      }))
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else {
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
