import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { SIDEBAR_TOGGLE } from '../actions';
import { Link, IndexLink } from 'react-router';
import { getLocalStorageInfo, eraseGlobalCookie } from '../../utils';
import { trackLogOut } from '../../utils/mixpanel'
import { imgStoragePath } from '../../constants';
import ToolTipHover from './ToolTipHover';
import dashboardIcon from '../../shared/assets/img/dashbord_icon.svg';
import homeIcon from '../../shared/assets/img/home_icon.png';
import  templateIcon  from '../../shared/assets/img/templates.svg';
import config from '../../config';
class PrySidebarExp extends Component{
  constructor(props){
    super(props);
  }
  logout = () => {
    trackLogOut(getLocalStorageInfo())
    localStorage.clear();
    eraseGlobalCookie('lessorAccess')
    eraseGlobalCookie('redirectURIProjects')
    eraseGlobalCookie('domain')
    this.props.sidebarToggleFn(false)
    browserHistory.push('/login');
  }

  componentDidMount(){

  }

  checkTracker(){
    if(getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('R')){
      return  '/technical/projects/tracker';
    }else if(getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('GR')){
      return  '/technical/projects/tracker';
    }else if(getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('MR')){
      return  '/technical/man-power/tracker';
    }else if(getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('CR')){
      return  '/technical/cor/tracker';
    }else{
      return  '/technical/projects/tracker';
    }
  }

  primaryNavLink = (imgIcon, imgWidth, url, activeClassName, flag, targetId, placement, title, linkAccess, isBeta) => {
    if(linkAccess){
      return (
        <li>
          <Link to={url} onClick={() => this.props.sidebarToggleFn(false)} style={(title == 'Co') ? {cursor: 'not-allowed'} : {}} className={(targetId == 'userManagement' ? ' ': ' ') + (targetId == 'usersManager' ? 'utility-block ': ' ')  + (activeClassName ? 'flex-centered active' :'flex-centered')}>
            <img id={targetId} src={imgStoragePath+imgIcon} alt="nav-icon" width={imgWidth} />
            <br/>
            <span className="sideBarSubheading">{title}</span>
          </Link>
        </li>
      )
    }else{
      return null
     }
  }
  showToolTip = (flag, targetId, placement, title) => {
    if(!flag){
      return(
        <ToolTipHover tagetId={targetId} placement={placement}> {title} </ToolTipHover>
      )
    }else{ return null }
  }
  render(){
    var { sideBarStatus,  notificationOpen, dashSw } = this.props;
    sideBarStatus = false;
    const pathname = this.props.pathName;
    const userDetail = JSON.parse(localStorage.getItem('userInfo'));
    let is_saas = false
    Object.keys(userDetail.user.permission).map(permission => {
      if(userDetail.user.permission[permission].is_saas == true){
        is_saas = true
      }
      return permission
    })
    const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532 );
    return(
      <div className="prySidebarExp" style={ notificationOpen || this.props.topNotice ? {marginTop: '30px'} : null }>
        <div className="dashboard-block">
          {
            <ul className="list-unstyled" style={{ marginTop: userDetail.defaultLessor.on_trail ? '30px' : '0px'  }}>
              <li>
                <Link className="flex-centered" to={`${config.domain.subDomian}`} target="_blank">
                  <img id="home" src={homeIcon} alt="img" width='25'/>
                  <br/>
                  <span className="sideBarSubheading ">
                    Home
                  </span>
                </Link>
              </li>
              <li>
                <IndexLink  onClick={() => this.props.sidebarToggleFn(false)}  activeClassName="active" className={pathname === '' || pathname === '/' ? "flex-centered active" : "flex-centered"} to="/fleet/technical/dashboard">
                  <img id="dashboard" src={dashboardIcon} alt="img" width='20'/>
                  <br/>
                  <span className="sideBarSubheading ">
                    Dashboard
                  </span>
                </IndexLink>
                </li>
              { this.primaryNavLink('phase-2/technical_projects.png', 20, '/technical/projects?project_status=all', ((pathname.includes('technical/project') || pathname.includes('/forms/')) && !pathname.includes('/technical/projects/tracker')), sideBarStatus, 'technical-projects', 'right', 'Projects', getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.projects) }
              {
               this.primaryNavLink('phase-2/project_tracker.png', 20, this.checkTracker(), (pathname.includes(this.checkTracker())), sideBarStatus, 'technical-tracker', 'right', 'Trackers', getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && (getLocalStorageInfo().user.permission.technical.project_tracker.includes('R')  || getLocalStorageInfo().user.permission.technical.project_tracker.includes('GR')))
              }
              { this.primaryNavLink(imgStoragePath+'tableau_analytics.svg', 20, '/analytics/resources', (pathname.includes('/analytics/resources') || pathname.includes('/analytics/projects')), sideBarStatus, 'analytics', 'right', 'Analytics', userDetail.user && userDetail.user.account_type && userDetail.user.account_type == 'Acumen' )}
              { this.primaryNavLink('Bitmap.png', 20, '/technical/program-planner', (pathname.includes('/technical/program-planner')), sideBarStatus, 'program-planner', 'right', 'Program Planner', (getLocalStorageInfo()?.user?.permission?.program_planner?.dashboard !== undefined && getLocalStorageInfo()?.defaultLessor?.id === 321 || getLocalStorageInfo()?.defaultLessor?.id === 442 )) }
              { smbcInstance ?
                  null :
                  this.primaryNavLink('report_icon.png', 20, '/technical/opr/report', (pathname.includes('/opr')), true, 'exportReport', 'right', 'OPR Report', true)
              }
                 { 
                  smbcInstance ?
                  null :
                 getLocalStorageInfo().user.security_groups.filter(group => [14, 24,29,33].includes(group.id)).length ?
                  <li>
                   <IndexLink  onClick={() => this.props.sidebarToggleFn(false)}  activeClassName="active"  className="flex-centered" to="/technical/form/templates">
                    <img id="dashboard" src={templateIcon} alt="img" width='20' style={{opacity:'0.8'}}/>
                    <br/>
                    <span className="sideBarSubheading ">
                     Templates
                    </span>
                  </IndexLink>
                  </li> : null
                 }      
            </ul>
          }
        </div>
     </div>
    )
  }
}

const mapStateToProps = state => ({
  notificationOpen:state.sharedReducers.notificationOpen,
  dashSw:state.sharedReducers.dashSw,
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sidebarToggleFn: (statusFlag) => dispatch({
      type: SIDEBAR_TOGGLE,
      payload: false
    }),
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(PrySidebarExp);
