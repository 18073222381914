import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {Paper, Tooltip, Button} from '@material-ui/core';
import { browserHistory } from 'react-router'
import { ListLoader, TableListComp, FilterUIComp, ExportManu, EmptyCollection } from '../../../shared';
import {  STableLoader } from '../../../shared/loaders';
import { removeEmptyKey, convertFilterObject } from '../../../utils';
import { ProjectAnalyticList, ProjectsChart } from '../components';
import { getAnalyticProjectsApi, getAnalyticProjectsGrpApi, getProjectLeadApi, getProjectEngineersApi, getRegionListApi, exportProjectTrackerApi } from '../apiServices';
import { sGlobalConstantAc, sLesseeListAc,sLessorListAc } from '../../../shared/actionCreators';
import { projectsFilterOptions,  projectAnalyticHd, countryCodes } from '../'
import { trackActivity } from '../../../utils/mixpanel';
import MapIcon from '@material-ui/icons/Map';
import CountUp from 'react-countup';
import RefreshIcon from '@material-ui/icons/Refresh';
import CloseIcon from '@material-ui/icons/Close';
import { imgStoragePath } from '../../../constants';
class ProjectsAnalyticTracker extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      skeletonLoader:true,
      tabIndex:0,
      filter: {},
      country_code:'',
      sort:'',
      sort_by:'',
      applyingFilter:{},
      projectsDetail:{list:[], pagination:{}},
      projectsGrph:{plots:[], general_info:{}},
      projectLeadsList:[],
      projectEngineersList:[],
      regionList:[],
    }
    this.getAnalyticProjectsApi = getAnalyticProjectsApi.bind(this);
    this.getAnalyticProjectsGrpApi = getAnalyticProjectsGrpApi.bind(this);
    this.getProjectLeadApi = getProjectLeadApi.bind(this);
    this.getProjectEngineersApi = getProjectEngineersApi.bind(this);
    this.getRegionListApi = getRegionListApi.bind(this);
    this.exportProjectTrackerApi = exportProjectTrackerApi.bind(this);
  }
  componentDidMount(){
    this.getAnalyticProjectsApi(this.props, {}, 'skeletonLoader');
    this.getAnalyticProjectsGrpApi(this.props, {});
    this.props.getPlatformConstants();
    this.props.getLesseeList();
    this.props.getLessorList();
    this.getProjectLeadApi();
    this.getProjectEngineersApi();
    this.getRegionListApi();
  }

  applyFilter = (keyParam, value, rangeKey) => {
    if(rangeKey){
      this.setState(prevState => ({
        ...prevState,
        applyingFilter: {
          ...prevState.applyingFilter,
          [keyParam]: {
            ...prevState.applyingFilter[keyParam],
            [rangeKey]:value
          }
        }
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        applyingFilter: {
          ...prevState.applyingFilter,
          [keyParam]: value
        }
      }))
    }
  }
  removeFilter = (keyParam) => {
    const { filter, applyingFilter } = this.state;
    let query = Object.assign({}, filter);
    delete query[keyParam];
    this.getAnalyticProjectsApi(this.props, {...query}, 'pageLoader')
    this.getAnalyticProjectsGrpApi(this.props, {...query}, 'pageLoader')
    this.setState({applyingFilter: delete applyingFilter[keyParam]})
  }
  submitFilter = (filterOptions) => {
    const { applyingFilter } = this.state
    this.getAnalyticProjectsApi(this.props, {...convertFilterObject(removeEmptyKey(applyingFilter), filterOptions)}, 'pageLoader')
    this.getAnalyticProjectsGrpApi(this.props, {...convertFilterObject(removeEmptyKey(applyingFilter), filterOptions)}, 'pageLoader')
    this.setState({country_code:''})
  }
  exportReportFn = (file) => {
    const { filter} = this.state
    this.exportProjectTrackerApi(this.props,file,filter)
  }
  getCountryNames = () => {
    const { country_code } = this.state
    for (const key in countryCodes) {
      if (countryCodes.hasOwnProperty(key)) {
          if(key == country_code){
            return countryCodes[key]
          }
      }
    }
  }
  removeAllFilter = () => {
    this.getAnalyticProjectsApi(this.props, {}, 'pageLoader'); 
    this.getAnalyticProjectsApi(this.props, {list:true}, 'pageLoader') 
    this.setState({country_code:''})
  }
  createSortHandler = (sortField) => {
    const { sort, sort_by, filter } = this.state;
    this.setState({
      sort:sortField,
      sort_by: (sortField === sort ? sort_by === 'asc' ? 'desc':'asc' :'asc')
    });
    if(sortField === sort){
      if(sort_by === 'asc'){
        this.getAnalyticProjectsApi(this.props, {...filter, sort: sortField, sort_by: 'desc', list:true }, 'pageLoader');
      }else{
        this.getAnalyticProjectsApi(this.props, {...filter, sort: sortField, sort_by: 'asc', list:true }, 'pageLoader');
      }
    }else{
      this.getAnalyticProjectsApi(this.props, {...filter, sort: sortField, sort_by: 'asc', list:true}, 'pageLoader');
    }
  }
  handleTabChange = (value, tab) => {
    trackActivity('Tab Clicked',{page_title: 'Analytics', tab_name: tab})
    browserHistory.push(`/analytics/${value}`)
  }
  handleToggleView = (tab, viewMode ) => {
    trackActivity('Item Clicked', {
      page_title:'Analytics',
      tab_name:'Project tracker',
      viewmode: viewMode
    })
    this.setState({tabIndex:tab })
  }
  render(){
    const { country_code, filter, applyingFilter, projectsDetail, projectsGrph, skeletonLoader,  pageLoader, projectLeadsList, projectEngineersList,tabIndex, sort,sort_by } = this.state;
    const { pltConstants, lessorList } = this.props;
    let filterOptions = projectsFilterOptions;
    filterOptions = {
      ...filterOptions,
      name: {
        ...filterOptions.name,
        options: pltConstants.filter(item => item.type === 'project_name')
      },
      department: {
        ...filterOptions.department,
        options: pltConstants.filter(item => item.type === 'project_departments')
      },
      lessee:{
        ...filterOptions.lessee,
        options:this.props.lesseeList
      },
      project_leads:{
        ...filterOptions.project_leads,
        options:projectLeadsList
      },
      project_engineers:{
        ...filterOptions.project_engineers,
        options:projectEngineersList
      },
      lessor_ids:{
        ...filterOptions.lessor_ids,
        options:lessorList
      },
    }
    return(
      <section className="resources-analytic-tracker">
        <div className="analytic-head">
          <h2>Projects Analytics</h2>
          <p> The analytics presented below provide detailed information on Acumen's Projects and its Manpower Resources globally.</p>
          <ul className="list-inline">
            <li className="list-inline-item" onClick={()=> this.handleTabChange('resources', 'Resource Tracker')}>Resources Tracker</li>
            <li className="list-inline-item active" onCLick={() => this.handleTabChange('projects', 'Project Tracker')} >Projects Tracker</li>
          </ul>
        </div>
        <div className="analytic-blk">
          <div className="flex-centered">
            <div>
              <ul className="list-inline">
                {
                  projectsGrph && projectsGrph.general_info && projectsGrph.general_info.completed ?
                  <li className="list-inline-item">
                    <Paper>
                      <h4 style={{color:'#5d3cdb'}} data-target={projectsGrph.general_info.completed }>
                        <CountUp end={projectsGrph.general_info.completed} start={0} duration={projectsGrph.general_info.completed < 100 ? 0.75 : 2} />
                      </h4>
                      <p style={{color:'#5d3cdb'}}>Completed</p>
                    </Paper>
                  </li> :
                  <li className="list-inline-item">
                    <Paper>
                      <h4 style={{color:'#5d3cdb'}}>0</h4>
                      <p style={{color:'#5d3cdb'}}>Completed</p>
                    </Paper>
                  </li>
                }
                {
                  projectsGrph && projectsGrph.general_info && projectsGrph.general_info.ongoing ?
                  <li className="list-inline-item">
                    <Paper>
                      <h4 style={{color:'#df8c0c'}} data-target={projectsGrph.general_info.ongoing }>
                        <CountUp end={projectsGrph.general_info.ongoing} start={0} duration={projectsGrph.general_info.ongoing < 100 ? 0.75 : 2} />
                      </h4>
                      <p style={{color:'#df8c0c'}}>Ongoing</p>
                    </Paper>
                  </li> :
                  <li className="list-inline-item">
                    <Paper>
                      <h4 style={{color:'#df8c0c'}}>0</h4>
                      <p style={{color:'#df8c0c'}}>Ongoing</p>
                    </Paper>
                  </li>
                }
                {
                  projectsGrph && projectsGrph.general_info && projectsGrph.general_info.upcoming ?
                  <li className="list-inline-item">
                    <Paper>
                      <h4 style={{color:'#2eaeb5'}} data-target={projectsGrph.general_info.upcoming }>
                        <CountUp end={projectsGrph.general_info.upcoming} start={0} duration={projectsGrph.general_info.upcoming < 100 ? 0.75 : 2} />
                      </h4>
                      <p style={{color:'#2eaeb5'}}>Upcoming</p>
                    </Paper>
                  </li> :
                  <li className="list-inline-item">
                    <Paper>
                      <h4 style={{color:'#2eaeb5'}}>0</h4>
                      <p style={{color:'#2eaeb5'}}>Upcoming</p>
                    </Paper>
                  </li>
                }
              </ul>
              { country_code && tabIndex == 0 ? 
                <span style={{fontSize:'12px', background:'#fff3cd', color:'#856404', border:'1px solid #ffeeba' , padding:'3px',marginBottom:'5px'}} >
                  Results based on Location : {this.getCountryNames()} <CloseIcon  style={{position:'relative', top:'3px', fontSize:'16px', cursor:'pointer'}} onClick={() => { this.getAnalyticProjectsApi(this.props, {list:true}, 'pageLoader'); this.getAnalyticProjectsGrpApi(this.props, {list:false}, 'pageLoader');}}  /> 
                </span> 
                : null
              }
            </div>
            <div style={{marginLeft:'auto'}}>
              <ul className="list-inline flex-centered">
                <li className="list-inline-item toggle-chart">
                  <ul className="list-inline">
                    <li className={`list-inline-item ${tabIndex == 0 ? 'active' : 'inactive-bg'}`} onClick={() => this.handleToggleView(0, 'List View')}>
                      <img src={tabIndex == 0 ? imgStoragePath + 'regular_list.svg' : imgStoragePath + 'regular_list_inactive.svg'} alt="Icon" />
                    </li>
                    <li className={`list-inline-item ${tabIndex == 1 ? 'active' : 'inactive-bg'}`} onClick={() => this.handleToggleView(1, 'Map View')}>
                      <MapIcon  fontSize="small"  style={{position:'relative', top:'5px', fontSize:'1.14rem'}}/>
                     </li>
                  </ul>
                </li>
                <li className="list-inline-item">
                  <ul className="list-inline flex-centered">
                    <li className="list-inline-item">
                      <Tooltip title="Click to restore default map view" arrow>
                        <Button onClick={() => { this.getAnalyticProjectsApi(this.props, {list:true}, 'pageLoader'); this.getAnalyticProjectsGrpApi(this.props, {list:false}, 'pageLoader');}} color="primary" variant="contained" >
                          <RefreshIcon />
                        </Button>
                      </Tooltip>
                    </li>
                    <li className="list-inline-item">
                      <span style={{float: 'right', padding: '5px 0'}}>
                        <ExportManu title="Export" files={[{title:'Excel', extension: 'xls', key:''}]} exportReportFn={(file) => this.exportReportFn(file)}/>
                      </span>
                    </li>
                  </ul>
                </li>
              </ul> 
            </div>
          </div>
          <div style={{marginT0p:'3px'}}>
            <FilterUIComp
               filter={filter}
               applyingFilter={applyingFilter}
               removeFilter={this.removeFilter}
               removeAllFilter={this.removeAllFilter}
               submitFilter={() => this.submitFilter(filterOptions)}
               applyFilter={this.applyFilter}
               filterMenu={filterOptions}
             />
              <Fragment>
                 { tabIndex == 1 ? projectsGrph.plots.length ?
                   <ProjectsChart
                      plots={projectsGrph.plots.map(plotItem => {return{...plotItem, value:plotItem.count}})}
                      onClickCountry={(country_code) =>{this.getAnalyticProjectsGrpApi(this.props, {list:false, country_code:country_code}, 'pageLoader'); this.getAnalyticProjectsApi(this.props, {...filter, list:true, country_code:country_code}, 'pageLoader'); this.setState({country_code:country_code})}}
                   />
                   :null 
                   : 
                  skeletonLoader ? <STableLoader count={10}  /> :                 
                   <div>
                    <TableListComp
                      sort_by={sort_by}
                      sort={sort}
                      heads={projectAnalyticHd}
                      data={ projectsDetail.list.map((item,index) =>
                        <ProjectAnalyticList
                          key={index}
                          index={index+1}
                          item={item}
                        />
                      )}
                      noRecord={ projectsDetail.list.length ? null:
                        <EmptyCollection
                          title="No records found"
                        />
                      }
                      pagination={projectsDetail.pagination}
                      onChangePage={(event, newPage) => this.getAnalyticProjectsApi(this.props, {...filter, country_code:country_code, list:true, page:newPage+1, per_page: projectsDetail.pagination.per_page}, 'pageLoader')}
                      onChangeRowsPerPage={(event) => this.getAnalyticProjectsApi(this.props, {...filter, country_code:country_code, list:true, page:1, per_page: event.target.value}, 'pageLoader')}
                      createSortHandler={this.createSortHandler}
                    />
                 </div>
                 }
              </Fragment>
            { pageLoader ? <ListLoader />:null }
          </div>
        </div>
      </section>
    )
  }
}
const mapStateToProps = state => ({
  pltConstants: state.sharedReducers.techConstants,
  lesseeList: state.sharedReducers.sLesseeList,
  lessorList: state.sharedReducers.sLessorList
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getPlatformConstants: () => dispatch(sGlobalConstantAc({constant_types:['project_departments', 'project_name']})),
    getLesseeList: () => dispatch(sLesseeListAc()),
    getLessorList: () => dispatch(sLessorListAc({is_internal_lessor:false,contains_project:true})),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProjectsAnalyticTracker)
