import React from 'react'
import { Grid } from '@material-ui/core';
const DocsCheckListHeader = ({}) => {
    return(
        <div className='docs-checklist-hd'>
            <Grid container>
                <Grid item md={6}>
                    <p>Document/Checklist Name</p>
                </Grid>
                <Grid item md={2}>
                    <p>Status</p>
                </Grid>
                <Grid item md={4}>
                    <p>Remarks</p>
                </Grid>
            </Grid>
        </div>
    )
}

export default DocsCheckListHeader;