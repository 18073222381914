import React from 'react';
import { Paper, TextField, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider,KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { LabelValueCard } from '../../../shared_elements';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
export default function GeneralDetail({generalDetail, currentFormDetail, onFieldChange, error, handleError, resetErrorKey}){
  const canEdit = currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save;
  return(
    <Paper className="form-detail-card">
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          <Autocomplete
            disabled={!canEdit}
            options = {[{label: 'Witness', value: 1},{label: 'Review', value: 2}]}
            getOptionLabel={option => option.label}
            id="type_of_report"
            value={generalDetail.type_of_report ? [{label: 'Witness', value: 1},{label: 'Review', value: 2}].find(item => item.value === generalDetail.type_of_report):null}
            onChange={(e, value) => { onFieldChange(e, 'type_of_report', value ? value.value : ''); handleError(value ? value.value:'','type_of_report') }}
            renderInput={params => <TextField required onFocus={(e) => resetErrorKey('type_of_report')} error={error.type_of_report ? true:false} helperText={error.type_of_report ? error.type_of_report:''} {...params} label="Type of Report" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant="outlined" />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            disabled={!canEdit}
            id="place_of_inspection"
            label="Place of Inspection"
            fullWidth
            required
            margin="normal"
            inputProps={{maxLength: 50}}
            value={generalDetail.place_of_inspection}
            onChange={(e) => onFieldChange(e, 'place_of_inspection', e.target.value)}
            error={error.place_of_inspection ? true:false}
            helperText={error.place_of_inspection ? error.place_of_inspection: ''}
            InputLabelProps={{shrink: true}}
            onChange={(e) => onFieldChange(e, 'place_of_inspection', e.target.value)}
            onBlur={(e) => handleError(e.target.value,'place_of_inspection')}
            onFocus={(e) => resetErrorKey('place_of_inspection')}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            disabled={!canEdit}
            id="amm_revision_number"
            label="AMM Revision No"
            fullWidth
            required
            inputProps={{maxLength: 20}}
            margin="normal"
            value={generalDetail.amm_revision_number}
            error={error.amm_revision_number ? true:false}
            helperText={error.amm_revision_number ? error.amm_revision_number: ''}
            InputLabelProps={{shrink: true}}
            onChange={(e) => onFieldChange(e, 'amm_revision_number', e.target.value)}
            onBlur={(e) => handleError(e.target.value,'amm_revision_number')}
            onFocus={(e) => resetErrorKey('amm_revision_number')}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                disabled={!canEdit}
                margin="normal"
                id="amm_revision_date"
                label="AMM Revision Date"
                format={fieldDateFormat}
                fullWidth
                required
                disableFuture={true}
                clearable={true}
                InputLabelProps={{shrink: true}}
                inputProps={{readOnly: true}}
                clearable={true}
                value={generalDetail.amm_revision_date ? generalDetail.amm_revision_date:null}
                error={error.amm_revision_date ? true:false }
                helperText={error.amm_revision_date ? error.amm_revision_date: ''}
                onChange={(data, value) => {onFieldChange(value, 'amm_revision_date', data ? moment(data).format(backendDateFormat):data); data ? resetErrorKey('amm_revision_date'): handleError(null, 'amm_revision_date')}}
                inputVariant="outlined"
              />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={3}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                disabled={!canEdit}
                margin="normal"
                id="date_of_inspection_from"
                label="Date of Inspection From"
                format={fieldDateFormat}
                fullWidth
                required
                disableFuture={true}
                inputProps={{readOnly: true}}
                clearable={true}
                InputLabelProps={{shrink: true}}
                value={generalDetail.date_of_inspection_from ? generalDetail.date_of_inspection_from:null}
                error={error.date_of_inspection_from ? true:false }
                helperText={error.date_of_inspection_from ? error.date_of_inspection_from: ''}
                onChange={(data, value) => {onFieldChange(value, 'date_of_inspection_from', data ? moment(data).format(backendDateFormat):data); resetErrorKey('date_of_inspection_from');resetErrorKey('date_of_inspection_to');resetErrorKey('report_date')}}
                inputVariant="outlined"
              />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={3}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                disabled={!canEdit}
                margin="normal"
                id="date_of_inspection_to"
                label="Date of Inspection To"
                format={fieldDateFormat}
                fullWidth
                InputLabelProps={{shrink: true}}
                inputProps={{readOnly: true}}
                clearable={true}
                error={error.date_of_inspection_to ? true:false }
                helperText={error.date_of_inspection_to ? error.date_of_inspection_to: ''}
                value={generalDetail.date_of_inspection_to ? generalDetail.date_of_inspection_to:null}
                onChange={(data, value) => {onFieldChange(value, 'date_of_inspection_to', data ? moment(data).format(backendDateFormat):data); resetErrorKey('date_of_inspection_from');resetErrorKey('date_of_inspection_to');resetErrorKey('report_date')}}
                inputVariant="outlined"
              />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={3}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                disabled={!canEdit}
                margin="normal"
                id="report_date"
                label="Report Date"
                format={fieldDateFormat}
                fullWidth
                required
                InputLabelProps={{shrink: true}}
                disableFuture={true}
                inputProps={{readOnly: true}}
                clearable={true}
                value={generalDetail.report_date ? generalDetail.report_date:null}
                error={error.report_date ? true:false }
                helperText={error.report_date ? error.report_date: ''}
                onChange={(data, value) => {onFieldChange(value, 'report_date', data ? moment(data).format(backendDateFormat):data); resetErrorKey('date_of_inspection_from');resetErrorKey('date_of_inspection_to');resetErrorKey('report_date')}}
                inputVariant="outlined"
              />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            disabled={!canEdit}
            id="report_issued_place"
            label="Report Issued Place"
            fullWidth
            required
            margin="normal"
            inputProps={{maxLength: 30}}
            value={generalDetail.report_issued_place}
            error={error.report_issued_place ? true:false}
            helperText={error.report_issued_place ? error.report_issued_place: ''}
            InputLabelProps={{shrink: true}}
            onChange={(e) => onFieldChange(e, 'report_issued_place', e.target.value)}
            onBlur={(e) => handleError(e.target.value,'report_issued_place')}
            onFocus={(e) => resetErrorKey('report_issued_place')}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </Paper>
  )
}
