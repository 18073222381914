import React from 'react';
import { Paper, Table, TableBody, TableHead, TableCell, TableSortLabel, TablePagination, TableRow, Checkbox } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { useTableStyle } from '../../utils_v2/materialStyles';
export default function TableListComp({bulkOperation=false, bulkFlag, heads, noRecord, data, sort='', sort_by='', pagination={}, onChangePage, onChangeRowsPerPage, createSortHandler, classNames, toggleBulkOps}){
  const classes = useTableStyle();
  return(
    <div style={{clear: 'both', width: '100%'}}>
      <Paper>
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table + ' mui-table-format ' + classNames}
            aria-labelledby="tableTitle"
            stickyHeader={true}
            size='medium'
          >
            <TableHead className="mui-table">
              <TableRow>
                { bulkOperation ?
                  <TableCell>
                    <Checkbox
                      size="small"
                      checked={bulkFlag}
                      onChange={(e) => toggleBulkOps(e.target.checked)}
                      name="bulk_operation"
                      color="primary"
                    />
                  </TableCell>:null
                }
                {heads.map((row, index) =>
                  <TableCell
                    key={index}
                    align={row.actionCell ? 'right':'left'}
                    padding={'default'}
                    sortDirection={sort === row.id ? (sort_by ? sort_by:'asc') : false}
                  >
                    {row.sortOption && noRecord === null ?
                      <TableSortLabel
                        active={sort === row.id}
                        direction={sort_by && sort === row.id ? sort_by:'asc'}
                        onClick={() => createSortHandler(row.id)}
                      >
                        {row.label}
                      </TableSortLabel>:<span>{row.label}</span>
                    }
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              { noRecord ? <TableRow><TableCell colSpan={bulkOperation ? heads.length+1:heads.length} align="center">{noRecord}</TableCell></TableRow>:data}
            </TableBody>
          </Table>
        </div>
        { Object.keys(pagination).length && pagination.total > 0 ?
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={pagination.total}
              rowsPerPage={pagination.per_page}
              page={pagination.page - 1}
              backIconButtonProps={{'aria-label': 'previous page'}}
              nextIconButtonProps={{'aria-label': 'next page'}}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
            />:null
        }
      </Paper>
    </div>
  )
}
