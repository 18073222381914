import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Paper, Button, TextField, Grid, Table, TableCell, TableHead, TableBody, TableRow, Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Tabs, Tab } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ComponentFields from './ComponentFields';
import CancelIcon from '@material-ui/icons/Cancel';
import FlareIcon from '@material-ui/icons/Flare';
import { checkApiStatus, onCheckFormPermission } from '../../../utils_v2';
import { globalGetService, globalPutService, globalPostService } from '../../../utils_v2/globalApiServices';
const SectionDetail = ({params, assetSections, section, toggleModalFn, getResponseBack, currentFormDetail}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false)
    const [tabIndex, setTabIndex] = useState('observation')
    const [sectionCrud, setSectionCrud] = useState(section.data)
    const [error, setError] = useState({});
    const [obsError, setObsError] = useState([]);
    const [componentFields, setComponentFields] = useState({mode:'', sectionId:null, modal:false, data:null})
    const findDuplicates = (strArray) => {
        return strArray.filter((item, index) => strArray.indexOf(item.trim()) !== index)
    }
    const onFieldChange = (key, value, index) => {
        if(index === undefined){
            setSectionCrud({...sectionCrud, name:value})
        }else{
            setSectionCrud({...sectionCrud, observations: sectionCrud.observations.map((item, itemIndex) => index !== itemIndex ? item : {
                ...item,[key]:value
            })});
        }
    }
    const onAddObservation = () => {
        setSectionCrud({...sectionCrud, observations:[...sectionCrud.observations, {label:'',value:null,remarks:''}]})
    }
    const onRemoveObservation = (index) => {
        setSectionCrud({...sectionCrud, observations:sectionCrud.observations.filter((item, itemIndex) => index !== itemIndex)})
    }
    
    const onAddEditSection = () => {
        let validationInputs = {}, observationErrors = [], isError=false, compFieldError=[];
        validationInputs = {
            name:sectionCrud.name?.trim()?.length ? '':'Please enter Name',
        }
        // check for Section Duplication 
        if(sectionCrud.name?.trim()?.length){
            let arr = [];
            if(sectionCrud.id){
                arr = assetSections.filter(item => item.id !== sectionCrud.id).map(item => item.name.toLowerCase());
            }else{
                arr = assetSections.map(item => item.name.toLowerCase())
            }
            let duplicates = findDuplicates([...arr, sectionCrud.name.toLowerCase()])
            if(duplicates.length){
                validationInputs = {
                    ...validationInputs,
                    name:'Section name already exist'
                }    
            }
        }
        if(sectionCrud.observations.length){
            observationErrors = sectionCrud.observations.map(item => {
                return {
                    label: item?.label?.trim()?.length ? '':'Please enter Remarks',
                    remarks: item?.value === 1 ? item?.remarks?.trim()?.length ? '':'Please enter Remarks, for Poor Rating':'',
                }
            })
            observationErrors.map(data => {
                if(!Object.keys(data).every((k) => { return data[k] === ''})){
                    isError = true
                }
            });
        }else{
            isError = false
        }
        if(sectionCrud.observations.length && !isError){
            let duplicates = findDuplicates(sectionCrud.observations.map(item => item.label.toLowerCase()));
            if(duplicates.length){
                validationInputs = {
                    ...validationInputs,
                    observationDuplicate:'Observation cannot be duplications'
                }
            }
        }
        if(sectionCrud.id && sectionCrud?.components?.length){
            let duplicates = findDuplicates(sectionCrud.components.filter(item => item?.title?.trim()?.length).map(item => item.title.toLowerCase()));
            if(duplicates.length){
                validationInputs = {
                    ...validationInputs,
                    compDuplicate:'Component Section name cannot be duplications'
                }
            }
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''}) && !isError){
            if(sectionCrud.id){
                globalPutService(`technical/v2/workorder/${params.workOrderSlug}/asset-sections/${sectionCrud.id}/`, sectionCrud)
                .then(response => {
                    if(checkApiStatus(response)){
                        toggleModalFn(); getResponseBack();
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }else{
                        enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }
                })
            }else{
                globalPostService(`technical/v2/workorder/${params.workOrderSlug}/asset-sections/`, [sectionCrud])
                .then(response => {
                    if(checkApiStatus(response)){
                        toggleModalFn();getResponseBack();
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }else{
                        enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }   
                })
            }
        }else{
            setError({...validationInputs, obsError:observationErrors, compFieldError:[]});
            enqueueSnackbar(`Data validation error in ${validationInputs?.compDuplicate?.trim().length ? ' component fields':''} ${isError === false ? ' observation fields':''}`, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
        }
    }
    const getComponentFields = () => {
        globalGetService(`technical/v2/workorder/${params.workOrderSlug}/${sectionCrud.id}/asset-sections-components/`)
        .then(response => {
            if(checkApiStatus(response)){
                setComponentFields({mode:'edit', sectionId: sectionCrud.id, modal:true, data:response.data.data})
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }
            setLoading(false)
        })
    }
    const onChangeComponentFields = (key, value, compIndex, questionIndex = null) => {
        if(questionIndex !== null){
            setSectionCrud({...sectionCrud, components:sectionCrud.components.map((component, cpIndex) => cpIndex !== compIndex ? component : {
                ...component,
                question_answers: component.question_answers.map((question, qIndex) => qIndex !== questionIndex ? question : {...question,[key]:value})
            })})
        }else{
            setSectionCrud({...sectionCrud, components:sectionCrud.components.map((component, cpIndex) => cpIndex !== compIndex ? component : {...component,[key]:value} )})
        }
    }
    const updateComponentRes = (data) => {
        setSectionCrud({...sectionCrud, components: data});
    }
    const onResetError = (key, itemIndex) => {
        setError({...error, obsError:error.obsError.map((item, index) => index !== itemIndex ? item:{
            ...item, [key]:''
        })})
    }
    return(
        <>
            <Dialog
                open={section.modal}
                onClose={toggleModalFn}
                className='project-management-modal'
                maxWidth='lg'
            >
                <DialogTitle id="scroll-dialog-title">
                    { onCheckFormPermission(currentFormDetail) ? `${section.mode === 'add' ?'ADD':'EDIT'} Section`:`View Section`}
                </DialogTitle>
                <DialogContent dividers={true}>
                    <div style={{width:'700px'}}>
                        <Grid container spacing={1}>
                            <Grid item md={12}>
                                <TextField
                                    disabled={!onCheckFormPermission(currentFormDetail)}
                                    required
                                    id='name'
                                    label='Section Name'
                                    fullWidth
                                    margin="normal"
                                    value={sectionCrud.name}
                                    error={error?.name}
                                    helperText={error?.name||''}
                                    onChange={(e) => onFieldChange('name', e.target.value)}
                                    onFocus={() => setError({...error,name:''})}
                                    InputLabelProps={{shrink: true}}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={12}>
                                <Paper style={{marginBottom:'10px'}}>
                                    <Tabs indicatorColor='primary' value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)}>
                                        <Tab label="Observation" value='observation'/>
                                        {section.mode !== 'add' ?
                                            <Tab label="Component Fields" value='component_fields' />:null
                                        }
                                    </Tabs>
                                </Paper>
                                { tabIndex === 'observation' ? 
                                    <Paper style={{padding:'10px'}}>
                                        {onCheckFormPermission(currentFormDetail) ?
                                            <Button style={{marginBottom:'5px'}} onClick={onAddObservation} color='primary' size='small' variant='outlined'>Add Observation</Button>:null
                                        }
                                        <Table className='mui-table-format'>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Rating</TableCell>
                                                    <TableCell>Remarks</TableCell>
                                                    <TableCell align='right'>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                { sectionCrud.observations.map((observation, index) => 
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            <TextField
                                                                disabled={!onCheckFormPermission(currentFormDetail)}
                                                                id={`label_${index}`}
                                                                fullWidth
                                                                margin="none"
                                                                value={observation.label}
                                                                error={error?.obsError?.length && error.obsError[index] && error.obsError[index].label}
                                                                helperText={error?.obsError?.length && error.obsError[index] && error.obsError[index].label ? error.obsError[index].label:''}
                                                                onFocus={() => onResetError('label',index)}
                                                                onChange={(e) => onFieldChange('label', e.target.value, index)}
                                                                InputLabelProps={{shrink: true}}
                                                                variant='outlined'
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            {(() => {
                                                                let options = [{label:'Poor', value:1}, {label:'Average', value:2}, {label:'Good', value:3}];
                                                                let optionValue = null;
                                                                if(observation.value){
                                                                    optionValue = options.find((option) => option.value === observation.value);
                                                                }
                                                                return(
                                                                    <Autocomplete
                                                                        disabled={!onCheckFormPermission(currentFormDetail)}
                                                                        options={options}
                                                                        getOptionLabel={option => option.label}
                                                                        id="value"
                                                                        value={optionValue}
                                                                        onChange={(e, value) => onFieldChange('value', value ? value.value:null, index)}
                                                                        renderInput={params => <TextField {...params} margin="none" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                                                    />
                                                                )
                                                            })()}
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                disabled={!onCheckFormPermission(currentFormDetail)}
                                                                required={observation?.value === 1 ?true:false}
                                                                id={`remarks_${index}`}
                                                                fullWidth
                                                                margin="none"
                                                                value={observation.remarks}
                                                                error={error?.obsError?.length && error.obsError[index] && error.obsError[index].remarks}
                                                                helperText={error?.obsError?.length && error.obsError[index] && error.obsError[index].remarks ? error.obsError[index].remarks:''}
                                                                onFocus={() => onResetError('remarks',index)}
                                                                onChange={(e) => onFieldChange('remarks', e.target.value, index)}
                                                                InputLabelProps={{shrink: true}}
                                                                variant='outlined'
                                                            />
                                                        </TableCell>
                                                        <TableCell align='right'>
                                                            {onCheckFormPermission(currentFormDetail) ?
                                                                <Tooltip title='Remove' arrow>
                                                                    <CancelIcon onClick={() => onRemoveObservation(index)} color='secondary' fontSize='small' />
                                                                </Tooltip>:null
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                                {!sectionCrud.observations.length ? 
                                                    <TableRow>
                                                        <TableCell align='center' colSpan={4}>No observation added yet!</TableCell>
                                                    </TableRow>:null
                                                }
                                            </TableBody>
                                        </Table> 
                                        {error.observationDuplicate ? <p className='error-msg'>{error.observationDuplicate}</p> :null}
                                    </Paper>:null
                                }    
                                { tabIndex === 'component_fields' ? 
                                    <div>
                                        { onCheckFormPermission(currentFormDetail) ?
                                            <p style={{textAlign:'right', marginBottom:'6px'}}>
                                                <Tooltip title='Config Component Fields'><FlareIcon onClick={getComponentFields} color='primary' fontSize='medium' /></Tooltip>
                                            </p>:null
                                        }
                                        {sectionCrud.components.map((compSection, compIndex) =>
                                            <Paper style={{padding:'10px', marginBottom:'10px', background:'#f1f1f1'}}>
                                                <div>
                                                    <TextField
                                                        disabled={!onCheckFormPermission(currentFormDetail)}
                                                        id={`title_${compIndex}`}
                                                        fullWidth
                                                        margin="none"
                                                        placeholder='Title Goes Here'
                                                        value={compSection.title}
                                                        onChange={(e) => onChangeComponentFields('title', e.target.value, compIndex)}
                                                        InputLabelProps={{shrink: true}}
                                                        onFocus={() => setError({...error,compDuplicate:''})}
                                                        variant='outlined'
                                                    />
                                                </div>
                                                <div>
                                                    <Grid container spacing={1}>
                                                        {compSection.question_answers.map((questionItem, questionIndex) => 
                                                            <Grid item md={4}>
                                                                <TextField
                                                                    disabled={!onCheckFormPermission(currentFormDetail)}
                                                                    id={`value_${questionIndex}`}
                                                                    label={questionItem.label}
                                                                    fullWidth
                                                                    margin="normal"
                                                                    value={questionItem.value}
                                                                    onChange={(e) => onChangeComponentFields('value', e.target.value, compIndex, questionIndex)}
                                                                    InputLabelProps={{shrink: true}}
                                                                    variant='outlined'
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </div>
                                            </Paper>
                                        )}
                                        {!sectionCrud?.components?.length ? <p style={{textAlign:'center'}}>No Component Fields added yet!</p>:null}
                                        {error.compDuplicate ? <p className='error-msg'>{error.compDuplicate}</p>:null}
                                    </div>:null
                                }
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size="small" variant="outlined">Cancel</Button>
                    { onCheckFormPermission(currentFormDetail) ?
                        <Button disabled={isLoading} onClick={onAddEditSection} color='primary' size="small" variant="contained">
                            {isLoading ? <CircularProgress size={24} />:'Save'}
                        </Button>:null
                    }
                </DialogActions>
            </Dialog>
            { componentFields.modal ? 
                <ComponentFields 
                    componentFields={componentFields} 
                    toggleModalFn={() => setComponentFields({modal:false, sectionId:null, mode:'', data:null})}
                    getResponseBack={(data) => updateComponentRes(data)}
                />
                :null
            }
        </>
    )
}
export default withRouter(SectionDetail);