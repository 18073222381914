import { globalGetService, globalPutService, globalPostService, globalStaticPostService, globalDeleteService, globalDownloadService, globalExportService } from '../../../../globalServices/';
import { toastFlashMessage, downloadFileType } from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel';
import moment from 'moment';
import { displayDateTimeFormatShort } from '../../../../constants';
import { T011SectionCrud } from './';
import { browserHistory } from 'react-router';
const sectionIds = [5, 6, 7, 8, 9, 10, 14, 15, 16, 17, 21, 22, 23, 24, 25];
const assetTypes = {
  1: 'MSN',
  3: 'APU',
  4: 'LG',
  5: 'PROPELLER'
}
export function exportReport(aircraft) {
  let formInfo = this.state.formInfo
  this.setState({ pageLoader: true });
  globalExportService(`technical/workorder/${this.props.params.workOrderSlug}/gap-report-sections/?download=xls`)
    .then(response => {
      this.setState({ pageLoader: false });
      let formInfo = this.state.formInfo
      let assetTypeNames = {
        3: 'APU',
        4: 'LG',
        5: 'PROPELLER'
      }
      trackActivity('T011', {
        event_type: 'Report Exported',
        asset: formInfo.asset.asset_type === 1 ? `MSN ${formInfo.asset.msn}` : [3, 4, 5].includes(formInfo.asset.asset_type) ? `${assetTypeNames[formInfo.asset.asset_type]} ${formInfo.asset.serial_number}` : `ESN ${formInfo.asset.esn}`,
        page_title: formInfo.current_form.name.toUpperCase() ? formInfo.current_form.name.toUpperCase() : 'PRS',
        file_name: 'PRS Report',
        extension: 'XLS'
      })
      downloadFileType(response, formInfo.project.project_number + '_' + assetTypes[formInfo.asset.asset_type] + '_' + (formInfo.asset.msn ? formInfo.asset.msn : formInfo.asset.serial_number) + '_T011.', 'xls');
    })
}

export function getFileIcons() {
  globalPostService(`console/get-constants/`, { constant_types: ['file_extension_drive'] })
    .then(response => {
      if (response.data.statusCode == 200) {
        this.setState({ fileIcons: response.data.data })
      }
    })
}

export function getTableContentsApi(props, queryParam = {}, source) {
  this.setState({
    t011ReportInfo: { items: null, list: [] },
    pageLoader: true
  })
  let queryFilter = {}
  Object.keys(queryParam).map(key => {
    if (queryParam[key] !== undefined && queryParam[key] !== null && queryParam[key] !== "") {
      queryFilter = { ...queryFilter, [key]: queryParam[key] }
    }
  })
  globalGetService('technical/workorder/' + props.params.workOrderSlug + '/gap-report-sections/', queryFilter)
    .then(response => {
      this.setState({pageLoader:false})
      if (response.data.statusCode == 200) {
        this.setState({
          t011ReportInfo: response.data.data,
          fullScreen: this.state.fullScreen,
          gap_report_ids: [],
          // sectionId:response.data.data.list && response.data.data.list.length && response.data.data.list.filter(item => item.name === this.props.params.tab_name).length ? response.data.data.list.filter(item => item.name === this.props.params.tab_name)[0].id : 0}, () => this.getTableSectionsApi(props,{ section: this.state.sectionId
          sectionId: this.props.params.tab_name
        }, () => this.getTableSectionsApi(props, {
          section: this.state.sectionId
          , ...queryFilter
        }, source));
        this.getUserStatsApi(this.props);

      }
    });
}

export function fetchActivityLogs(queryParams) {
  this.setState({ pageLoader: true });
  let params = queryParams && queryParams.gap_report_section_id !== undefined && queryParams.gap_report_section_id !== 0 ? { per_page: 200, ...queryParams } : { per_page: 200 }
  globalGetService(`technical/workorder/${this.props.params.workOrderSlug}/deleted-gap-reports/`, params)
    .then(response => {
      this.setState({ pageLoader: false });
      if (response.data.statusCode === 200) {
        this.setState({ searchLog: '', activityLogs: response.data.data.list.filter(item => item.deleted_by !== undefined && item.deleted_by !== null && item.deleted_by !== '').map(log => `<span style="font-weight: 600;font-family: system-ui;">${log.deleted_by}</span> deleted item <span style="font-weight: 600;font-family: system-ui;">${log.description}</span> from Section: <span style="font-weight: 600;font-family: system-ui;">${log.gap_report_section}</span>, <span style="font-weight: 600;font-family: system-ui;">${moment(log.deleted_at).format(displayDateTimeFormatShort) + ' UTC'}</span>`), showActivityLogs: true })
      }
    })
}

export function fetchTags() {
  this.setState({ tags: [] });
  globalGetService(`/technical/prs-lessor-tags/`)
    .then(response => {
      if (response.data.statusCode === 200) {
        this.setState({ tags: response.data.data })
      }
    })
}

export function addTags(props, tag) {
  this.setState({ pageLoader: true });
  if (tag.value) {
    this.addIssueTags(this.props, { id: tag.value, name: tag.name })
  } else {
    globalPostService(`/technical/prs-lessor-tags/`, { name: tag.inputValue })
      .then(response => {
        this.setState({ pageLoader: false });
        if (response.data.statusCode === 200) {
          if (this.state.issueDetail.open) {
            this.addIssueTags(this.props, { id: response.data.data.id, name: response.data.data.name })
          }

        }
      })
  }
}

export function addIssueTags(props, tag) {
  this.setState({ pageLoader: true });
  globalPostService(`/technical/workorder/${this.props.params.workOrderSlug}/gap-report/${this.state.issueDetail.data.id}/tags/`, tag)
    .then(response => {
      this.setState({ pageLoader: false });
      if (response.data.statusCode === 200) {
        this.fetchTags()
        this.fetchIssueTags(props)
        trackActivity('T011', {
          event_type: 'Issue Tag Added',
          page_title: 'PRS Detail',
          workorder_slug: props.params.workOrderSlug,
          form_slug: props.params.formSlug,
          id: this.state.issueDetail.data.id ? this.state.issueDetail.data.id : '',
          issue_tag: tag ? tag : '',
          response_body: response.data.data ? response.data.data : '',
          asset_type: props.params.type ? props.params.type : '',
          aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : ''
        })
      }
    })
}

export function removeIssueTags(props, tagid) {
  this.setState({ pageLoader: true });
  globalDeleteService(`/technical/workorder/${this.props.params.workOrderSlug}/gap-report/${this.state.issueDetail.data.id}/tags/${tagid}/`)
    .then(response => {
      this.setState({ pageLoader: false });
      if (response.data.statusCode === 200) {
        this.fetchTags()
        this.fetchIssueTags(props)
        trackActivity('T011', {
          event_type: 'Issue Tag Removed',
          page_title: 'PRS Detail',
          workorder_slug: props.params.workOrderSlug,
          form_slug: props.params.formSlug,
          id: this.state.issueDetail.data.id ? this.state.issueDetail.data.id : '',
          tagid: tagid ? tagid : '',
          response_body: response.data.data ? response.data.data : '',
          asset_type: props.params.type ? props.params.type : '',
          aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : ''
        })
      }
    })
}

export function fetchIssueTags(props) {
  this.setState({ pageLoader: true, issueTags: [] });
  globalGetService(`/technical/workorder/${this.props.params.workOrderSlug}/gap-report/${this.state.issueDetail.data.id}/tags/`)
    .then(response => {
      this.setState({ pageLoader: false });
      if (response.data.statusCode === 200) {
        this.setState({ issueTags: response.data.data })
        this.fetchTags()
      }
    })
}

export function fetchIssueComments() {
  this.setState({ pageLoader: true, comments: [] });
  globalGetService(`/technical/workorder/${this.props.params.workOrderSlug}/gap-report/${this.state.issueDetail.data.id}/comments/`)
    .then(response => {
      this.setState({ pageLoader: false });
      if (response.data.statusCode === 200) {
        this.setState({ comments: response.data.data })
      }
    })
}

export function addIssueComments(item_id, text) {
  this.setState({ pageLoader: true });
  globalPostService(`/technical/workorder/${this.props.params.workOrderSlug}/gap-report/${item_id}/comments/`, { comment: text })
    .then(response => {
      this.setState({ pageLoader: false });
      if (response.data.statusCode === 200) {
        this.fetchIssueComments(item_id)
        trackActivity('T011', {
          event_type: 'Issue Comment Added',
          page_title: 'PRS Detail',
          item_id: item_id ? item_id : '',
          comment_text: text ? text : '',
          response_body: response.data.data ? response.data.data : ''
        })
      }
    })
}

export function updateIssueComment(item_id, text, commentId) {
  this.setState({ pageLoader: true });
  globalPutService(`/technical/workorder/${this.props.params.workOrderSlug}/gap-report/${item_id}/comments/${commentId}/`, { comment: text })
    .then(response => {
      this.setState({ pageLoader: false });
      if (response.data.statusCode === 200) {
        this.fetchIssueComments(item_id)
        trackActivity('T011', {
          event_type: 'Issue Comment Updated',
          page_title: 'PRS Detail',
          item_id: item_id ? item_id : '',
          comment_id: commentId ? commentId : '',
          comment_text: text ? text : '',
          response_body: response.data.data ? response.data.data : ''
        })
      }
    })
}

export function deleteIssueComments(item_id, commentId) {
  this.setState({ pageLoader: true });
  globalDeleteService(`/technical/workorder/${this.props.params.workOrderSlug}/gap-report/${item_id}/comments/${commentId}/`)
    .then(response => {
      this.setState({ pageLoader: false });
      if (response.data.statusCode === 200) {
        this.fetchIssueComments(item_id)
      }
    })
}

export function getTableSectionsApi(props, queryParam, source) {
  let queryFilter = {}
  let applyQueryFilter = {}
  if (queryParam !== undefined && Object.keys(queryParam).length) {
    Object.keys(queryParam).map(key => {
      if (queryParam[key] !== undefined && queryParam[key] !== null && queryParam[key] !== "") {
        queryFilter = { ...queryFilter, [key]: key === 'created_at_from' || key === 'created_at_to' ? moment(queryParam[key]).format('YYYY-MM-DD') : queryParam[key] }
        applyQueryFilter = { ...queryFilter, [key]: key === 'created_at_from' || key === 'created_at_to' ? moment(queryParam[key]).format('DD MMM, YYYY') : queryParam[key] }
      }
    })
  }
  let t011SectionInfo = this.state.t011SectionInfo
  this.setState({ pageLoader: source === 'init', pageLoader: true });
  delete queryFilter.filter
  globalGetService('technical/workorder/' + props.params.workOrderSlug + '/gap-report/', { ...queryFilter, page: queryFilter.page ? queryFilter.page : 1, per_page: queryFilter.per_page ? queryFilter.per_page : 10, sort: this.state.sort, sort_by: this.state.sort_by })
    .then(response => {
      this.setState({ pageLoader: false });
      delete queryFilter.per_page
      delete queryFilter.page
      delete queryFilter.keyParam
      delete applyQueryFilter.per_page
      delete applyQueryFilter.page
      delete applyQueryFilter.keyParam
      if (response.data.statusCode == 200) {
        let location = browserHistory.getCurrentLocation()
        this.setState({ gap_report_ids: this.state.issueDetail.open ? this.state.gap_report_ids : [], t011SectionInfo: this.state.issueDetail.open ? { ...t011SectionInfo, pagination: response.data.data.pagination, list: [...t011SectionInfo.list, ...response.data.data.list] } : response.data.data, filter: applyQueryFilter, t011SectionInfoFiltered: response.data.data.list, sectionId: queryParam !== undefined && queryParam.section ? queryParam.section : null, fullScreen: this.state.fullScreen })
        this.getUserStatsApi(this.props);
      }
    }, queryParam.free_text ? trackActivity('T011', { event_type: 'Item Searched', page_title: 'PRS', search_key: queryParam.free_text ? queryParam.free_text : '' }) : '');
}

export function fetchIssueDetails() {
  let location = browserHistory.getCurrentLocation()
  globalGetService(`/technical/workorder/${this.props.params.workOrderSlug}/gap-report/${location.query.issueId}/`)
    .then(response => {
      if (response.data.statusCode === 200) {
        this.setState({ issueDetail: { open: true, data: response.data.data } }, () => {
          this.fetchIssueComments(location.query.issueId)
          this.fetchIssueTags(this.props)
        })
      }
    })
}
export function updateTableSectionsFieldApi(props, key, data, id, addattachment = false) {
  const { filter } = this.state
  let formData = new FormData();
  if (key == 'files') {
    this.setState({ pageLoader: true })
    for (let f of data) {
      formData.append('files', f)
    }
  } else {

    formData.append(key, data === '' || data === null ? '' : data);
  }
  if (key !== 'gap_report_section') {
    formData.append('gap_report_section', this.state.issueDetail.data.gap_report_section.id ? this.state.issueDetail.data.gap_report_section.id : this.state.issueDetail.data.gap_report_section.value)
  }
  globalPutService(`technical/workorder/${props.params.workOrderSlug}/gap-report/${id}/`, formData)
    .then(response => {
      this.setState({ pageLoader: false })
      if (response.data.statusCode == 200) {
        this.getFormInfoApi(this.props);
        if(key  ==  "files" && addattachment){
          this.getTableContentsApi(this.props, {...filter});
        }
          this.getAssigneeFilterListApi(this.props)
        if(key !== 'files'){
          toastFlashMessage(response.data.message, 'success');
        }
        this.setState({ issueDetail: { ...this.state.issueDetail, data: { ...this.state.issueDetail.data, [key]: response.data.data[key] } } })
        trackActivity('T011', {
          event_type: 'Table Sections Field Updated',
          page_title: 'PRS Detail',
          workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
          form_slug: props.params.formSlug ? props.params.formSlug : '',
          asset_type: props.params.type ? props.params.type : '',
          aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : '',
          key: key ? key : '',
          data: data ? data : '',
          id: id ? id : '',
          request_body: formData ? formData : '',
          response_body: response.data.data ? response.data.data : ''
        })
      } else {
        trackActivity('T011', {
          event_type: 'Table Sections Field Update Failed',
          page_title: 'PRS Detail',
          workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
          form_slug: props.params.formSlug ? props.params.formSlug : '',
          asset_type: props.params.type ? props.params.type : '',
          aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : '',
          key: key ? key : '',
          data: data ? data : '',
          id: id ? id : '',
          request_body: formData ? formData : '',
          error_source: 'Backend',
          error_msg: response.data.message ? response.data.message : ''
        })
      }
    });
}

export function deleteTableSectionRowApi(props, id, sectionId, deleteType) {
  let gap_report_ids = deleteType === 'single' ? [id] : this.state.deleteType === 'section' ? this.state.t011SectionInfo.list.map(gapList => gapList.id) : this.state.gap_report_ids
  let url = this.state.deleteType === 'prs' ? `technical/workorder/${props.params.workOrderSlug}/gap-reports/delete/?is_reset=true` : `technical/workorder/${props.params.workOrderSlug}/gap-reports/delete/`
  // this.setState({ pageLoader: true })
  globalDeleteService(url, { gap_report_ids })
    .then(response => {
      this.setState({ pageLoader: false })
      if (response.data.statusCode == 200) {
        // this.setState({t011SectionInfo:response.data.data, sectionId: queryParam.section})
        // this.getTableSectionsApi(this.props);
        this.getTableContentsApi(props)
        this.setState({ t011SectionInfo: { ...this.state.t011SectionInfo, list: this.state.t011SectionInfo.list.filter(item => item.id !== id) } }, () => this.applyFilter())
        this.setState({ gap_report_ids: [] })
        if (this.state.issueDetail.open) {
          this.setState({
            issueDetail: { open: false, data: {} }
          })
        }
        toastFlashMessage(response.data.message, 'success')
        trackActivity('T011', {
          event_type: 'Table Section Row Deleted',
          page_title: 'PRS Detail',
          workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
          form_slug: props.params.formSlug ? props.params.formSlug : '',
          asset_type: props.params.type ? props.params.type : '',
          aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : '',
          delete_type: deleteType ? deleteType : '',
          id: id ? id : '',
          response_body: response.data.data ? response.data.data : ''
        })
      }
    });
}
export function downloadAllFilesApi(props = {}, gapReportId) {
  this.setState({ pageLoader: true });
  globalGetService(`/technical/workorder/${props.params.workOrderSlug}/gap-report/${gapReportId}/?download=all_files`)
    .then(response => {
      this.setState({ pageLoader: false });
      toastFlashMessage(response.data.message, 'success')
    })
}

export function deleteFileApi(deleteFileId) {
  this.setState({ pageLoader: true })
  globalDeleteService(`technical/workorder/${this.props.params.workOrderSlug}/gap-report/${parseInt(this.state.issueDetail.data.id)}/file/${deleteFileId}/`)
    .then(response => {
      this.setState({ pageLoader: false })
      if (response.data.statusCode === 200) {
        this.setState({ issueDetail: { ...this.state.issueDetail, data: { ...this.state.issueDetail.data, files: this.state.issueDetail.data.files.filter(file => file.id !== deleteFileId) } } })
        trackActivity('T011', {
          event_type: 'Attachment Deleted',
          page_title: 'PRS Detail',
          workorder_slug: this.props.params.workOrderSlug ? this.props.params.workOrderSlug : '',
          form_slug: this.props.params.formSlug ? this.props.params.formSlug : '',
          asset_type: this.props.params.type ? this.props.params.type : '',
          aircraft_slug: this.props.params.aircraft_slug ? this.props.params.aircraft_slug : '',
          issue_id: this.props.location.query.issueId ? this.props.location.query.issueId : '',
          file_id: deleteFileId ? deleteFileId : '',
          response_body: response.data.data ? response.data.data : ''
        })
      }
    })
}

export function onDownloadAttachmentApi(props, file) {
  this.setState({ pageLoader: true })
  globalExportService(`technical/workorder/${this.props.params.workOrderSlug}/gap-report/${this.props.location.query.issueId}/file-download/${file.id}/`)
    .then(response => {
      this.setState({ pageLoader: false })
      downloadFileType(response, file.name.split('.').slice(0, -1).join('.'), `.${file.name.split('.').pop()}`);
      trackActivity('T011', {
        event_type: 'Attachment Downloaded',
        page_title: 'PRS Detail',
        workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
        form_slug: props.params.formSlug ? props.params.formSlug : '',
        asset_type: props.params.type ? props.params.type : '',
        aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : '',
        issue_id: this.props.location.query.issueId ? this.props.location.query.issueId : '',
        file_id: file.id ? file.id : '',
        file_name: file.name ? file.name : '',
        response_body: response.data.data ? response.data.data : ''
      })
    })
}

export function updateTechnicalFormDataApi(props, data = {}, sectionId, update) {
  var formdata = data
  const { attachment, filter } = this.state
  data = { ...data, gap_report_section: data.category }
  let finaData = {}
  Object.keys(data).map(key => {
    if (data[key] !== "" && data[key] !== null) {
      finaData = { ...finaData, [key]: data[key] }
    }
  })
  // if(!sectionIds.includes(sectionId)){
  //   delete data['install_date']
  //   delete data['position']
  //   delete data['part_number']
  //   delete data['serial_number']
  // }
  this.setState({ pageLoader: true, openTechForm: false });
  globalPostService(`/technical/workorder/${props.params.workOrderSlug}/gap-report/`, finaData)
    .then(response => {
      this.setState({ pageLoader: false, openTechForm: update  });
      if (response.data.statusCode === 200) {
        this.setState({ issueDetail: { ...this.state.issueDetail, data: response.data.data }, T011SectionCrud: T011SectionCrud })
        this.getFormInfoApi(this.props);
        this.getCreatedByListApi(this.props)
        if(attachment && attachment.length){
          this.updateTableSectionsFieldApi(props, 'files', attachment, response.data.data.id, true)
        }else{
          this.getTableContentsApi(this.props, {...filter});
        }

        
        toastFlashMessage(response.data.message, 'success');
        trackActivity('T011', {
          event_type: 'Issue Added',
          page_title: 'PRS Detail',
          workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
          form_slug: props.params.formSlug ? props.params.formSlug : '',
          asset_type: props.params.type ? props.params.type : '',
          aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : '',
          request_body: finaData ? finaData : '',
          response_body: response.data.data ? response.data.data : ''
        })
      }
    });
}

export function getFormInfoApi(props) {
  globalGetService('technical/workorder/' + props.params.workOrderSlug + '/forms/', { form: props.params.formSlug })
    .then(response => {
      if (response.data.statusCode == 200) {
        this.setState({ formInfo: response.data.data, });
        let formInfo = response.data.data
        let assetTypeNames = {
          3: 'APU',
          4: 'LG',
          5: 'PROPELLER'
        }
        trackActivity('T011', {
          event_type: 'Page Visited',
          asset: formInfo.asset.asset_type === 1 ? `MSN ${formInfo.asset.msn}` : [3, 4, 5].includes(formInfo.asset.asset_type) ? `${assetTypeNames[formInfo.asset.asset_type]} ${formInfo.asset.serial_number}` : `ESN ${formInfo.asset.esn}`,
          page_title: formInfo.current_form.name.toUpperCase() ? formInfo.current_form.name.toUpperCase() : ''
        })
      }
    });
}

export function updateFormStatusApi(data) {
  this.setState({ pageLoader: true });
  globalPostService(`technical/workorder/${this.props.params.workOrderSlug}/form/${this.props.params.formSlug}/status-update/`, data)
    .then(response => {
      this.setState({ pageLoader: false });
      if (response.data.statusCode == 200) {
        toastFlashMessage(response.data.message, 'success');
        this.getFormInfoApi(this.props);
      }
    })
}

export function importExcelApi(files) {
  var formdata = new FormData();
  formdata.append('file', files[0])
  this.setState({ pageLoader: true });
  globalStaticPostService(`technical/workorder/${this.props.params.workOrderSlug}/import-gap-report/`, formdata)
    .then(response => {
      this.setState({ pageLoader: false });
      if (response.data.statusCode == 200) {
        this.setState({ importPRS: false, importError: '' })
        toastFlashMessage(response.data.message, 'success');
        let formInfo = this.props.formInfo
        let assetTypeNames = {
          3: 'APU',
          4: 'LG',
          5: 'PROPELLER'
        }
        this.setState({ files: {} });
        this.getTableContentsApi(this.props, {}, 'init');
      }
    })
}

export function getUserListApi(props) {
  globalGetService('technical/workorder/' + props.params.workOrderSlug + '/gap-report-created-by/')
    .then(response => {
      if (response.data.statusCode == 200) {
        this.setState({
          userList: response.data.data,
        })
      }
    });
}

export function getAssigneeListApi(props) {
  globalGetService(`technical/workorder/${props.params.workOrderSlug}/project-users/`)
    .then(response => {
      if (response.data.statusCode == 200) {
          this.setState({
            assigneeList: response.data.data
          })
        
      }
    })
}
export function getAssigneeFilterListApi(props) {
  globalGetService(`technical/workorder/${props.params.workOrderSlug}/project-users/?filter_assignee=true`)
    .then(response => {
      if (response.data.statusCode == 200) {
          this.setState({
            assigneeFilterList: response.data.data
          })
      }
    })
}
export function getCreatedByListApi(props) {
  globalGetService(`technical/workorder/${props.params.workOrderSlug}/project-users/?filter_created_by=true`)
    .then(response => {
      if (response.data.statusCode == 200) {
        this.setState({
          createdList: response.data.data
        })
      }
    })
}

export function getUserStatsApi(props) {
  globalGetService('technical/workorder/' + props.params.workOrderSlug + '/assignee-plot-data/')
    .then(response => {
      if (response.data.statusCode == 200) {
        this.setState({
          userStats: response.data.data,
        })
      }
    });
}

export function moveGapIdsApi(props, section, gapReportIds) {
  this.setState({ pageLoader: true })
  globalPostService(`technical/workorder/${props.params.workOrderSlug}/move-items/`, { item_ids: gapReportIds, to_section_id: section.id })
    .then(response => {
      if (response.data.statusCode == 200) {
        this.setState({ prsDropdownModal: false })
        toastFlashMessage(response.data.message, 'success')
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      }
    })
}

export function bulkAssigneeApi(props, section, gapReportIds) {
  this.setState({ pageLoader: true })
  globalPostService(`technical/workorder/${props.params.workOrderSlug}/prs-multiple-update/`, { item_ids: gapReportIds, assignee_id: section.id })
    .then(response => {
      if (response.data.statusCode == 200) {
        this.setState({ prsAssigneeModal: false })
        toastFlashMessage(response.data.message, 'success')
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      }
    })
}

export function bulkOpsApi(props, gapReportIds, key, value) {
  this.setState({ pageLoader: true })
  globalPostService(`technical/workorder/${props.params.workOrderSlug}/prs-multiple-update/`, { item_ids: gapReportIds, [key]: value })
    .then(response => {
      this.setState({ pageLoader: false })
      if (response.data.statusCode == 200) {
        toastFlashMessage(response.data.message, 'success')
        setTimeout(() => {
          this.getTableSectionsApi(this.props, { section: this.state.sectionId, filter: this.state.filter });
          this.getTableContentsApi(this.props);
        }, 1000)
      }
    })
}
