import React, { Fragment, useState ,useEffect} from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Button, Dialog, Grid, ListItem, ListItemText, DialogActions, DialogContent, DialogTitle, Tooltip, CircularProgress } from '@material-ui/core';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { FormSkeletonLoader } from '../../Elements';
import {arrayMoveImmutable} from 'array-move';
import { globalGetService, globalPostService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus } from '../../../utils_v2';
import SortIcon from '@material-ui/icons/Sort';
const SortableItem = SortableElement(({value}) => <div className='sort-section-list'>{value.name}</div>);
const SortableList = SortableContainer(({items}) => {
    // debugger
    return (
        <Grid item md={12} >
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} sortIndex={index} index={index} value={value} style={{zIndex: 100000}} />
            ))}
        </Grid>
    );
});
const SectionSorting = ({params, getResponseBack}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [formLoader, setFormLoader] = useState(true);
    const [sections, setSections] = useState([]);
    const [modal, setModal] = useState(false);
    const onSortEnd = ({oldIndex, newIndex}) => {
        setSections(arrayMoveImmutable(sections, oldIndex, newIndex));
    };
    useEffect(() => {
        const timer = setTimeout(() => {
          setFormLoader(false);
        }, 3000);
      }, []);

    const getSections = (loaderType) => {
        if (loaderType) {
            setFormLoader(true);
          }
        globalGetService(`technical/v2/workorder/${params.workOrderSlug}/sections/`, {type:'T001'})
        .then(response => {
            if(checkApiStatus(response)){
                setSections(response.data.data);
                setFormLoader(false); 
            }
        });
    }
    const updateSectionsSort = () => {
        setLoading(true);
        globalPostService(`technical/v2/workorder/${params.workOrderSlug}/sections/importSections/`, {type:'T001', sections: sections.map((item,index)=> {return {...item,physical_order:index+1}})})
        .then(response => {
            setLoading(false);
            if(checkApiStatus(response)){
                enqueueSnackbar('Sections display order updated Successfully', { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                getResponseBack();setModal(false);setSections([]);
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        })
    }
    return(
        <Fragment>
            <Tooltip title='Sort Section' arrow><SortIcon onClick={() => {setModal(true); getSections()}} color='primary' /></Tooltip>
            {modal ? 
                <Dialog
                    open={modal}
                    onClose={() => setModal(false)}
                    className='project-management-modal section-sorting-modal'
                >
                    <DialogTitle id="scroll-dialog-title">
                        Sort Section(s)
                    </DialogTitle>
                    <DialogContent dividers={true}>
                    {formLoader ?  <div style={{ minHeight: '380px', width:'380px'}}>  <FormSkeletonLoader /> </div> : (
                        <div className='form-section-sorting-modal'>
                            <Grid container spacing={1}>
                                <SortableList helperClass='sortableHelper' items={sections} onSortEnd={onSortEnd} />
                            </Grid>
                        </div>
                    )}
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} onClick={()=> setModal(false)} color="primary" size='small' variant='outlined'>Cancel</Button>
                        <Button disabled={isLoading} onClick={updateSectionsSort} color="primary" size='small' variant='contained'>
                        {isLoading ? <CircularProgress size={20} /> : 'Proceed'}
                        </Button>
                    </DialogActions>
                </Dialog>:null
            }   
        </Fragment>
    )
}
export default withRouter(SectionSorting);