import React, { Fragment, useEffect, useState } from "react";
import { useSnackbar } from 'notistack';
import { Paper, Grid, Button } from "@material-ui/core";
import { BudgetWrapper, ExpenseList, AddEditExpense, ExpenseAttachments, ChangeExpenseStatus } from '../components';
import { TableListComp, FilterUIComp, DeletePopUp, EmptyCollection, PageLoader } from '../../../shared_elements';
import FilterComponent from '../../../shared_elements/filter_component'
import { STableLoader } from '../../../shared_elements/loaders';
import { globalGetService, globalDeleteService, globalExportService } from '../../../utils_v2/globalApiServices'
import { removeEmptyKey, convertFilterObject, checkApiStatus, downloadFileType, onCheckPermission } from '../../../utils_v2'
import { expenseHd, expenseFilterOptions } from '../';
import { budgetAccessGroup, checkBudgetPermission } from '../';
import { trackActivity } from '../../../utils/mixpanel';
import { getLocalStorageInfo } from "../../../utils";
import { browserHistory } from "react-router";
const BudgetExpense = ({params}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(true);
    const [modal, setModal] = useState(false);
    const [mode, setMode] = useState('');
    const [filter, setFilter] = useState({});
    const [applyingFilter, setApplyingFilter] = useState({});
    const [sort, setSort] = useState('');
    const [sort_by, setSortBy] = useState('');
    const [expenseId, setExpenseId] = useState(null);
    const [project, setProject] = useState({});
    const [attachmentsInfo, setAttachments] = useState({modal:false, expense:null});
    const [budgetExpenses, setBudgetExpenses] = useState({list:[], pagination:{}});
    const [expenseStatus, setExpenseStatus] = useState({modal:false, expense:null})
    const [deleteExpense, setDeleteExpense] = useState({modal:false, expense:null})
    const [raisedFor, setRaisedFor] = useState([]);
    const [approverList, setApprover] = useState([]);
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        if( onCheckPermission('technical', 'budget_expense', 'R') || getLocalStorageInfo().user.security_groups.filter(group => [29, 24, 23, 21, 10].includes(group.id)).length){
            console.log();
        }
        else if( onCheckPermission('technical', 'budget_allocation', 'R') || getLocalStorageInfo().user.security_groups.filter(group => [29, 24, 23, 21, 10].includes(group.id)).length ){
            browserHistory.push(`/technical/project/${params.project_slug}/budget/allocation`);
        }
        else{
            browserHistory.push(`/technical/project/${params.project_slug}/budget/analytics`);
        }
        getDropdownFieldsFn({role:'expense_users'})
        .then(response=>{
            if(checkApiStatus(response)){
                setRaisedFor(response.data.data)
            }
        })
        getDropdownFieldsFn({role:'expense_users', subRole:'approver'})
        .then(response=>{
            if(checkApiStatus(response)){
                setApprover(response.data.data)
            }
        })
        getExpenseList({page:1, per_page:20}, 'skeletonLoader');
        getProjectDetail();
        trackActivity('Expense Management', {
            event_type: 'Page Visited',
            page_title: 'Budget Expense',
        });
        getCategories();
    },[]);
    const getDropdownFieldsFn = (query) => {
        return new Promise((resolve) =>
            globalGetService(`technical/users/`, query)
                .then(response => {
                    resolve(response)
                })
        )
    }
    const getCategories = () => {
        globalGetService(`technical/budget/categories/`)
        .then(response => {
            if(checkApiStatus(response)){
                setCategories(response.data.data);
            }
        });
    }
    const getProjectDetail = () => {
        setLoading(true);
        globalGetService(`technical/project/${params.project_slug}/`,{fields:'work_orders,archive_status,id,name,status,start_date,expected_end_date,leads,engineers,currency,budget_amount'})
        .then(response => {
            setLoading(false);
            if(checkApiStatus(response)){
                setProject(response.data.data);
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        });
    }
    const getExpenseList = (query={}, loaderType) => {
        if(loaderType === 'skeletonLoader'){ setSkeletonLoader(true); }else{ setLoading(true); }
        let filterQuery = Object.assign({}, query);
        delete filterQuery.asset;
        if(query?.asset?.trim()?.length){
            let assetInfo = query.asset.split('-')
            filterQuery = {
                ...filterQuery,
                asset_id:assetInfo[0],
                asset_type:assetInfo[1]
            }
        }
        globalGetService(`technical/project/${params.project_slug}/budgets/expenses/`,filterQuery)
        .then(response => {
            if(loaderType === 'skeletonLoader'){ setSkeletonLoader(false); }else{ setLoading(false); }
            if(checkApiStatus(response)){
                delete query.sort;
                delete query.sort_by;
                delete query.page;
                delete query.per_page;
                setFilter(query);
                setBudgetExpenses(response.data.data);
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        })
    }
    const onExportExpense = (fileName, extension) => {
        enqueueSnackbar('Expense Report Downloading ...', { variant: 'info',anchorOrigin:{horizontal:'right', vertical:'bottom'}})
        globalExportService(`technical/project/${params.project_slug}/budgets/expenses/`,removeEmptyKey({...filter, download:'xls'}))
        .then(response => {
            downloadFileType(response, 'Budget_Expense.', 'xls')
            closeSnackbar();
            enqueueSnackbar('Expense Report Downloaded', { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'bottom'}})
            trackActivity('Expense Management', {
                event_type: 'Expense Report Downloaded',
                page_title: 'Budget Expense',
                project_slug: params.project_slug,
                file_type: 'xls'
            });
        })
    }

    const onDeleteBudgetExpense = () => {
        setLoading(true);
        globalDeleteService(`technical/project/${params.project_slug}/budgets/expenses/${deleteExpense.expense.id}/`)
        .then(response => {
            setLoading(false);
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                setDeleteExpense({modal:false, expense:null})
                getExpenseList({page:budgetExpenses.pagination.page, per_page:budgetExpenses.pagination.per_page}, 'pageLoader')
                trackActivity('Expense Management', {
                    event_type: 'Expense Deleted',
                    page_title: 'Budget Expense',
                    project_slug: params.project_slug,
                    expense_id: deleteExpense.expense.id
                });
            }else{
                trackActivity('Expense Management', {
                    event_type: 'Expense Delete Failed',
                    page_title: 'Budget Expense',
                    project_slug: params.project_slug,
                    expense_id: deleteExpense.expense.id,
                    error_message: response.data.message
                });
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        })
    }
    const onDeleteExpenseAttachment = (attachmentId, expenseId) => {
        setAttachments({
            ...attachmentsInfo,
            expense:{
                ...attachmentsInfo.expense,
                attachments:attachmentsInfo.expense.attachments.filter(attachment => attachment.id !== attachmentId)
            }
        });
        setBudgetExpenses({
            ...budgetExpenses,
            list:budgetExpenses.list.map(item => {
                if(item.id === expenseId){
                    item.attachments = item.attachments.filter(attachment => attachment.id !== attachmentId)
                }
                return item
            })
        });
    }
    const createSortHandler = (sortField) => {
        let sort_order = 'asc';
        if(sortField === sort){
            sort_order = sort_by === 'asc' ? 'desc':'asc'
        }
        getExpenseList({page:1, per_page: budgetExpenses.pagination.per_page, sort_by:sortField, sort:sort_order},'pageLoader')
        setSort(sortField);
        setSortBy(sortField === sort ? sort_by === 'asc' ? 'desc':'asc' :'asc')
    }
    let filterOptions = expenseFilterOptions;
    if(onCheckPermission('technical', 'budget_expense','R')|| onCheckPermission('technical', 'budget_expense','C') || getLocalStorageInfo().user.security_groups.filter(group => [29,24,23,21,10].includes(group.id)).length){
        let users = raisedFor;
        // get the unique object on id
        users = users.filter((thing, index, self) =>
            index === self.findIndex((t) => (
                t.id === thing.id
            ))
        )
        let approvers = approverList;
        // get the unique object on id
        approvers = approvers.filter((thing, index, self) =>
            index === self.findIndex((t) => (
                t.id === thing.id
            ))
        )
        filterOptions = {
            ...filterOptions,
            user:{
                ...filterOptions.user,
                options:Object.keys(project).length ? users:[]
            },
            approver:{
                ...filterOptions.approver,
                options:Object.keys(project).length ? approvers:[]
            }
        }
    }else{
       delete filterOptions['user'];
       delete filterOptions['approver'];
    }
    if(project?.work_orders?.length){
        let workOrderOptions = project.work_orders.map((wk) => wk.asset).map((item) => {return {...item, unique_id:`${item.id}-${item.type}`}})
        filterOptions = {
            ...filterOptions,
            asset:{
                ...filterOptions.asset,
                options:workOrderOptions   
            }
        }
    }
    if(categories.length){
        filterOptions = {
            ...filterOptions,
            category:{
                ...filterOptions.category,
                options:categories   
            }
        }
    }
    return (
        <section className='projects-management-apps projects-management-section'>
            <BudgetWrapper project={project} getResponseBack={() => getProjectDetail()} totalExpense={budgetExpenses?.expense_total||null}>
                <div>
                    <Paper square className="filter-cta">
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12} sm={12} md={9} style={{borderRight:'1px solid #d7d7d7'}}>
                                <FilterComponent 
                                    filter={filter}
                                    filterMenu={filterOptions}
                                    getResponseBack={(applyFilter) => getExpenseList({...applyFilter, page:1, per_page:budgetExpenses.pagination.per_page}, 'pageLoader')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <ul className="list-inline cta-list">
                                    { project && project.currency ?
                                        <Fragment>
                                            { onCheckPermission('technical', 'budget_expense','C') || getLocalStorageInfo().user.security_groups.filter(group => [29,24,23,21,10].includes(group.id)).length ?
                                                <li className="list-inline-item">
                                                <Button color="primary" variant="contained" size="small" onClick={() => {setModal(true);setMode('add'); trackActivity('Expense Management', {page_title: 'Budget Expense', event_type:'Clicked on Submit expense Button'})}}>ADD New Expense</Button>
                                            </li>
                                            : null}
                                            <li className="list-inline-item">
                                                <Button onClick={onExportExpense} color="primary" variant="outlined" size="small">Export As Excel</Button>
                                            </li>
                                        </Fragment>:null
                                    }
                                </ul>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
                { skeletonLoader ? <STableLoader count={8} />:
                    <TableListComp
                        heads={expenseHd}
                        sort={sort}
                        sort_by={sort_by}
                        data={ budgetExpenses.list.map((item,index) =>
                            <ExpenseList
                                key={index}
                                index={index}
                                item={item}   
                                onEditExpense={(mode) => {setExpenseId(item.id);setModal(true);setMode(mode)}}
                                onDeleteExpense={() => setDeleteExpense({modal:true, expense:item})}                         
                                showAttachments={() => setAttachments({modal:true, expense:item})}
                                onChangeStatus={() => setExpenseStatus({modal:true, expense:item})}
                            />
                        )}
                        noRecord={ budgetExpenses.list.length ? null:
                            <EmptyCollection
                            title="No records found"
                            />
                        }
                        pagination={budgetExpenses.pagination}
                        onChangePage={(event, newPage) => getExpenseList(removeEmptyKey({sort:sort, sort_by:sort_by,page:newPage+1, per_page: budgetExpenses.pagination.per_page}), 'pageLoader')}
                        onChangeRowsPerPage={(event) => getExpenseList(removeEmptyKey({sort:sort, sort_by:sort_by, page:1, per_page: event.target.value}), 'pageLoader')}
                        createSortHandler={createSortHandler}
                    />
                }
            </BudgetWrapper>
            { modal ?
                <AddEditExpense 
                raisedFor={raisedFor}
                approverList={approverList}
                    id={expenseId}
                    expenses={budgetExpenses && budgetExpenses.list?budgetExpenses.list:[]}
                    project={project}
                    modal={modal}
                    mode={mode}
                    toggleModalFn={() => {setModal(false);setMode(''); setExpenseId(null)}}
                    existing_categories={categories}
                    getResponseBack={() => getExpenseList({page:budgetExpenses.pagination.page, per_page:budgetExpenses.pagination.per_page}, 'pageLoader')}
                />:null    
            }
            <DeletePopUp
                modal={deleteExpense.modal}
                toggleModalFn={() => setDeleteExpense({modal:false, expense:null})}
                title="Delete Budget Expense"
                content={<h4>Are you sure you want to delete?</h4>}
                deleteRecordFn={onDeleteBudgetExpense}
            />
            {attachmentsInfo.modal ?
                <ExpenseAttachments 
                    attachmentsInfo={attachmentsInfo}
                    toggleModalFn={() => setAttachments({modal:false, expense:null})}
                    getResponseBack={(attachmentId, expenseId) => onDeleteExpenseAttachment(attachmentId, expenseId)}
                />:null
            }
            { expenseStatus.modal ?
                <ChangeExpenseStatus 
                    expenseStatus={expenseStatus}
                    getResponseBack={() => getExpenseList({page:budgetExpenses.pagination.page, per_page:budgetExpenses.pagination.per_page}, 'pageLoader')}
                    toggleModalFn={() => setExpenseStatus({modal:false, expense:null})}
                />:null
            }
            { isLoading ? <PageLoader /> : null }
        </section>
    );
}
export default BudgetExpense;