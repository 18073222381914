import React, { Fragment } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress } from '@material-ui/core';
import {DropzoneArea} from 'material-ui-dropzone'
import { CkEditorText, LabelValueCard } from '../../../shared_elements';
import { SortableComponent } from '../../te001/components';
const AddEditDisassemblyFind = ({currentFormDetail, disAssemblyCrud, onFieldChange, updateSortInpsection, toggleModalFn, addEditDisassemblyFn, attachFindingFn, removeFindingImgFn, updateSlider, editImageFn}) => {
  return(
    <Dialog
      fullScreen
      open={disAssemblyCrud.modal}
      onClose={toggleModalFn}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">
        Disassembly Finding
      </DialogTitle>
      <DialogContent dividers={true}>
        <form className="add-disassembly-modal">
          <Grid container spacing={3}>
            { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
              <Grid item xs={12}>
                <TextField
                  required
                  id="title"
                  label="Title"
                  fullWidth
                  margin="normal"
                  value={disAssemblyCrud.data.title ? disAssemblyCrud.data.title:''}
                  inputProps={{ maxLength: 20 }}
                  InputLabelProps={{shrink: true}}
                  onChange={(e) => onFieldChange(e, 'title', e.target.value, 'findings')}
                />
              </Grid>:
              <LabelValueCard xs={12} md={12} label='Title' value={disAssemblyCrud.data.title ? disAssemblyCrud.data.title:'--'} />
            }

            <Grid item xs={12}>
              <CkEditorText
                disabled = { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save?false:true}
                htmlData={disAssemblyCrud.data.remarks ? disAssemblyCrud.data.remarks:''}
                onChangeData={(data) => onFieldChange('', 'remarks', data, 'findings')}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="image-sorting-cn">
                { disAssemblyCrud.data.finding_pictures.length ?
                  <SortableComponent
                    items={disAssemblyCrud.data.finding_pictures}
                    updateSlider={(index) => updateSlider(disAssemblyCrud.data.finding_pictures, index)}
                    currentFormDetail={currentFormDetail}
                    onComponentChange={(value, index) => onFieldChange('', 'description', value, 'finding_pictures', index)}
                    onRemoveInpection={(item, index) => removeFindingImgFn(item, index)}
                    onEditInspection={(item) => editImageFn(item)}
                    onSortEnd={updateSortInpsection}
                  />:null
                }
                { currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <div className="" style={{display:'inline-block', width:'250px', height: '20%'}}>
                          <DropzoneArea
                            filesLimit={10}
                            acceptedFiles={['image/*']}
                            showPreviewsInDropzone={false}
                            dropzoneText="Drag & Drop Pictures Here or Click"
                            dropzoneClass="drag-drop-cnt"
                            maxWidth="sm"
                            onDrop={(files) => attachFindingFn(files)}
                            showAlerts={['error', 'info']}
                            alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                          />
                        </div>
                      </li>
                    </ul>:null
                  }
              </div>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleModalFn} color="primary">Cancel</Button>
        { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
          <Button disabled={disAssemblyCrud.data.title && disAssemblyCrud.data.title.trim().length ? false:true } onClick={addEditDisassemblyFn} variant="contained" color="primary">Save</Button>:null
        }
      </DialogActions>
    </Dialog>
  )
}
export default AddEditDisassemblyFind;
