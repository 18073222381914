import moment from 'moment';
import { globalGetService, globalExportService, globalPutService, globalDeleteService, globalPostService, globalFileUploadService } from '../../utils_v2/globalApiServices';
import { fieldValidation } from '../../utils_v2/formValidation';
import { errorCode} from './index';
import { checkApiStatus, downloadFileType, getLocalStorageInfo } from '../../utils_v2';
export function getCurrentFormDetailApi(props={}){
    globalGetService(`technical/engine-workorder/${props.params.workOrderSlug}/forms/`,{form:props.params.formSlug})
    .then(response => {
      if(checkApiStatus(response)){
        this.setState({
          currentFormDetail:response.data.data
        })
      }
    })
}

export function changeFormStatusApi(props={}, data={}){
    this.setState({pageLoader:true});
    globalPostService(`technical/engine-workorder/${props.params.workOrderSlug}/form/${props.params.formSlug}/status-update/`,data)
    .then(response => {
        this.setState({pageLoader:false});
        if(checkApiStatus(response)){
        this.getCurrentFormDetailApi(this.props);
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }else{
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
    })
}
export function getShopVisitInfoApi(props={}, query={}){
  globalGetService(`technical/engine-workorder/${props.params.workOrderSlug}/shop-visit-details/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        shopVisitInfo: response.data.data,
        skeletonLoader:false
      })
    }
  })
}
export function exportShopVisitInfoApi(props={}, file={}){
  this.setState({pageLoader: true});
  let query = {download:file.extension};
  if(file.key !== ''){
    query = {
      ...query,
      summary_export_type: file.key
    }
  }
  globalGetService(`technical/engine-workorder/${props.params.workOrderSlug}/shop-visit-details/`, query)
  .then(response => {
    downloadFileType(response.data, (`TE006_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`), file.extension);
    this.setState({pageLoader: false});
    this.props.enqueueSnackbar('Build Standard Evaluation downloaded successfully', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
  })
}
export function editShopVisitInfoApi(props={}, data={}){
  let validationInputs = {
    report_date:errorCode['report_date'][fieldValidation({...errorCode['report_dateObj'], fieldval: data.details.report_date})],
    report_issued_place:errorCode['report_issued_place'][fieldValidation({...errorCode['report_issued_placeObj'], fieldval: data.details.report_issued_place})],
  }
  if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
    this.setState({pageLoader:true});
    globalPostService(`technical/engine-workorder/${props.params.workOrderSlug}/shop-visit-details/`, data)
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.setState({
          shopVisitInfo: response.data.data
        })
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else {
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }else{
    this.setState({error: validationInputs});
  }
}
export function getShopVisitApi(props={}, id){
  globalGetService(`technical/engine-workorder/${props.params.workOrderSlug}/shop-visit/${id}/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        shopVisitCrud: {
          data: response.data.data,
          error:{},
          modal:true,
          mode:'edit'
        }
      }))
    }
  })
}
export function addEditShopVisitApi(props={}, shopVisit={}){
  let validationInputs = {
    shop_visit_number:''
  }
  if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
    this.setState({pageLoader:true});
    if(shopVisit.data.id){
      globalPutService(`technical/engine-workorder/${props.params.workOrderSlug}/shop-visit/${shopVisit.data.id}/`, shopVisit.data)
      .then(response => {
        this.setState({pageLoader:false});
        if(checkApiStatus(response)){
          this.setState(prevState => ({
            ...prevState,
            shopVisitCrud: {data:{}, error:{}, modal:false, mode:''},
            shopVisitInfo: {
              ...prevState.shopVisitInfo,
              shop_visits: prevState.shopVisitInfo.shop_visits.map(item => item.id === shopVisit.id ? response.data.data:item)
            }
          }))
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }else {
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
      })
    }else{
      globalPostService(`technical/engine-workorder/${props.params.workOrderSlug}/shop-visit/`, shopVisit.data)
      .then(response => {
        this.setState({pageLoader:false});
        if(checkApiStatus(response)){
          this.setState(prevState => ({
            ...prevState,
            shopVisitCrud: {data:{}, error:{}, modal:false, mode:''},
            shopVisitInfo: {
              ...prevState.shopVisitInfo,
              shop_visits: [...prevState.shopVisitInfo.shop_visits, response.data.data]
            }
          }))
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }else{
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
      })
    }
  }else{
    this.setState(prevState => ({
      ...prevState,
      shopVisitCrud: {
        ...prevState.shopVisitCrud,
        error: validationInputs
      }
    }))
  }
}

export function deleteShopVisitApi(props={}, data={}){
  this.setState({pageLoader:true});
  globalDeleteService(`technical/engine-workorder/${props.params.workOrderSlug}/shop-visit/${data.id}/delete/`)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        shopVisitInfo: {
          ...prevState.shopVisitInfo,
          shop_visits: [...prevState.shopVisitInfo.shop_visits].filter(sv => sv.id !== data.id)
        },
        deleteModal:false,
        deleteIds:{}
      }))
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else {
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function deleteSVattachmentApi(props={}, deleteIds={}){
  if(deleteIds.id){
    this.setState({pageLoader:true});
    globalDeleteService(`technical/shop-visit-attachment/${deleteIds.id}/delete/`)
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.setState(prevState => ({
          ...prevState,
          shopVisitCrud: {
            ...prevState.shopVisitCrud,
            data: {
              ...prevState.shopVisitCrud.data,
              attachments:[...prevState.shopVisitCrud.data.attachments].filter((item, index) => index !== deleteIds.deleteIndex)
            }
          },
          deleteModal:false,
          deleteIds:{}
        }))
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else {
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }else{
    this.setState(prevState => ({
      ...prevState,
      shopVisitCrud: {
        ...prevState.shopVisitCrud,
        data: {
          ...prevState.shopVisitCrud.data,
          attachments:[...prevState.shopVisitCrud.data.attachments].filter((item, index) => index !== deleteIds.deleteIndex)
        }
      },
      deleteModal:false,
      deleteIds:{}
    }))
  }

}
export function uploadShopVisitAttachmentApi(props={}, data={}){
  return new Promise(
    function(resolve, reject){
      globalFileUploadService(`technical/engine-workorder/${props.params.workOrderSlug}/file-upload/`, data)
      .then(response => {
        resolve(response)
      })
    }
  )
}
