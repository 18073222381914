import React, { Component, Fragment } from 'react';
import { Tabs, Tab, Button, TextField } from '@material-ui/core';
import { withRouter } from 'react-router';
import {arrayMoveImmutable} from 'array-move';
import { isMobile } from 'react-device-detect';
import {DropzoneArea} from 'material-ui-dropzone'
import { TableListComp, CkEditorText, EmptyCollection, DeletePopUp, PageLoader } from '../../../shared_elements';
import { ComponentInpected, GeneralDetail, PrerequisiteContent, SortableComponent } from '../components';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { getCurrentFormDetailApi, changeFormStatusApi, getBoroscopeDetailsApi, editBoroscopeDetailsApi, deleteBoroscopeCompApi, deleteBoroscopePlateApi, addBoroscopePlateApi, exportBoroscopeDetailsApi, annotateBoroscopePlateApi } from '../apiServices';
import { fieldValidation } from '../../../utils_v2/formValidation';
import { errorCode, te001ComponenttHd } from '../';
import { FormWrapper, ReadPrerequisite, EditImageDialog, ImageSlider } from '../../Elements';
class BoreScopeInspection extends Component{
    constructor(props){
        super(props);
        this.state = {
          skeletonLoader: true,
          pageLoader: false,
          tabIndex: 0,
          currentFormDetail:null,
          component: {
            description: '',
            findings: null,
            id: null,
            status: 1
          },
          borescopeInspectionDetail: {
            borescope_details:{},
            components_inspected: [],
            inspection_pictures: [],
          },
          error: {},
          deleteModal: false,
          deleteIds:{},
          imgAnnotation: {
            modal:false,
            data:{}
          },
          sliderInfo: {
            modal:false,
            images:[],
            startIndex:0
          }
        }
        this.getCurrentFormDetailApi = getCurrentFormDetailApi.bind(this);
        this.changeFormStatusApi = changeFormStatusApi.bind(this);
        this.getBoroscopeDetailsApi = getBoroscopeDetailsApi.bind(this);
        this.editBoroscopeDetailsApi = editBoroscopeDetailsApi.bind(this);
        this.addBoroscopePlateApi = addBoroscopePlateApi.bind(this);
        this.deleteBoroscopeCompApi = deleteBoroscopeCompApi.bind(this);
        this.deleteBoroscopePlateApi = deleteBoroscopePlateApi.bind(this);
        this.annotateBoroscopePlateApi = annotateBoroscopePlateApi.bind(this);
        this.exportBoroscopeDetailsApi = exportBoroscopeDetailsApi.bind(this);
    }
    componentDidMount(){
        this.getCurrentFormDetailApi(this.props);
        this.getBoroscopeDetailsApi(this.props);
    }
    handleChange = (event, newValue) => {
        this.setState(prevState => ({
        ...prevState,
        tabIndex:newValue
        }))
    }
    addComponent = () => {
        this.setState(prevState => ({
        ...prevState,
        borescopeInspectionDetail: {
            ...prevState.borescopeInspectionDetail,
            components_inspected: [...prevState.borescopeInspectionDetail.components_inspected, this.state.component]
        }
        }))
    }
    onFieldChange = (event, keyParam, data) => {
        this.setState(prevState => ({
        ...prevState,
        borescopeInspectionDetail: {
            ...prevState.borescopeInspectionDetail,
            borescope_details: {
            ...prevState.borescopeInspectionDetail.borescope_details,
            [keyParam]: data
            }
        }
        }));
    }
    onComponentChange = (event, keyParam, data, arrayType, arrayIndex) => {
        this.setState(prevState => ({
        ...prevState,
        borescopeInspectionDetail: {
            ...prevState.borescopeInspectionDetail,
            [arrayType]: [...prevState.borescopeInspectionDetail[arrayType]].map((component, componentIndex) =>  componentIndex == arrayIndex ?  {
            ...component,
            [keyParam]: data
            }: component )
        }
        }));
    }
    deleteComponentInspectFn = () => {
        const { deleteIds } = this.state;
        if(deleteIds.deleteType === 'borescope_image'){
        this.deleteBoroscopePlateApi(this.props, deleteIds);
        }else{
        if(deleteIds.id){
            this.deleteBoroscopeCompApi(this.props, deleteIds)
        }else{
            this.setState(prevState => ({
            ...prevState,
            borescopeInspectionDetail: {
                ...prevState.borescopeInspectionDetail,
                components_inspected: [...prevState.borescopeInspectionDetail.components_inspected].filter((item, index) => index !== deleteIds.deleteIndex)
            },
            deleteIds:{},
            deleteModal:false
            }))
        }
        }
    }
    handleError = (value, key) => {
        this.setState((prevState)=> ({
        ...prevState,
        error: {
            ...prevState.error,
            [key]: errorCode[key][fieldValidation({...errorCode[key+'Obj'], fieldval: value})]
        }
        }
        ))
    }
    updateSlider = (inspection_pictures, index) => {
        this.setState(prevState => ({
        ...prevState,
        sliderInfo: {
            ...prevState.sliderInfo,
            modal:true,
            images: inspection_pictures.map(item => {return {title:'test', original:item.image, thumbnail:item.image}}),
            startIndex:index
        }
        }))
    }
    updateSortInpsection = ({oldIndex, newIndex}) => {
        const { borescopeInspectionDetail } = this.state;
        let inspectPictures = arrayMoveImmutable(borescopeInspectionDetail.inspection_pictures, oldIndex, newIndex)
        this.setState(prevState => ({
        ...prevState,
        borescopeInspectionDetail: {
            ...prevState.borescopeInspectionDetail,
            inspection_pictures: inspectPictures.map((item, index) => {return {...item, order: index+1}})
        }
        }))
    }
    saveImage = () =>{
        document.querySelector("#annotator").contentWindow.document.querySelector('#btn-download').click();
        setTimeout(() => {
        // this.props.saveEditedImage(this.props.findngImgCrud.data.data.id, document.querySelector("#annotator").contentWindow.document.querySelector('#editedImage').getAttribute('src'), this.props.findngImgCrud.index, this.props.disAssemblyCrud)
        }, 1000)
    }
    render(){
        const { tabIndex, borescopeInspectionDetail, currentFormDetail, deleteModal, deleteIds, imgAnnotation, sliderInfo, skeletonLoader, pageLoader, error } = this.state;
        return(
            <section className='projects-management-apps project-forms-section'>
                { currentFormDetail && Object.keys(currentFormDetail).length ? 
                    <FormWrapper formInfo={currentFormDetail} />:null
                }
                <div className='bore-scope-inspection'>
                    <div className="technical-engine-forms">
                        <h4 className="gen-detail-title">General Details <ReadPrerequisite content={<PrerequisiteContent/>} /></h4>
                        <GeneralDetail
                            currentFormDetail={currentFormDetail}
                            generalDetail={borescopeInspectionDetail.borescope_details}
                            onFieldChange={this.onFieldChange}
                            error={error}
                            handleError={this.handleError}
                            resetErrorKey={(key) => {
                            this.setState(prevState => ({
                                ...prevState,
                                error: {
                                ...prevState.error,
                                [key]: ''
                                }
                            }))
                            }}
                        />
                        <Tabs
                            value={tabIndex}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons={isMobile ? 'on':'auto'}
                        >
                            <Tab label="Components Inspected" />
                            <Tab label="Additional Information" />
                            <Tab label="Recommendations" />
                            <Tab label="Inspection Pictures" />
                        </Tabs>
                        { tabIndex === 0 &&
                            <div>
                                <div className="">
                                    { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
                                        <div className="text-right" style={{margin: '0 0 20px 0'}}>
                                            <Button
                                            size="small"
                                            variant="outlined"
                                            color="primary"
                                            disableElevation={true}
                                            onClick={() => {this.addComponent(); this.tabEnd.scrollIntoView({ behavior: 'smooth' });}}
                                            startIcon={<ControlPointIcon fontSize="small" color="primary" />}
                                            >
                                            Add Item
                                            </Button>
                                        </div>:null
                                    }
                                </div>
                                <TableListComp
                                    heads={ currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ? [...te001ComponenttHd, { label: 'Action', sortOption: false, actionCell: true }]:te001ComponenttHd}
                                    data={borescopeInspectionDetail.components_inspected.map((item,index) =>
                                    <ComponentInpected
                                        item={item}
                                        currentFormDetail={currentFormDetail}
                                        index={index+1}
                                        onFieldChange={(event, keyParam, data) => this.onComponentChange(event, keyParam, data, 'components_inspected', index)}
                                        previewDetail={(mode) => { this.setState({deleteModal:true, deleteIds:{...item, deleteType:'components_inspected', deleteIndex: index}}) }}
                                    />
                                    )}
                                    noRecord={ borescopeInspectionDetail.components_inspected.length ? null:
                                    <EmptyCollection title="No records found" />
                                    }
                                />
                            </div>
                        }
                        { tabIndex === 1 &&
                            <CkEditorText
                                disabled = { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save?false:true}
                                htmlData={borescopeInspectionDetail.borescope_details.additional_information}
                                onChangeData={(data) => this.onFieldChange('', 'additional_information', data)}
                            />
                        }
                        { tabIndex === 2 &&
                            <CkEditorText
                                disabled = { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save?false:true}
                                htmlData={borescopeInspectionDetail.borescope_details.recommendation}
                                onChangeData={(data) => this.onFieldChange('', 'recommendation', data)}
                            />
                        }
                        { tabIndex === 3 &&
                            <div className="image-sorting-cn">

                                { borescopeInspectionDetail.inspection_pictures && borescopeInspectionDetail.inspection_pictures.length ?
                                    <SortableComponent
                                        items={borescopeInspectionDetail.inspection_pictures}
                                        updateSlider={(index) => this.updateSlider(borescopeInspectionDetail.inspection_pictures, index)}
                                        currentFormDetail={currentFormDetail}
                                        onComponentChange={(value, index) => this.onComponentChange('', 'description', value, 'inspection_pictures', index)}
                                        onRemoveInpection={(item, index) => { this.setState({deleteModal:true, deleteIds:{...item, deleteType:'borescope_image'}});}}
                                        onEditInspection={(item) => this.setState({imgAnnotation:{modal:true, data:item}})}
                                        onSortEnd={this.updateSortInpsection}
                                    />:null
                                }
                                { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
                                    <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <div className="" style={{display:'inline-block', width:'250px', height: '20%', position:'relative'}}>
                                            <DropzoneArea
                                                filesLimit={1}
                                                acceptedFiles={['image/jpg', 'image/jpeg', 'image/png']}
                                                showPreviewsInDropzone={false}
                                                dropzoneText={<p>Drag & Drop Pictures<br/> OR <br/> Click Here</p>}
                                                dropzoneClass="drag-drop-cnt"
                                                maxWidth="sm"
                                                onDrop={(files) => this.addBoroscopePlateApi(this.props, files)}
                                                showAlerts={['error', 'info']}
                                                alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                                            />
                                            <p className="file-upload-note">Supported Extension .jpg, jpeg, .png</p>
                                            </div>
                                        </li>
                                    </ul>:null
                                }
                            </div>
                        }
                    </div>
                    <div ref={el => { this.tabEnd = el; }} />
                </div>
                <DeletePopUp
                    modal={deleteModal}
                    toggleModalFn={() => this.setState({deleteModal:false, deleteIds:{}})}
                    title="Delete"
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={this.deleteComponentInspectFn}
                />
                { imgAnnotation.modal ?
                    <EditImageDialog
                        imgAnnotation={imgAnnotation}
                        toggleModalFn={() => this.setState({imgAnnotation: {modal:false}})}
                        saveAnnotateImg={(blob) => this.annotateBoroscopePlateApi(this.props, blob, imgAnnotation.data)}
                    />:null
                }
                { sliderInfo.modal ?
                    <ImageSlider
                        sliderInfo={sliderInfo}
                        toggleModalFn={() => this.setState({sliderInfo:{modal:false, images:[], startIndex:''}})}
                    />:null
                }
            </section>
        )
    }
}
export default BoreScopeInspection;