import React, { Component } from 'react'
import { Row, Col, UncontrolledCollapse, Card, CardBody, Table, Button } from 'reactstrap';
import { UserManageSideBar } from '../components';
import '../../applications/technicalInspection/assets/styles/_common.scss'
import { AccordionWithHeader, AccordionNode, AccordionHeader, AccordionPanel } from 'react-accordion-with-header';
import { TableUIComp, ListLoader, NoRecordFound } from '../../shared';
import { imgStoragePath } from '../../constants';
import { PermissionList } from '../components';
import { trackActivity } from '../../utils/mixpanel';
import { FieldGroup, FieldSelect } from '../../applications/technicalInspection/Elements_V';
import { getSecurityPermissionListApi, exportSecurityPermissionApi } from '../actionCreators';
class SecurityPermissions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      arrowHeading: false,
      arrowListing: false,
      security_grp: 'Contract Manager',
      securityGrpList: [],
      loader: false,
      search: '',
      exportLoader: false,
    }
    this.getSecurityPermissionListApi = getSecurityPermissionListApi.bind(this)
    this.exportSecurityPermissionApi = exportSecurityPermissionApi.bind(this)
  }
  componentDidMount() {
    this.getSecurityPermissionListApi()
    trackActivity('User Management', {
      event_type: 'Page Visited',
      page_title: 'User Security Permission List'
    })
  }

  handleSearch = (value) => {
    this.setState(prevState => ({
      ...prevState,
      search: value
    }))
  }
  render() {
    const { arrowHeading, arrowListing, security_grp, securityGrpList, loader, search, exportLoader } = this.state

    let filteredSecurityGrpList = securityGrpList.filter(securityGrp => securityGrp.name.toLowerCase().indexOf(search.toLowerCase()) !== -1)
    return (
      <div className="contract-container user-profile-blk" >
        <UserManageSideBar />
        <Row>
          <Col md="6">
            <h4 className="gama" style={{ marginLeft: '275px' }}>
              <div className="title-block" >
                <h1>Roles</h1>
              </div >
            </h4>
          </Col>
        </Row>
        {loader ? <ListLoader /> :
          <Card style={{ width: '1156px', marginLeft: '270px' }}>
            <Row >
              <Col md={12}   >
                <ul style={{ listStyleType: 'none', margin: '15px', padding: '15px' }} >
                  {filteredSecurityGrpList.length ? filteredSecurityGrpList.map((item, i) =>
                    <PermissionList i={i} item={item} />
                  ) : <NoRecordFound description="Security Groups not found" />}
                </ul>
              </Col>
            </Row>
          </Card>
        }
      </div>
    )
  }
}
export default SecurityPermissions;
