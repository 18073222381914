import React, { useState, Fragment } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Button,CircularProgress,Dialog,DialogActions,DialogContent,DialogTitle,Grid,TextField} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import { GetApp } from '@material-ui/icons';
import { checkApiStatus ,downloadFileType} from '../../../utils_v2';
import { globalPostService,globalExportService } from '../../../utils_v2/globalApiServices';
const ImportViaSheet = ({params, getResponseBack}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false)
    const [modal, setModal] = useState(false);
    const [files, setFiles] = useState([]);
    const importSheet = () => {
        let formData = new FormData();    
        formData.append('file', files[0]);
        setLoading(true);
        globalPostService(`technical/workorder/${params.workOrderSlug}/import-gap-report/`, formData)
        .then(response => {
            if(checkApiStatus(response)){
                setModal(false);setFiles([]);
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                getResponseBack();
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
            setLoading(false);
        });
    }
    const downloadsampleTemplate =()=>{
        globalExportService('technical/v2/sections/sampleDownload/')
         .then(response=>{
                downloadFileType(response, 'Sample_Template.', 'xls' )
         })   
        }
    return(
        <Fragment>
            <Button onClick={() => setModal(true)} variant="outlined" color="primary" size="small">Import</Button>
            { modal ?
                <Dialog
                    open={modal}
                    onClose={() => setModal(false)}
                >
                    <DialogTitle id="scroll-dialog-title">Import PRS Sheet</DialogTitle>
                    <DialogContent dividers={true}>
                        <div style={{ width: "500px", textAlign:'center' }}>
                            <div style={{ width: "400px", margin:'0 auto' }}>
                                <DropzoneArea
                                    acceptedFiles={['.xls, .xlsx']}
                                    filesLimit={1}
                                    maxFileSize={10000000}
                                    showPreviewsInDropzone={false}
                                    useChipsForPreview={true}
                                    showPreviews={ true }
                                    dropzoneClass="drag-drop-cnt"
                                    showAlerts={["error", "info"]}
                                    clearOnUnmount={true}
                                    alertSnackbarProps={{ anchorOrigin: { vertical: "top", horizontal: "right", }, }}
                                    onChange={(files) => setFiles(files)}
                                />
                            </div>
                        </div>
                        <Grid item xs={12} md={12}>
                                <label>
                                    <p style={{width:"490px",fontSize:"12px"}}>Note:- Please Export the Report using EXPORT REPORT button in the listing. Update the same and import for changes to reflect in SPARTA</p>
                                </label>
                         </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setModal(false)} size='small' color="primary">Cancel</Button>
                        <Button disabled={!files.length || isLoading} onClick={importSheet} variant="contained" size='small' color="primary">
                            {isLoading ? <CircularProgress size={20} />:'Submit'}
                        </Button>
                    </DialogActions>
                </Dialog>:null
            }
            
        </Fragment>
    )
}
export default withRouter(ImportViaSheet);