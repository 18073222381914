import React from 'react';
import { browserHistory, withRouter } from 'react-router';
import { Tabs, Tab, Paper, Tooltip } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { trackActivity } from '../../../utils/mixpanel';
import { getLocalStorageInfo, onCheckPermission } from '../../../utils_v2';
import { InfoIcon, ToolTipHover } from '../../../shared';
const ProjectDetailTab = ({ params, tabIndex, handleTabChange }) => {
  const onChangeRoute = (event, newValue) => {
    switch (newValue) {
      case 'inspection':
        browserHistory.push(`/technical/project/view/${params.project_slug}`);
        break;
      case 'budget':
        browserHistory.push(`/technical/project/${params.project_slug}/budget/expense`);
        break;
      case 'task-management':
        browserHistory.push(`/technical/project/${params.project_slug}/tasks`);
        break;
      default:
        browserHistory.push('/technical/projects?project_status=all');
        break;
    }
  }
  return (
    <Paper className='project-tabs' style={{ margin: '-15px -15px 10px -15px' }}>
      <Tabs
        value={tabIndex}
        onChange={onChangeRoute}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label={<span>Inspection</span>} value="inspection" onClick={() => trackActivity('Project Detail', { event_type: 'Tab Clicked', event_desc: 'Clicked on Inspection Tab' })} />
        {
          onCheckPermission('technical', 'budget_expense', 'R') || onCheckPermission('technical', 'budget_allocation', 'R') || onCheckPermission('technical', 'budget_analytics', 'R') || getLocalStorageInfo().user.security_groups.filter(group => [29, 24, 23, 21, 10].includes(group.id)).length ?
            <Tab label={<span>Expense Management </span>} value="budget" onClick={() => trackActivity('Project Detail', { event_type: 'Tab Clicked', event_desc: 'Clicked on Expense Management Tab' })} />
            :
            <Tooltip title={'Sorry, you don\'t have access to Expense Management. Please contact support@sparta.aero for access.'} arrow>
              <div style={{ width: '350px', textAlign: "center" }}>
                <Tab disabled={true} label={
                    <span> Expense Management
                      <span>
                        <InfoIcon id='project-expense' />
                      </span>
                    </span>
                  }
                  value="budget" onClick={() => console.log('')}
                />
              </div>
            </Tooltip>
        }
        <Tab label={<span>Task Management</span>} value="task-management" onClick={() => trackActivity('Project Detail', { event_type: 'Tab Clicked', event_desc: 'Clicked on Task Management Tab' })} />
      </Tabs>

    </Paper>
  )
}
export default withRouter(ProjectDetailTab);
