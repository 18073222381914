import React, { Fragment, useState } from 'react';
import moment from 'moment';
import { TableRow, TableCell, Avatar, Tooltip, IconButton, Chip } from '@material-ui/core';
import { Link, browserHistory } from 'react-router';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import { CircularProgress } from '../../../shared_elements';
import { displayDateFormatShort } from '../../../constants';
import { onCheckPermission } from '../../../utils_v2'
const assetType = { 1: 'MSN', 2: 'ESN', 3: 'APU', 4: 'LG', 5: 'Propeller' }
export default function ProjectList({ item, editProject }) {
  const [open, setOpen] = useState(false);
  return (
    <TableRow onClick={() => browserHistory.push(`/technical/project/view/${item.slug}`)} hover tabIndex={-1}>
      <TableCell style={{ width: '440px' }}>
        <span className={`project-status-bar ${item.archive_status === 1 ? 'archived' : item.status.label.toLowerCase()}`}></span>
        <div className="flex-centered">
          <Tooltip title={`${item.progress}%`} arrow>
            <CircularProgress value={item.progress > 100 ? 100 : item.progress} />
          </Tooltip>
          <div style={{ marginLeft: '6px' }}>
            <span className="project-no">{`#${item.project_number}`}</span>
            <h4 className="project-name">{item.name}</h4>
          </div>
        </div>
        {(() => {
          const assetArray = item.assets.length > 3 ? open ? item.assets : item.assets.slice(0, 3) : item.assets;
          return (
            <Fragment>
              {
                assetArray.map((asset) => {
                  const workOrder = asset.work_order_number
                  const labelType = assetType[asset.asset_type];
                  const serialNo = asset.asset_type === 1 ? asset.msn : asset.asset_type === 2 ? asset.esn : asset.serial_number;
                  return (<Chip label={
                    <div>
                      {`${labelType}:${serialNo}`}
                      <br />
                      {workOrder ? 'WO No. ' + workOrder : ''}
                    </div>
                  } size="small" variant="outlined" style={{padding:'15px 0px'}} />)
                })
              }
              {item.assets.length > 3 ? <Chip color="primary" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setOpen(!open) }} clickable={true} label={!open ? `+${item.assets.length - 3} More` : 'Show Less'} size="small" /> : null}
            </Fragment>
          )
        })()}
      </TableCell>
      <TableCell><div style={{ width: '140px' }}>{item.location}</div></TableCell>
      <TableCell>{item.lessee && item.lessee.logo ? <img style={{ maxWidth: '50px' }} src={item.lessee.logo} alt="" /> : item?.lessee?.name ? item?.lessee?.name : '--'}</TableCell>
      <TableCell>{item.start_date ? moment(item.start_date).format(displayDateFormatShort) : '--'}</TableCell>
      <TableCell>{item.expected_end_date ? moment(item.expected_end_date).format(displayDateFormatShort) : '--'}</TableCell>
      <TableCell>
        {item.leads.length > 1 ?
          <AvatarGroup max={3}>
            {item.leads.map((lead, index) =>
              <Tooltip arrow title={lead.name}>
                <Avatar alt={lead.name} src={lead.profile_pic} />
              </Tooltip>
            )}
          </AvatarGroup>
          : item.leads.length === 1 ? item.leads.map((lead, index) => <Tooltip title={lead.name}><Avatar alt={lead.name} src={lead.profile_pic} /></Tooltip>) : null
        }
      </TableCell>
      <TableCell><span className={`project-status ${item.archive_status === 1 ? 'archived' : item.status.label.toLowerCase()}`}>{item.archive_status === 1 ? 'Archived' : item.status.label}</span></TableCell>
      <TableCell>
        <Fragment>
          {onCheckPermission('technical', 'projects', 'U') && item.archive_status !== 1 && (item.status && item.status.id && ![7, 8].includes(item.status.id)) ?
            <Tooltip title="Edit" arrow>
              <EditIcon onClick={(e) => { e.preventDefault(); e.stopPropagation(); editProject() }} color="primary" fontSize="small" />
            </Tooltip> : null
          }
          <Link to={`/technical/project/view/${item.slug}`}>
            <Tooltip title="View" arrow>
              <VisibilityIcon color="primary" fontSize="small" />
            </Tooltip>
          </Link>
        </Fragment>
      </TableCell>
    </TableRow>
  )
}
