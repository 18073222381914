import React, { useEffect, useState } from 'react';
import moment from "moment";
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import {Accordion,AccordionSummary,AccordionDetails,Grid,Paper,TextField} from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { fieldDateFormat, backendDateFormat, displayDateFormat } from "../../../constants";
import { checkApiStatus,onCheckFormPermission } from '../../../utils_v2';
import { globalGetService, globalPutService, globalPostService } from '../../../utils_v2/globalApiServices';
const OtherInformation = ({params, prsDetail,currentFormDetail}) => {
    const [isExpand, setExpand] = useState(true);
    const [prsInfo, setPrsInfo] = useState(prsDetail)
    const [prsCategories, setPrsCategories] = useState({items:null, list:[]});
    const [tags, setTags] = useState([]);
    const [assigneeList, setAssigneeList] = useState([]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    useEffect(() => {
        getPRSCategories();
        getAssigneeList();
        getTags();
    },[]);
    const getAssigneeList = () => {
        globalGetService(`technical/workorder/${params.workOrderSlug}/project-users/`)
        .then(response => {
            if (checkApiStatus(response)) {
                setAssigneeList(response.data.data)
            }
        })
    }
    const getPRSCategories = () => {
        globalGetService(`technical/workorder/${params.workOrderSlug}/gap-report-sections/`)
        .then(response => {
            if(checkApiStatus(response)){
                setPrsCategories(response.data.data);
            }
        });
    }
    const getTags = () => {
        globalGetService(`technical/prs-lessor-tags/`)
        .then(response => {
            if(checkApiStatus(response)) {
                setTags(response.data.data);
            }
        })
    }
    const onFieldChange = (e, keyParam, value) => {

    }
    const onUpdatePrsInfo = (key, value, required) => {
        if(key === 'tags'){
            globalPostService(`technical/workorder/${params.workOrderSlug}/gap-report/${params.id}/tags/`,{id:value.map(item=>item.id)})
            .then(response => {
                if(checkApiStatus(response)){
                    enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                }else{
                    enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                }
            })
        }else{
            let payload = {};
            if(key === 'gap_report_section'){
                payload = {[key]:value}
            }else{
                payload = {gap_report_section:prsDetail.gap_report_section.id, [key]:value}
            }
            globalPutService(`technical/workorder/${params.workOrderSlug}/gap-report/${params.id}/`, payload)
            .then(response => {
                if(checkApiStatus(response)){
                    enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                }else{
                    enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                }
            });
        }
    }
    return(
        <Paper>
            <Accordion expanded={isExpand} onChange={(e,expanded)=> setExpand(expanded)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                    Other Information
                </AccordionSummary>
                <AccordionDetails>
                    <Paper style={{padding:'15px'}}>
                        <Grid container spacing={1}>
                            <Grid item md={4} xs={6}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="started_date"
                                        label="Discrepancy Raised On"
                                        format={fieldDateFormat}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ readOnly: true }}
                                        value={ prsInfo?.started_date || null}
                                        onChange={(data, value) => {setPrsInfo({...prsInfo,'started_date':data ? moment(data).format(backendDateFormat):null});onUpdatePrsInfo("started_date",data ? moment(data).format(backendDateFormat):null,false)}}
                                        inputVariant='outlined'
                                        clearable
                                        disabled={ onCheckFormPermission(currentFormDetail) ? false : true}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item md={4} xs={6}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="estimated_completion_date"
                                        label="Discrepancy Resolved On"
                                        format={fieldDateFormat}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ readOnly: true }}
                                        value={ prsInfo?.estimated_completion_date || null}
                                        onChange={(data, value) => {setPrsInfo({...prsInfo,'estimated_completion_date':data ? moment(data).format(backendDateFormat):null});onUpdatePrsInfo("estimated_completion_date",data ? moment(data).format(backendDateFormat):null,false)}}
                                        inputVariant='outlined'
                                        clearable
                                        disabled={ onCheckFormPermission(currentFormDetail) ? false : true}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item md={4} xs={6}>
                                <TextField
                                    id="ata"
                                    label="ATA"
                                    fullWidth
                                    margin="normal"
                                    value={prsInfo?.ata || ""}
                                    onChange={(e) => setPrsInfo({...prsInfo,'ata':e.target.value})}
                                    onBlur={() => onUpdatePrsInfo('ata', prsInfo.ata, false)}
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                    disabled={ onCheckFormPermission(currentFormDetail) ? false : true}
                                />
                            </Grid>
                            <Grid item md={4} xs={6}>
                                <TextField
                                    id="part_number"
                                    label="Part Number"
                                    fullWidth
                                    margin="normal"
                                    value={ prsInfo?.part_number || ""}
                                    onChange={(e) => setPrsInfo({...prsInfo,'part_number':e.target.value})}
                                    onBlur={() => onUpdatePrsInfo('part_number', prsInfo.part_number, false)}
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                    disabled={ onCheckFormPermission(currentFormDetail) ? false : true}
                                />
                            </Grid>
                            <Grid item md={4} xs={6}>
                                <TextField
                                    id="serial_number"
                                    label="Serial Number"
                                    fullWidth
                                    margin="normal"
                                    value={prsInfo?.serial_number || ""}
                                    onChange={(e) => setPrsInfo({...prsInfo,'serial_number':e.target.value})}
                                    onBlur={() => onUpdatePrsInfo('serial_number', prsInfo.serial_number, false)}
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                    disabled={ onCheckFormPermission(currentFormDetail) ? false : true}
                                />
                            </Grid>
                            <Grid item md={4} xs={6}>
                                <Autocomplete
                                    disableClearable
                                    options={prsCategories.list}
                                    getOptionLabel={(option) => option.name}
                                    id="gap_report_section"
                                    value={prsInfo?.gap_report_section || null }
                                    onChange={(e, value) => { setPrsInfo({...prsInfo,gap_report_section:value}); onUpdatePrsInfo('gap_report_section', value?.id||null, true)}}
                                    renderInput={(params) => ( <TextField required {...params} label="Category" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' /> )}
                                    disabled={ onCheckFormPermission(currentFormDetail) ? false : true}
                               />
                            </Grid>
                            <Grid item md={4} xs={6}>
                                <TextField
                                    id="position"
                                    label="Position"
                                    fullWidth
                                    margin="normal"
                                    value={prsInfo?.position || ""}
                                    onChange={(e) => setPrsInfo({...prsInfo,'position':e.target.value})}
                                    onBlur={() => onUpdatePrsInfo('position', prsInfo.position, false)}
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                    disabled={ onCheckFormPermission(currentFormDetail) ? false : true}
                                />
                            </Grid>
                            <Grid item md={4} xs={6}>
                                <TextField
                                    id="cycles_remaining"
                                    label="Cycles Remaining"
                                    fullWidth
                                    margin="normal"
                                    value={prsInfo?.cycles_remaining || ""}
                                    onChange={(e) => setPrsInfo({...prsInfo,'cycles_remaining':e.target.value})}
                                    onBlur={() => onUpdatePrsInfo('cycles_remaining', prsInfo.cycles_remaining, false)}
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                    disabled={ onCheckFormPermission(currentFormDetail) ? false : true}
                                />
                            </Grid>
                            <Grid item md={4} xs={6}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="install_date"
                                        label="Install Date"
                                        format={fieldDateFormat}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ readOnly: true }}
                                        value={ prsInfo?.install_date || null}
                                        onChange={(data, value) => {setPrsInfo({...prsInfo,'install_date':data ? moment(data).format(backendDateFormat):null});onUpdatePrsInfo("install_date",data ? moment(data).format(backendDateFormat):null,false)}}
                                        inputVariant='outlined'
                                        clearable
                                        disabled={ onCheckFormPermission(currentFormDetail) ? false : true}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item md={4} xs={6}>
                                <Autocomplete
                                    options={[{ label: 'None', value: 0 }, { label: 'P1', value: 1 }, { label: 'P2', value: 2 }]}
                                    getOptionLabel={(option) => option.label}
                                    id="priority"
                                    value={prsInfo.priority}
                                    onChange={(e, value) => { setPrsInfo({...prsInfo,priority:value}); onUpdatePrsInfo('priority', value?.value||null, false)}}
                                    renderInput={(params) => ( <TextField {...params} label="Priority" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' /> )}
                                    disabled={ onCheckFormPermission(currentFormDetail) ? false : true}
                                />
                            </Grid>
                            <Grid item md={4} xs={6}>
                                <Autocomplete
                                    options={assigneeList}
                                    getOptionLabel={(option) => option.name}
                                    id="assignee"
                                    value={prsInfo.assignee}
                                    onChange={(e, value) => { setPrsInfo({...prsInfo,assignee:value}); onUpdatePrsInfo('assignee', value?.id||null, false)}}
                                    renderInput={(params) => ( <TextField {...params} label="Assignee" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' /> )}
                                    disabled={ onCheckFormPermission(currentFormDetail) ? false : true}
                                />
                            </Grid>
                            <Grid item md={4} xs={6}>
                                <Autocomplete
                                    multiple
                                    options={tags}
                                    filterSelectedOptions={true}
                                    getOptionLabel={(option) => option.name}
                                    id="tags"
                                    value={prsInfo?.tags||[]}
                                    onChange={(e, value) => {setPrsInfo({...prsInfo,tags:value});; onUpdatePrsInfo("tags", value); }}
                                    renderInput={(params) => ( <TextField {...params} label="Tags" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' /> )}
                                    disabled={ onCheckFormPermission(currentFormDetail) ? false : true}
                                />
                            </Grid>                        
                        </Grid>
                    </Paper>
                </AccordionDetails>
            </Accordion>
        </Paper>
    )
}
export default withRouter(OtherInformation);