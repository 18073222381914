import React, { useState } from "react";
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, CircularProgress } from '@material-ui/core';
import { CkEditorText } from '../../../shared_elements';
import { checkApiStatus } from '../../../utils_v2';
import { globalPostService } from '../../../utils_v2/globalApiServices';
const AddSection = ({params, toggleModalFn, getResponseBack, sectionObj}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [section, setSection] = useState(sectionObj.data);
    const [error, setError] = useState({});
    const onAddSection = () => {
        if(section?.title?.trim()?.length){
            setLoading(true)
            globalPostService(`technical/v2/workorder/${params.workOrderSlug}/inspection-sections/`, [section])
            .then(response => {
                if(checkApiStatus(response)){
                    if (response.data.message){
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }else{
                        enqueueSnackbar('Section created successfully', { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }
                    toggleModalFn();getResponseBack();
                }else{
                    enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                }
                setLoading(false)
            })
        }else{
            enqueueSnackbar('Please enter Name', { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
        }   
    }
    return(
        <Dialog
            open={sectionObj.modal}
            onClose={toggleModalFn}
            className='project-management-modal'
            maxWidth='md'
        >
            <DialogTitle id="scroll-dialog-title">
                Add {section.parent_section ? 'Sub-Section':'Section'}
            </DialogTitle>    
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item md={12}>
                        <TextField
                            id='name'
                            fullWidth
                            margin="normal"
                            label={section.parent_section ?'Sub-Section Name' :'Section Name'}
                            InputLabelProps={{shrink: true}}
                            value={section.title}
                            error={error.title}
                            helperText={error?.title||""}
                            onChange={(e) => setSection({...section, title:e.target.value})}
                            onFocus={() => setError({...error, title:''})}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={12}>
                        <div>
                            <CkEditorText 
                                htmlData={section?.value||''}
                                onChangeData={(data) => setSection({...section, value:data})}
                            />
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>   
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size="small" variant="outlined">Cancel</Button>
                <Button disabled={isLoading} onClick={onAddSection} color='primary' size="small" variant="contained">
                    {isLoading ? <CircularProgress size={24} />:'SAVE'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default withRouter(AddSection);