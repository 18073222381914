import moment from 'moment';
import { globalGetService, globalExportService, globalPutService, globalDeleteService, globalPostService, globalFileUploadService } from '../../utils_v2/globalApiServices';
import { checkApiStatus, downloadFileType, getLocalStorageInfo } from '../../utils_v2';
import { dateRangeValidation } from '../../utils_v2/formValidation';
import { regexConstants } from '../../constants/regEx';
import { backendDateFormat } from '../../constants';
import { errorCode } from './index';
export function getCurrentFormDetailApi(props={}){
    globalGetService(`technical/engine-workorder/${props.params.workOrderSlug}/forms/`,{form:props.params.formSlug})
    .then(response => {
      if(checkApiStatus(response)){
        this.setState({
          currentFormDetail:response.data.data
        })
      }
    })
}
export function changeFormStatusApi(props={}, data={}){
    this.setState({pageLoader:true});
    globalPostService(`technical/engine-workorder/${props.params.workOrderSlug}/form/${props.params.formSlug}/status-update/`,data)
    .then(response => {
        this.setState({pageLoader:false});
        if(checkApiStatus(response)){
        this.getCurrentFormDetailApi(this.props);
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }else{
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
    })
}
export function getTableInspectionApi(props={}, query={}){
  globalGetService(`technical/engine-workorder/${props.params.workOrderSlug}/table-inspections/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        tableInspection: response.data.data,
        skeletonLoader: false
      })
    }
  })
}
export function exportTableInspectionApi(props={}, file={}){
  this.setState({pageLoader: true});
  globalExportService(`technical/engine-workorder/${props.params.workOrderSlug}/table-inspections/`, {download: file.extension})
  .then(response => {
    downloadFileType(response.data, (`TE005_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`), file.extension);
    this.setState({pageLoader: false});
    this.props.enqueueSnackbar('Table Inspection downloaded successfully', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
  })
}
export function editTableInspectionApi(props={}, data={}){
  // let llpFlag = true;
  // let llpError = [];
  let validationInputs = {}
  if(data.general_details.inspection_performed_from && data.general_details.inspection_performed_till){
    let msg = dateRangeValidation(data.general_details.inspection_performed_from, data.general_details.inspection_performed_till, 0);
    validationInputs = {
      ...validationInputs,
      inspection_performed_from: msg ? 'Inspection From Date cannot be greater then To Date':'',
      inspection_performed_to: msg ? 'Inspection To Date cannot be less then To From':''
    }
  }
  // llpError = data.llp_details.map(item => {
  //   return {
  //     part_number: item.part_number && item.part_number.trim() !== '' ? regexConstants.alphanumericWithHyphenSpace.test(item.part_number) ? '':'Alphanumeric with space and hypens only':'',
  //     serial_number: item.serial_number && item.serial_number.trim() !== '' ? regexConstants.alphanumericWithHyphenSpace.test(item.serial_number) ? '':'Alphanumeric with space and hypens only':'',
  //   }
  // })
  // llpError.map(data => {
  //   if(!Object.keys(data).every((k) => { return data[k] === ''})){
  //     llpFlag = false
  //   }
  // });
  if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
    this.setState({pageLoader:true});
    globalPostService(`technical/engine-workorder/${props.params.workOrderSlug}/table-inspections/`, data)
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else {
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }else{
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        general_details:validationInputs
      }
    }));
  }
}
export function getDisassemblyApi(props={}, id, mode){
  this.setState({pageLoader:true});
  globalGetService(`technical/engine-workorder/${props.params.workOrderSlug}/disassembly/${id}/`)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState({
        disAssemblyCrud: {
          data: response.data.data,
          modal:true,
          mode: mode === 'V'?'view':'edit'
        }
      })
    }
  })
}
export function addEditDisassemblyApi(props={}, data={}){
  this.setState({pageLoader:true});
  globalPostService(`technical/engine-workorder/${props.params.workOrderSlug}/disassembly/`, {data:data})
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      if(data.id){
        this.setState(prevState => ({
          ...prevState,
          disAssemblyCrud:{data:{},modal:false,mode:''},
          tableInspection: {
            ...prevState.tableInspection,
            disassembly_findings: prevState.tableInspection.disassembly_findings.map(item => item.id === data.id ? response.data.data:item)
          }
        }))
      }else{
        this.setState(prevState => ({
          ...prevState,
          disAssemblyCrud:{data:{},modal:false,mode:''},
          tableInspection: {
            ...prevState.tableInspection,
            disassembly_findings: [...prevState.tableInspection.disassembly_findings, response.data.data]
          }
        }))
      }
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else {
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function addDisassemblyPlateApi(params={}, query={}){
  globalPostService(`technical/engine-workorder/${params.workOrderSlug}/disassembly-picture/${params.imageId}/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else {
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function editDisassemblyPlateApi(params={}, query={}){
  globalGetService(`technical/engine-workorder/${params.workOrderSlug}/form/${params.formSlug}/get-pictures-url/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else {
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function deleteDisassemblyFindingImgApi(props={}, deleteIds={}){
  if(deleteIds.id){
    globalDeleteService(`technical/engine-workorder/${props.params.workOrderSlug}/disassembly-picture/${deleteIds.id}/delete/`)
    .then(response => {
      if(checkApiStatus(response)){
        this.setState({
          deleteModal: false,
          deleteIds:{}
        })
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else {
        // this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }else{
    this.setState(prevState => ({
      ...prevState,
      disAssemblyCrud: {
        ...prevState.disAssemblyCrud,
        data: {
          ...prevState.disAssemblyCrud.data,
          finding_pictures:[...prevState.disAssemblyCrud.data.finding_pictures].filter((item, index) => index !== deleteIds.deleteIndex)
        }
      },
      deleteModal:false,
      deleteIds:{}
    }))
  }
}
export function deleteLLPPartApi(props={}, deleteIds={}){
  if(deleteIds.id){
    this.setState({pageLoader:true});
    globalDeleteService(`technical/engine-workorder/${props.params.workOrderSlug}/llp-details/${deleteIds.id}/`)
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.setState(prevState => ({
          ...prevState,
          tableInspection: {
            ...prevState.tableInspection,
            llp_details: [...prevState.tableInspection.llp_details].filter((item, index) => index !== deleteIds.deleteIndex)
          },
          deleteModal:false,
          deleteIds:{}
        }));
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else {
        // this.props.enqueueSnackbar('Deleted Successfully', {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }else{
    this.setState(prevState => ({
      ...prevState,
      tableInspection: {
        ...prevState.tableInspection,
        llp_details: [...prevState.tableInspection.llp_details].filter((item, index) => index !== deleteIds.deleteIndex)
      },
      deleteModal:false,
      deleteIds:{}
    }));
  }
}
export function deleteDisassemblyApi(props={}, deleteIds){
  this.setState({pageLoader:true});
  globalDeleteService(`technical/engine-workorder/${props.params.workOrderSlug}/disassembly/${deleteIds.id}/`)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        tableInspection: {
          ...prevState.tableInspection,
          disassembly_findings: prevState.tableInspection.disassembly_findings.filter((item, index) => item.id !== deleteIds.id)
        },
        deleteModal:false,
        deleteIds:{}
      }));
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else {
      // this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}

export function attachFindingApi(props={}, files){
  this.setState({pageLoader:true});
  let formData = new FormData();
  for(let i = 0; i < files.length; i++) {
     let file = files[i];
     formData.append('files[]', file);
  }
  globalFileUploadService(`technical/engine-workorder/${props.params.workOrderSlug}/file-upload/`, formData)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        disAssemblyCrud: {
          ...prevState.disAssemblyCrud,
          data: {
            ...prevState.disAssemblyCrud.data,
            finding_pictures:[...prevState.disAssemblyCrud.data.finding_pictures, ...response.data.data.map(item => {return { description:'',sectionType:'dis_finding',image:item.url}})]
          }
        }
      }))
    }
  })
}


export function deleteSVAttachmentApi(props={}, deleteIds){
  if(deleteIds.id){
    this.setState({pageLoader:true});
    globalDeleteService(`technical/shop-visit-attachment/${deleteIds.id}/delete/`)
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.setState(prevState => ({
          ...prevState,
          tableInspection: {
            ...prevState.tableInspection,
            shop_visit: {
              ...prevState.tableInspection.shop_visit,
              attachments: [...prevState.tableInspection.shop_visit.attachments].filter((item, index) => index !== deleteIds.deleteIndex)
            }
          },
          deleteModal:false,
          deleteIds: {}
        }))
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else {
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }else{
    this.setState(prevState => ({
      ...prevState,
      tableInspection: {
        ...prevState.tableInspection,
        shop_visit: {
          ...prevState.tableInspection.shop_visit,
          attachments: [...prevState.tableInspection.shop_visit.attachments].filter((item, index) => index !== deleteIds.deleteIndex)
        }
      },
      deleteModal:false,
      deleteIds: {}
    }))
  }
}
export function generateSVAttachmentUrlApi(props={}, file){
  let formData = new FormData();
  formData.append('file', file);
  formData.append('shop_visit', true);
  this.setState({pageLoader:true});
  globalFileUploadService(`technical/engine-workorder/${props.params.workOrderSlug}/file-upload/`, formData)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        tableInspection: {
          ...prevState.tableInspection,
          shop_visit : {
            ...prevState.tableInspection.shop_visit,
            attachments: [...prevState.tableInspection.shop_visit.attachments, {
              attachment:response.data.url,
              name:response.data.name,
              description:'',
              created_at:moment().format(backendDateFormat),
              user: {id:getLocalStorageInfo().user.id,name: getLocalStorageInfo().user.name, profile_pic:getLocalStorageInfo().user.profile_pic}
            }]
          }
        }
      }))
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else {
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
