import React, { Component, Fragment } from 'react';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Tooltip, Button, TextField } from '@material-ui/core';
import { withRouter } from 'react-router';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { TableListComp, CkEditorText, EmptyCollection, DeletePopUp, PageLoader } from '../../../shared_elements';
import { GeneralDetail, PrerequisiteContent } from '../components';
import { FormWrapper, ReadPrerequisite, Attachment } from '../../Elements';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { getCurrentFormDetailApi, changeFormStatusApi, getMrSectionDetailsApi, editMrSectionDetailsApi, deleteMrSectionDetailsApi, deleteMrAttachmentApi, generateMrAttachmentUrlApi, exportMrSectionDetailsApi } from '../apiServices';
import { fieldValidation } from '../../../utils_v2/formValidation';
import { errorCode, mrSection } from '../';
class MrClaimAndInvoiceReview extends Component{
    constructor(props){
        super(props);
        this.state = {
          skeletonLoader: true,
          pageLoader: false,
          currentFormDetail:{},
          mrSections: {
            details: {},
            sections: [],
            attachments: []
          },
          error: {
            details: {},
            sections: [],
          },
          deleteModal: false,
          deleteIds:[],
        }
        this.getCurrentFormDetailApi = getCurrentFormDetailApi.bind(this);
        this.changeFormStatusApi = changeFormStatusApi.bind(this);
        this.getMrSectionDetailsApi = getMrSectionDetailsApi.bind(this);
        this.editMrSectionDetailsApi = editMrSectionDetailsApi.bind(this);
        this.deleteMrSectionDetailsApi = deleteMrSectionDetailsApi.bind(this);
        this.deleteMrAttachmentApi = deleteMrAttachmentApi.bind(this);
        this.generateMrAttachmentUrlApi = generateMrAttachmentUrlApi.bind(this);
        this.exportMrSectionDetailsApi = exportMrSectionDetailsApi.bind(this);
    }
    componentDidMount(){
        this.getCurrentFormDetailApi(this.props);
        this.getMrSectionDetailsApi(this.props);
    }
    addComponent = () => {
        this.setState(prevState => ({
        ...prevState,
        mrSections: {
            ...prevState.mrSections,
            sections: [...prevState.mrSections.sections, mrSection]
        }
        }))
    }
    onFieldChange = (event, keyParam, data, arrayType, arrayIndex) => {
        if(arrayType === 'sections'){
          this.setState(prevState => ({
            ...prevState,
            mrSections: {
              ...prevState.mrSections,
              sections: [...prevState.mrSections.sections].map((section, sectionIndex) => sectionIndex === arrayIndex ?
              {
                ...section,
                [keyParam]:data
              }:section)
            }
          }))
        }else if (arrayType === 'attachments') {
          this.setState(prevState => ({
            ...prevState,
            mrSections: {
              ...prevState.mrSections,
              attachments: [...prevState.mrSections.attachments].map((attachment, index) => index === arrayIndex ?
              {
                ...attachment,
                [keyParam]:data
              }:attachment)
            }
          }))
        }else{
          this.setState(prevState => ({
            ...prevState,
            mrSections: {
              ...prevState.mrSections,
              details: {
                ...prevState.mrSections.details,
                [keyParam]: data
              }
            }
          }))
        }
    }
    handleError = (value, key) => {
        this.setState(prevState => ({
          ...prevState,
          error: {
            ...prevState.error,
            details: {
              ...prevState.error.details,
              [key]: errorCode[key][fieldValidation({...errorCode[key+'Obj'], fieldval: value})]
            }
          }
        }));
    }
    render(){
        const { skeletonLoader, currentFormDetail, mrSections, deleteModal, deleteIds, error, pageLoader } = this.state;
        return(
            <section className='project-forms-section'>
                { currentFormDetail && Object.keys(currentFormDetail).length ?
                    <FormWrapper formInfo={currentFormDetail} />:null
                }
                <div className='mr-claim-and-invoice-review'>
                    <div className="technical-engine-forms">
                        <h4 className="gen-detail-title">General Details <ReadPrerequisite content={<PrerequisiteContent />} /></h4>
                        <GeneralDetail
                            currentFormDetail={currentFormDetail}
                            generalDetail={mrSections.details}
                            onFieldChange={this.onFieldChange}
                            error={error.details}
                            handleError={this.handleError}
                            resetErrorKey={(key) => {
                                this.setState(prevState => ({
                                ...prevState,
                                error: {
                                    ...prevState.error,
                                    [key]: ''
                                }
                                }))
                            }}
                        />
                        <div className="engine-te006-sections">
                            { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
                                <div className="text-right" style={{margin: '0 0 20px 0'}}>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="primary"
                                    disableElevation={true}
                                    onClick={() => {this.addComponent('')}}
                                    startIcon={<ControlPointIcon fontSize="small" color="primary" />}
                                >
                                    Add Section
                                </Button>
                                </div>:null
                            }

                            { mrSections.sections.map((section, index) =>
                                <ExpansionPanel className="working-groups-section">
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id={`section-${index}`}
                                >
                                    <div className="flex-centered" style={{width:'100%'}}>
                                    { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
                                        <TextField
                                        fullWidth
                                        required
                                        margin="normal"
                                        value={section.title}
                                        error={error.sections.length && error.sections[index] ? error.sections[index].title !=='' ? true:false :false}
                                        onChange={(e) => {this.onFieldChange(e, 'title', e.target.value, 'sections', index)}}
                                        helperText={error.sections.length && error.sections[index] ? error.sections[index].title :''}
                                        onFocus={(e) => {e.preventDefault(); e.stopPropagation();}}
                                        InputLabelProps={{shrink: true}}
                                        />:
                                        section.title
                                    }
                                    { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
                                        <span className="remove-section" onClick={(e, mode) => {e.preventDefault(); e.stopPropagation(); this.setState({deleteModal: true,deleteIds:{...section, deleteType:'sections', deleteIndex:index}})}}>
                                        <Tooltip title="Remove Section" arrow>
                                            <DeleteOutlineIcon color="secondary" />
                                        </Tooltip>
                                        </span>:null
                                    }
                                    </div>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <CkEditorText
                                    disabled = { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save?false:true}
                                    htmlData={section.content}
                                    onChangeData={(data) => this.onFieldChange('', 'content', data, 'sections', index)}
                                    />

                                </ExpansionPanelDetails>
                                </ExpansionPanel>
                            )}
                            { mrSections.sections.length === 0 ? <div style={{background:'#fff'}}><EmptyCollection title="No records found" /></div>:null}
                        </div>
                        <div className="">
                            { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
                                <div className="text-right" style={{margin: '20px 0 20px 0'}}>
                                <div>
                                    <input
                                    accept=".xlsx,.xls,.doc,.docx,.png,.jpeg,.jpg,.pdf"
                                    id="contained-button-file"
                                    type="file"
                                    style={{display:'none'}}
                                    onChange={(e) => this.generateMrAttachmentUrlApi(this.props, e.target.files[0])}
                                    />
                                    <label htmlFor="contained-button-file">
                                    <Button size="small" variant="outlined" color="primary" component="span">
                                        Add Attachment
                                    </Button>
                                    </label>
                                </div>
                                </div>:null
                            }
                            <TableListComp
                                heads={[
                                { label: 'File Name', sortOption: false },
                                { label: 'Description', sortOption: false },
                                { label: 'Uploaded on', sortOption: false },
                                { label: 'Uploaded by', sortOption: false },
                                { label: 'Action', sortOption: false, actionCell: true }
                                ]}
                                data={mrSections.attachments.map((item,index) =>
                                <Attachment
                                    currentFormDetail={currentFormDetail}
                                    item={item}
                                    onFieldChange={(event, keyParam, data) => this.onFieldChange(event, keyParam, data, 'attachments', index)}
                                    previewDetail={(mode) => {this.setState({deleteModal: true,deleteIds:{...item, deleteType:'attachments', deleteIndex:index}})}}
                                />
                                )}
                                noRecord={ mrSections.attachments.length ? null:
                                <EmptyCollection title="No records found" />
                                }
                            />
                        </div>
                    </div>
                </div>
                <DeletePopUp
                    modal={deleteModal}
                    toggleModalFn={() => {this.setState({deleteModal:false, deleteIds:[]})}}
                    title="Delete Section"
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={() => { deleteIds.deleteType === 'attachments' ? this.deleteMrAttachmentApi(this.props, deleteIds) :this.deleteMrSectionDetailsApi(this.props, deleteIds)}}
                />
            </section>
        )
    }
}
export default MrClaimAndInvoiceReview;