import React, { Fragment, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { browserHistory } from 'react-router';
import moment from 'moment';
import { Button, Accordion, AccordionDetails, AccordionSummary, Paper, Grid, Table, TableBody, TableRow, TableCell, Tooltip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { FormT012, WorkOrderHd, AssignEngineer, FormList, ProjectDetailTab, ProjectViewsSkeleton, ProjectUserProfile, AircraftCRU, ALPCRU, ProjectUsers, WorkOrderDropdown, ProjectUserPopUp, AssignOprEngineer } from '../components';
import { DeletePopUp, PageLoader } from '../../../shared_elements';
import { globalGetService, globalPostService, globalDeleteService } from '../../../utils_v2/globalApiServices'
import { checkApiStatus, getLocalStorageInfo, onCheckPermission } from '../../../utils_v2'
import { displayDateFormatShort } from '../../../constants'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import playStoreIcon from '../../assets/images/playstore_icon.png';
import appStoreIcon from '../../assets/images/app_store_icon.png'
import  { assetTypeInfo } from '../';
import { trackActivity } from '../../../utils/mixpanel';
const ProjectDetail = ({params}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [assetType, setAssetType] = useState(null);
    const [assetId, setAssetId] = useState('');
    const [assetModal, setAssetModal] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [project, setProject] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(true);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteIds, setDeleteIds] = useState(null);
    const [removeOprEng, setRemoveOprEng] = useState({modal:false});
    const [engineerAssigned, setAssignedEngineer] = useState({modal:false, selectedEngineer:null, assign_to_all:false, workOrderSlug:'', formSlug:''})
    const [engineerAssignedLoader, setAssignedEngineerLoader] = useState(false)
    const [oprEngineer, setOprEngineer] = useState({modal:false, item:null});
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    useEffect(() => {
        getProjectDetails('skeletonLoader');
        trackActivity('Project Management', {
            event_type: 'Page Visited',
            page_title: 'Project Detail',
          });
    },[]);

    const getProjectDetails = (loaderType) => {
        if(loaderType === 'skeletonLoader'){setSkeletonLoader(true)}else{setLoading(true);}
        globalGetService(`technical/project/${params.project_slug}/`)
        .then(res => {
            if(checkApiStatus(res)){
                setProject(res.data.data);
            }
            if(loaderType === 'skeletonLoader'){setSkeletonLoader(false)}else{setLoading(false);}
        })
    }
    const assignUserToForm = () => {
        setAssignedEngineerLoader(true);
        globalPostService(`technical/${engineerAssigned.assetType === 2 ? 'engine-workorder':'workorder'}/${engineerAssigned.workOrderSlug}/form/${engineerAssigned.formSlug}/assign-user/`, {assign_to_all:engineerAssigned.assign_to_all,user_id:engineerAssigned.selectedEngineer.id})
        .then(response => {
            setAssignedEngineerLoader(false);
            if(checkApiStatus(response)){
                setAssignedEngineer({modal:false, assign_to_all:false, selectedEngineer:null, workOrderSlug:'', formSlug:''})
                enqueueSnackbar(response.data.message, {variant:'success', anchorOrigin:{horizontal:'right', vertical:'top'}});
                getProjectDetails();
                trackActivity('Project Management', {
                    event_type: 'Assign User Success',
                    page_title: 'Project Detail',
                    form_slug: engineerAssigned.formSlug ? engineerAssigned.formSlug : '',
                    workorder_slug: engineerAssigned.workOrderSlug ? engineerAssigned.workOrderSlug : '',
                    asset_type: engineerAssigned.assetType ? engineerAssigned.assetType : '',
                    user_id:engineerAssigned.selectedEngineer.id,
                    response_msg: response.data.message
                  });
            }else{
                enqueueSnackbar(response.data.message, {variant:'error', anchorOrigin:{horizontal:'right', vertical:'top'}});
                trackActivity('Project Management', {
                    event_type: 'Assign User Failed',
                    page_title: 'Project Detail',
                    form_slug: engineerAssigned.formSlug ? engineerAssigned.formSlug : '',
                    workorder_slug: engineerAssigned.workOrderSlug ? engineerAssigned.workOrderSlug : '',
                    asset_type: engineerAssigned.assetType ? engineerAssigned.assetType : '',
                    response_msg: response.data.message
                  });
            }
        });
    }
    const onRemoveProjectUser = () => {
        globalDeleteService(`technical/project/${params.project_slug}/remove-user/`, {user_id:deleteIds.id,project_role:deleteIds.project_role,project_form:0})
        .then(response => {
            if(checkApiStatus(response)){
                setDeleteModal(false);
                setDeleteIds(null);
                enqueueSnackbar(response.data.message, {variant:'success', anchorOrigin:{horizontal:'right', vertical:'top'}});
                getProjectDetails();
                trackActivity('Project Management', {
                    event_type: 'Remove Project User Success',
                    page_title: 'Project Detail',
                    form_slug: engineerAssigned.formSlug ? engineerAssigned.formSlug : '',
                    workorder_slug: engineerAssigned.workOrderSlug ? engineerAssigned.workOrderSlug : '',
                    asset_type: engineerAssigned.assetType ? engineerAssigned.assetType : '',
                    user_id: deleteIds.id,
                    project_role: deleteIds.project_role ? deleteIds.project_role : '',
                    project_slug: params.project_slug ? params.project_slug : '',
                    success_msg: response.data.message
                  });
            }else{
                enqueueSnackbar(response.data.message, {variant:'error', anchorOrigin:{horizontal:'right', vertical:'top'}});
                trackActivity('Project Management', {
                    event_type: 'Remove Project User Failed',
                    page_title: 'Project Detail',
                    form_slug: engineerAssigned.formSlug ? engineerAssigned.formSlug : '',
                    workorder_slug: engineerAssigned.workOrderSlug ? engineerAssigned.workOrderSlug : '',
                    asset_type: engineerAssigned.assetType ? engineerAssigned.assetType : '',
                    project_role: deleteIds.project_role ? deleteIds.project_role : '',
                    project_slug: params.project_slug ? params.project_slug : '',
                    error_msg: response.data.message
                  });
            }
        })
    }
    
    const onLaunchWorkOrder =(slug, type) => {
        globalGetService(`technical/${type === 2 ? 'engine-workorder':'workorder'}/${slug}/launch/`)
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, {variant:'success', anchorOrigin:{horizontal:'right', vertical:'top'}});
                getProjectDetails();
                trackActivity('Project Management', {
                    event_type: 'Launch Workorder Success',
                    page_title: 'Project Detail',
                    form_slug: engineerAssigned.formSlug ? engineerAssigned.formSlug : '',
                    workorder_slug: slug ? slug : '',
                    workorder_type: type ? type : '',
                    asset_type: engineerAssigned.assetType ? engineerAssigned.assetType : '',
                    success_msg: response.data.message
                  });
            }else{
                enqueueSnackbar(response.data.message, {variant:'error', anchorOrigin:{horizontal:'right', vertical:'top'}});
                trackActivity('Project Management', {
                    event_type: 'Launch Workorder Failed',
                    page_title: 'Project Detail',
                    form_slug: engineerAssigned.formSlug ? engineerAssigned.formSlug : '',
                    workorder_slug: slug ? slug : '',
                    workorder_type: type ? type : '',
                    asset_type: engineerAssigned.assetType ? engineerAssigned.assetType : '',
                    error_msg: response.data.message
                  });
            }
        })
    }
    const onOprEngineer = (user, type) => {
        setLoading(true);
        globalPostService(`technical/project/${params.project_slug}/opr/${type}/`, {user_id:user.id})
        .then(response => {
            if(checkApiStatus(response)){
                setOprEngineer({modal:false, item:null});
                getProjectDetails('pageLoader');
                setRemoveOprEng({modal:false});
            }else{

            }
            setLoading(false);
        })
    }
    return(
        <section className='projects-management-apps projects-management-section'>
            <div className='project-view-sec'>
                <ProjectDetailTab tabIndex={'inspection'} handleTabChange={() => console.log('')} />
                { skeletonLoader ? <ProjectViewsSkeleton />: 
                    <Fragment>
                        <div className='project-view-header'>
                            <Grid container alignItems='center' spacing={1}>
                                <Grid item xs={12} md={8}>
                                    <ul className='list-inline flex-centered'>
                                        <li className='list-inline-item'>
                                            <ArrowBackIcon onClick={() => browserHistory.push(`/technical/projects`)} color="primary" fontSize='small' />
                                        </li>
                                        <li className='list-inline-item'>
                                            <h4>{project.name}</h4>
                                            <span className='project-period'>{`${moment(project.start_date).format(displayDateFormatShort)} to ${moment(project.expected_end_date).format(displayDateFormatShort)}`}</span>
                                            { project.status && project.status.label ?
                                                <span className={`project-status ${ project.archive_status === 1 ?'archived' :project.status.label.toLowerCase()}`}>{project.archive_status === 1 ?'Archived' :project.status.label}</span>:null
                                            }
                                        </li>
                                    </ul>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <ul className='list-inline project-cta'>
                                        { onCheckPermission('technical', 'work_orders','C') && project.archive_status !==1 && (project.status && project.status.id && ![7,8].includes(project.status.id)) ?
                                            <li className='list-inline-item'>
                                                <WorkOrderDropdown 
                                                    toggleModalFn={(assetType) => {setAssetModal(true); setAssetType(assetType);setAssetId('')}}
                                                />
                                            </li>:null
                                        }
                                        <li className='list-inline-item'>
                                            <a href="https://apps.apple.com/us/app/sparta-acumen-aviation/id1270942972" target='_blank' rel='noopener noreferrer' onClick={()=> trackActivity('Project Management', { event_type:'Clicked on App Store Icon', page_title:'Project Detail'})}>
                                                <Tooltip title="Access SPARTA Projects App on iOS" arrow><img src={appStoreIcon} alt="App Store" /></Tooltip>
                                            </a>
                                        </li>
                                        <li className='list-inline-item'>
                                            <a href="https://play.google.com/store/apps/details?id=acumenaviation.sparta.app&pli=1" target='_blank' rel='noopener noreferrer' onClick={()=> trackActivity('Project Management', { event_type:'Clicked on Play Store Icon', page_title:'Project Detail'})}>
                                                <Tooltip title="Access SPARTA Projects App on Android" arrow><img src={playStoreIcon} alt="Play Store" /></Tooltip>
                                            </a>
                                        </li>
                                    </ul>
                                </Grid>
                            </Grid>
                        </div>    
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={8}>
                                { project.project_form && project.project_form === 1 ? 
                                    <FormT012 project={project} />:null
                                }
                                { project.work_orders && project.work_orders.length ? project.work_orders.map((workOrder,index) =>
                                    <Accordion key={workOrder.id} expanded={expanded === `workOrder-${workOrder.id}`} onChange={handleChange(`workOrder-${workOrder.id}`)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id={`workOrder-${workOrder.id}`}
                                        >
                                            <WorkOrderHd 
                                                project={project}
                                                workOrder={workOrder} 
                                                onEditWorkOrder={() => { setAssetId(workOrder.slug); setAssetModal(true); setAssetType(workOrder.asset.type);}}
                                            />
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className="workOrder-forms">
                                                { workOrder.forms && workOrder.forms.length && workOrder.status.id === 1 && onCheckPermission('technical', 'work_orders','C') && project.archive_status !==1 && (project.status && project.status.id && ![7,8].includes(project.status.id)) ?
                                                    <div style={{margin:'10px 10px 10px 0', float: 'right'}}>
                                                        <Button onClick={() => onLaunchWorkOrder(workOrder.slug, workOrder.asset.type)} variant="contained" color="primary" size="small">Launch</Button>
                                                    </div>
                                                    :null
                                                }
                                                <div>
                                                    <Table>
                                                        { workOrder.forms.length ?
                                                            <TableBody>
                                                                { workOrder.forms.map((form, formIndex) =>
                                                                    <FormList
                                                                        project={project}
                                                                        workOrderSlug={workOrder.slug}
                                                                        asset={workOrder.asset}
                                                                        key={formIndex}
                                                                        form={form}
                                                                        onAssignEngineer={() => setAssignedEngineer({assetType:workOrder.asset.type,modal:true, selectedEngineer:null, assign_to_all:false, workOrderSlug:workOrder.slug, formSlug:form.slug})}
                                                                        onRedirection = {(e) => {workOrder.status && workOrder.status.id === 1  ? enqueueSnackbar('First Launch the WorkOrder', {variant:'error', anchorOrigin:{horizontal:'right', vertical:'top'}}) : form.engineer && Object.keys(form.engineer).length ? browserHistory.push(`/${assetTypeInfo[workOrder.asset.type].key}/${workOrder.asset.slug}/technical/${workOrder.slug}/forms/${form.slug}/${form.name}`): enqueueSnackbar('Assign Project Engineer', {variant:'error', anchorOrigin:{horizontal:'right', vertical:'top'}}) }}
                                                                    />
                                                                )}
                                                            </TableBody>:
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell colSpan={3} style={{textAlign:'center'}}>No Form(s) Added</TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        }
                                                    </Table>
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                ):<p style={{textAlign:'center'}}>No WorkOrder Added Yet</p>}
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div className='cor-feedback-form'>
                                    {/* { project.cor_required ? project.current_user_role == 'operator'? null: project.current_user_role == 'client'?project.client_cor_show ?
                                        <Paper onClick={() => browserHistory.push(`/technical/project/view/${params.project_slug}/project-cor-form`)} className='flex-centered form-label'>
                                            <h4>COR (Confidential Operator Report)</h4>
                                            <ArrowForwardIosIcon style={{marginLeft:'auto'}} color="primary" fontSize='small' />
                                        </Paper>:null
                                    :
                                        <Paper onClick={() => browserHistory.push(`/technical/project/view/${params.project_slug}/project-cor-form`)} className='flex-centered form-label'>
                                            <h4>COR (Confidential Operator Report)</h4>
                                            <ArrowForwardIosIcon style={{marginLeft:'auto'}} color="primary" fontSize='small' />
                                        </Paper> :null
                                    }                                    
                                    { project.user_feedback && project.engineers.length ?
                                        <Paper onClick={() => browserHistory.push(`/technical/project/view/${params.project_slug}/project-feedback-form`)} className='flex-centered form-label'>
                                            <h4>Feedback</h4>
                                            <ArrowForwardIosIcon style={{marginLeft:'auto'}} color="primary" fontSize='small' />
                                        </Paper>:null
                                    } */}
                                    {(() => {
                                        let oprFlg = project && Object.keys(project).length && project.opr_required;
                                        let leads = project.leads.map((lead) => lead.id);
                                        let oprAccess = project.leads.map((lead) => lead.id);
                                        if(project.opr_user){
                                            oprAccess = [...oprAccess, project.opr_user.id]
                                        }
                                        if(oprFlg && oprAccess.includes(getLocalStorageInfo().user.id)){
                                            return(
                                                <Paper className='flex-centered form-label'>
                                                    <h4>OPR<Tooltip title='Operator Performance Rating' arrow><InfoIcon color='primary' style={{fontSize:'15px'}} /></Tooltip>
                                                        {project?.opr_user?.name ? <span className='assigned-engineer'>Assigned Engineer: {project.opr_user.name}</span>:null}
                                                    </h4>
                                                    <ul className='list-inline' style={{marginLeft:'auto'}}>
                                                        { project?.opr_user?.id ?
                                                            <>
                                                                { leads.includes(getLocalStorageInfo().user.id) ?
                                                                    <>
                                                                        <li className='list-inline-item'>
                                                                            <Tooltip title='Change Engineer' arrow>
                                                                                <EditIcon onClick={(e) => {e.preventDefault(); e.stopPropagation();setOprEngineer({modal:true, item:null})}} color="primary" fontSize='small' style={{cursor:'pointer'}}/>
                                                                            </Tooltip>
                                                                        </li>
                                                                        <li className='list-inline-item' onClick={() => setRemoveOprEng({modal:true})}>
                                                                            <Tooltip title='Change Engineer' arrow>
                                                                                <Tooltip title='Remove Engineer' arrow><DeleteOutlineIcon color="secondary" fontSize='small'style={{cursor:'pointer'}} /></Tooltip>
                                                                            </Tooltip>
                                                                        </li>
                                                                    </>:null
                                                                }
                                                                <li onClick={() => browserHistory.push(`/technical/project/view/${params.project_slug}/project-opr-form`)} className='list-inline-item'>
                                                                    <ArrowForwardIosIcon color="primary" fontSize='small' style={{cursor:'pointer'}}/>
                                                                </li>
                                                            </>:
                                                            <li className='list-inline-item'>
                                                                <Button onClick={(e) => {e.preventDefault(); e.stopPropagation();setOprEngineer({modal:true, item:null})}} size='small' variant='outlined' color='primary'>Assign Engineer</Button>
                                                            </li>
                                                        }
                                                    </ul>
                                                </Paper>
                                            )
                                        }
                                    })()}
                                </div>
                                <ProjectUsers
                                    project={project}
                                    label={'Project Leads'}
                                    userType='lead'
                                    users={project.leads ? project.leads:[]}
                                    memberIds={project.leads? project.leads.map(item => item.id):[]}
                                    onRemoveUser={(user) => {setDeleteModal(true); setDeleteIds(user)}}
                                    getResponseBack={() => getProjectDetails()}
                                />
                                <ProjectUsers
                                    project={project}
                                    label={'Project Engineers'}
                                    userType='engineer'
                                    memberIds={project.engineers? project.engineers.map(item => item.id):[]}
                                    users={project.engineers ? project.engineers:[]}
                                    onRemoveUser={(user) => {setDeleteModal(true); setDeleteIds(user)}}
                                    getResponseBack={() => getProjectDetails()}
                                />
                                <ProjectUsers
                                    project={project}
                                    label={'Current Lessee Engineer'}
                                    userType='member'
                                    operator_level={1}
                                    memberIds={project.members ? project.members.map(item => item.id):[]}
                                    users={project.members ? project.members.filter(member => member.project_role === 3 && member.operator_level === 1):[]}
                                    onRemoveUser={(user) => {setDeleteModal(true); setDeleteIds(user)}}
                                    getResponseBack={() => getProjectDetails()}
                                />
                                <ProjectUsers
                                    project={project}
                                    label={'Next Lessee Engineer'}
                                    userType='member'                                    
                                    memberIds={project.members ? project.members.map(item => item.id):[]}
                                    operator_level={2}
                                    users={project.members ? project.members.filter(member => member.project_role === 3 && member.operator_level === 2):[]}
                                    onRemoveUser={(user) => {setDeleteModal(true); setDeleteIds(user)}}
                                    getResponseBack={() => getProjectDetails()}
                                />
                                <ProjectUsers
                                    project={project}
                                    label={'Clients/Third Party'}
                                    userType='member'
                                    operator_level={null}
                                    memberIds={project.members ? project.members.map(item => item.id):[]}
                                    users={project.members ? project.members.filter(member => member.project_role === 3 && member.operator_level === null):[]}
                                    onRemoveUser={(user) => {setDeleteModal(true); setDeleteIds(user)}}
                                    getResponseBack={() => getProjectDetails()}
                                />    
                                <ProjectUsers
                                project={project}
                                label={'Expense Users' }
                                userType='expense_users'
                                memberIds={project.expense_users ? project.expense_users.map(item => item.id):[]}
                                users={project.expense_users ? project.expense_users:[]}
                                onRemoveUser={(user) => {setDeleteModal(true); setDeleteIds(user)}}
                                getResponseBack={() => getProjectDetails()}
                                />                            
                            </Grid>
                        </Grid>
                    </Fragment>
                }
            </div>
            {assetType && assetType !== 1 && assetModal ?
                <ALPCRU 
                    modal={assetModal}
                    toggleModalFn={() => {setAssetId('');setAssetModal(false); setAssetType(null);}}
                    assetType={assetType}
                    project={project}
                    id={assetId}
                    getResponseBack={() => {setAssetType('');setAssetId('');setAssetModal(false);getProjectDetails()}}
                />:null
            }
            {assetType && assetType === 1 && assetModal ?
                <AircraftCRU 
                    modal={assetModal}
                    toggleModalFn={() => {setAssetId(''); setAssetModal(false); setAssetType(null);}}
                    assetType={assetType}
                    project={project}
                    id={assetId}
                    getResponseBack={() => {setAssetType('');setAssetId('');setAssetModal(false);getProjectDetails()}}
                />:null
            }
            {deleteModal ?
                <DeletePopUp
                    modal={deleteModal}
                    toggleModalFn={() => setDeleteModal(false)}
                    title="Remove User"
                    content={<h4>Are you sure you want to remove?</h4>}
                    deleteRecordFn={() => onRemoveProjectUser()}
                    confirmText="Remove"
                />:null
            }
            { engineerAssigned.modal ? 
                <AssignEngineer 
                    users={project.engineers ? project.engineers:[]}
                    engineerAssigned={engineerAssigned}
                    toggleModalFn={() => setAssignedEngineer({assetType:'',modal:false, selectedEngineer:null, assign_to_all:false, workOrderSlug:'', formSlug:''})}
                    selectProjectEngineerFn={(flag, user) => setAssignedEngineer({...engineerAssigned, selectedEngineer:flag?user:null})}
                    onSelectAllForms ={(flag) => setAssignedEngineer({...engineerAssigned, assign_to_all:flag})}
                    assignUserToForm={assignUserToForm}
                    engineerAssignedLoader={engineerAssignedLoader}
                />:null
            }
            { oprEngineer.modal ? 
                <AssignOprEngineer 
                    modal={oprEngineer.modal}
                    oprEngineer={oprEngineer}
                    toggleModalFn={()=>setOprEngineer({modal:false, item:null})}
                    getResponseBack={() => console.log('Hi')}
                    engineers={project?.engineers || []}
                    onOprEngineer={onOprEngineer}
                    project={project}
                />:null
            }
            {removeOprEng.modal ?
                <DeletePopUp
                    modal={removeOprEng.modal}
                    toggleModalFn={() => setRemoveOprEng({modal:false})}
                    title="Remove Engineer"
                    content={<h4>Are you sure you want to remove?</h4>}
                    deleteRecordFn={() => onOprEngineer(project.opr_user, 'remove')}
                    confirmText="Remove"
                />:null
            }
            { isLoading ? <PageLoader /> : null}
        </section>
    )
}
export default ProjectDetail;