import React, { useState, useEffect, Fragment } from 'react';
import { withRouter, browserHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Checkbox, Radio, RadioGroup, FormControl, FormLabel, FormControlLabel, Tooltip, IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { DropzoneArea } from 'material-ui-dropzone';
import { TableListComp, CkEditorText, LesseeCreate, PageLoader, DeletePopUp } from '../../../shared_elements';
import ProjectContact from './ProjectContact';
import { GoogleLocationField } from '../../Elements'
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { getProjectInitiateApi, getProjectUsersApi, getPlatformConstApi, getExpenseUsersApi, } from '../apiServices';
import { globalGetService, globalPostService, globalPutService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus, getLocalStorageInfo } from '../../../utils_v2';
import { fieldValidation } from '../../../utils_v2/formValidation';
import { errorCode, createProject, projectContactHd } from '../';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import config from '../../../config';
const ProjectCRU = ({ params, lessee, modal, toggleModalFn, projectCrud, getResponseBack }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [projectStatus, setProjectStatus] = useState({ statusType: null, closure_date: null, archiveReason: '' })
  const [moreInfo, setMoreInfo] = useState(false);
  const [error, setError] = useState({});
  const [leads, setLeads] = useState([]);
  const [pltConstants, setConstants] = useState([]);
  const [engineers, setEngineers] = useState([])
  const [expenseUsers, setExpenseUsers] = useState([])
  const [project, setProject] = useState(projectCrud);

  useEffect(() => {
    if (!project.id) {
      getProjectInitiateApi()
        .then(response => {
          setProject({
            ...project,
            instructions: response.data.data.project_general_instruction,
            miscellaneous_comments: response.data.data.miscellaneous_comments,
            equipment_requirements: response.data.data.equipment_requirements
          })
        });
    }

    getProjectUsersApi(params, { role: 'expense_users' })
      .then(response => {
        if (checkApiStatus(response)) {
          setExpenseUsers(response.data.data)
        }
      });
    getProjectUsersApi(params, { role: 'lead' })
      .then(response => {
        if (checkApiStatus(response)) {
          setLeads(response.data.data)
        }
      });
    getProjectUsersApi(params, { role: 'engineer' })
      .then(response => {
        if (checkApiStatus(response)) {
          setEngineers(response.data.data)
        }
      });
    getPlatformConstApi({ constant_types: ['project_departments', 'project_name'], lessor_id: getLocalStorageInfo().defaultLessor.id })
      .then(response => {
        if (checkApiStatus(response)) {
          setConstants(response.data.data)
        }
      })
  }, []);
  const resetErrorKey = (key) => {
    setError({
      ...error,
      [key]: false
    })
  }
  const onFieldChange = (e, keyParam, value, contactType, index) => {
    if (contactType) {
      setProject({
        ...project,
        [contactType]: project[contactType].map((contact, contactIndex) => contactIndex === index ? { ...contact, [keyParam]: value } : contact)
      })
    } else {
      if (keyParam == 'lessee') {
        setProject({
          ...project,
          [keyParam]: value,
          lessee_id: value?.id ? value?.id : null
        })
      }
      else {
        setProject({
          ...project,
          [keyParam]: value,
        })
      }
    }
  }
  const addRemoveContact = (contactType, opsType, index) => {
    if (opsType === 'add') {
      setProject({
        ...project,
        [contactType]: [...project[contactType], { name: '', email: '', designation: '', phone_number: '' }]
      })
    } else {
      setProject({
        ...project,
        [contactType]: project[contactType].filter((contact, contactIndex) => contactIndex !== index)
      })
    }
  }
  const addEditProjectFn = () => {
    let budgetManager = [242,13].includes(getLocalStorageInfo().defaultLessor.id) && getLocalStorageInfo()?.user?.security_groups?.length == 1 && getLocalStorageInfo()?.user?.security_groups && getLocalStorageInfo()?.user?.security_groups[0]?.name == "Expense Manager"
    let validationInputs = {
      name: errorCode['name'][fieldValidation({ ...errorCode['nameObj'], fieldval: project.name })],
      start_date: errorCode['start_date'][fieldValidation({ ...errorCode['start_dateObj'], fieldval: project.start_date })],
      expected_end_date: errorCode['expected_end_date'][fieldValidation({ ...errorCode['expected_end_dateObj'], fieldval: project.expected_end_date })],
      leads: project.leads.length ? '' : budgetManager ? '' : 'Please select at least one lead',
      engineers: project.engineers.length ? '' : budgetManager ? '' : 'Please select at least one engineer',
      location: errorCode['location'][fieldValidation({ ...errorCode['locationObj'], fieldval: project.location })],
    }
    if (project.start_date && project.expected_end_date) {
      let start_date = moment(project.start_date, backendDateFormat);
      let expected_end_date = moment(project.expected_end_date, backendDateFormat);
      if (expected_end_date.diff(start_date, 'days') < 0) {
        validationInputs.expected_end_date = 'Expected end date should be greater than start date'
      }
    }
    if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
      setLoading(true);
      if (project.id) {
        globalPutService(`technical/projects/${project.slug}/`, project)
          .then(response => {
            setLoading(false);
            if (checkApiStatus(response)) {
              enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
              getResponseBack(response.data.data, 'edit');
            } else {
              enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
            }
          })
      } else {
        let formData = new FormData();
        let payload = Object.assign({}, project)
        payload = {
          ...payload,
          leads: payload?.leads.length ? payload?.leads : null,
          engineers: payload?.engineers.length ? payload?.engineers : null
        }
        Object.keys(payload).map(item => {
          if (typeof payload[item] == 'object' && item != 'files') {
            formData.append(item, JSON.stringify(payload[item]))
          } else if (item === 'files') {
            let index = 1
            for (let file of payload.files) {
              formData.append('file_' + index, file)
              index++
            }
          } else {
            formData.append(item, payload[item])
          }
        })
        globalPostService(`technical/projects/`, formData)
          .then(response => {
            setLoading(false);
            if (checkApiStatus(response)) {
              enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
              getResponseBack(response.data.data, 'add');
              browserHistory.push(`/technical/project/view/${response.data.data.slug}`)
            } else {
              enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
            }
          })
      }
    } else {
      enqueueSnackbar('Please enter the mandatory fields, highlighted in red', { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
      setError(validationInputs)
    }
  }
  const onChangeProjectStatus = () => {
    if (projectStatus.statusType === 3) {
      // check for closure date is valid
      if (projectStatus.closure_date) {
        let closure_date = moment(projectStatus.closure_date, backendDateFormat);
        let start_date = moment(project.start_date, backendDateFormat);
        if (closure_date.diff(start_date, 'days') < 0) {
          enqueueSnackbar('Closure date should be greater than Project start date', { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
          return
        }
      }
      setLoading(true);
      globalGetService(`technical/project/${project.slug}/close-project/`, { cancel_date: projectStatus.closure_date })
        .then(response => {
          setLoading(false);
          if (checkApiStatus(response)) {
            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
            setProjectStatus({ statusType: null, closure_date: null, archiveReason: '' });
            getResponseBack('', 'status');
          } else {
            enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
          }
        })
    } else {
      if (projectStatus.archiveReason) {
        setLoading(true);
        globalPutService(`technical/projects/${project.slug}/`, {
          ...project,
          status: projectStatus.statusType === 1 ? 8 : project.status,
          archive_reason: projectStatus.archiveReason,
          archive_status: projectStatus.statusType === 2 ? 1 : 0
        })
          .then(response => {
            setLoading(false);
            if (checkApiStatus(response)) {
              enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
              setProjectStatus({ statusType: null, closure_date: null, archiveReason: '' })
              getResponseBack('', 'status');
            } else {
              enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
            }
          })
      } else {
        enqueueSnackbar('Please enter the Reason', { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
      }
    }
  }
  let budgetManager = [242,13].includes(getLocalStorageInfo().defaultLessor.id) && getLocalStorageInfo()?.user?.security_groups && getLocalStorageInfo()?.user?.security_groups?.length == 1 && getLocalStorageInfo()?.user?.security_groups[0]?.name == "Expense Manager"
  const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532 );
  return (
    <Fragment>
      <Dialog
        open={modal}
        onClose={() => toggleModalFn()}
        maxWidth={'md'}
        className='project-management-modal'
      >
        <DialogTitle id="scroll-dialog-title">{project.id ? 'Edit' : 'Add'} Project</DialogTitle>
        <DialogContent dividers={true}>
          <form className="add-edit-project-modal">
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                {project.id ?
                  <TextField
                    disabled
                    id="name"
                    label="Project Name"
                    value={project.name}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  /> :
                  <Autocomplete
                    options={pltConstants.length ? pltConstants.filter((item) => item.type === 'project_name') : []}
                    getOptionLabel={option => option.label}
                    id="name"
                    value={pltConstants.length && project.name ? pltConstants.filter((item) => item.type === 'project_name').find(pj => pj.label === project.name) : null}
                    onChange={(e, value) => onFieldChange(e, 'name', value ? value.label : value)}
                    renderInput={params => <TextField required onFocus={() => resetErrorKey('name')} error={error.name ? true : false} helperText={error.name ? error.name : ''} {...params} label="Project Name" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
                  />
                }
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  options={pltConstants.length ? pltConstants.filter((item) => item.type === 'project_departments') : []}
                  getOptionLabel={option => option.label}
                  id="department"
                  value={pltConstants.length && project.department ? pltConstants.filter((item) => item.type === 'project_departments').find(dept => dept.value === project.department) : null}
                  onChange={(e, value) => onFieldChange(e, 'department', value ? value.value : '')}
                  renderInput={params => <TextField {...params} label="Project Department" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
                />
              </Grid>
              {
                budgetManager ? null :
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      options={leads}
                      getOptionLabel={option => option.name}
                      id="leads"
                      multiple
                      value={leads.length && project.leads && project.leads.length ? leads.filter(item => project.leads.includes(item.id)) : []}
                      onChange={(e, value) => onFieldChange(e, 'leads', value ? value.map(item => item.id) : [])}
                      renderInput={params => <TextField required {...params} error={error.leads ? true : false} helperText={error.leads ? error.leads : ''} onFocus={() => resetErrorKey('leads')} label="Project Lead" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
                    />
                  </Grid>
              }
              {
                budgetManager ? null :
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      options={engineers}
                      getOptionLabel={option => option.name}
                      id="engineers"
                      multiple
                      value={engineers.length && project.engineers && project.engineers.length ? engineers.filter(item => project.engineers.includes(item.id)) : []}
                      onChange={(e, value) => onFieldChange(e, 'engineers', value ? value.map(item => item.id) : [])}
                      renderInput={params => <TextField
                        required  {...params}
                        error={error.engineers ? true : false}
                        onFocus={() => resetErrorKey('engineers')}
                        helperText={error.engineers ? error.engineers : ''}
                        label="Project Engineers"
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                      />
                      }
                    />
                  </Grid>
              }
              <Grid item xs={12} md={4}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    required
                    id="start_date"
                    label="Start Date"
                    format={fieldDateFormat}
                    fullWidth
                    clearable={true}
                    InputLabelProps={{ shrink: true }}
                    value={project.start_date ? project.start_date : null}
                    error={error.start_date ? true : false}
                    helperText={error.start_date ? error.start_date : ''}
                    onChange={(data, value) => { onFieldChange(value, 'start_date', data ? moment(data).format(backendDateFormat) : data); resetErrorKey('start_date') }}
                    inputVariant="outlined"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} md={4}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    required
                    id="expected_end_date"
                    label="Expected End Date"
                    format={fieldDateFormat}
                    fullWidth
                    clearable={true}
                    InputLabelProps={{ shrink: true }}
                    value={project.expected_end_date ? project.expected_end_date : null}
                    inputVariant="outlined"
                    error={error.expected_end_date ? true : false}
                    helperText={error.expected_end_date ? error.expected_end_date : ''}
                    onChange={(data, value) => { onFieldChange(value, 'expected_end_date', data ? moment(data).format(backendDateFormat) : data); resetErrorKey('expected_end_date') }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} md={4}>
                <LesseeCreate
                  options={lessee}
                  paramsKey='lessee'
                  optionKey='name'
                  label='Lessee'
                  value={project.lessee && Object.keys(project.lessee) ? project.lessee : null}
                  onFieldChange={(e, paramsKey, newValue) => onFieldChange(e, 'lessee', newValue)}
                />
                <div style={{ display: 'none' }}>
                  <Autocomplete
                    options={lessee}
                    getOptionLabel={option => option.name}
                    id="lessee_id"
                    value={lessee.length && project.lessee_id ? lessee.find(item => item.id === project.lessee_id) : null}
                    onChange={(e, value) => onFieldChange(e, 'lessee_id', value ? value.id : '')}
                    renderInput={params => <TextField {...params} label="Lessee" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <GoogleLocationField
                  location={project.location ? project.location : ''}
                  onFieldChange={onFieldChange}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="third_party"
                  label="Third Party"
                  fullWidth
                  margin="normal"
                  inputProps={{ maxLength: 20 }}
                  value={project.third_party ? project.third_party : ''}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  onChange={(e) => onFieldChange(e, 'third_party', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="zoho_opportunity_id"
                  label="ZOHO Enquiry ID"
                  fullWidth
                  margin="normal"
                  inputProps={{ maxLength: 20 }}
                  value={project.zoho_opportunity_id ? project.zoho_opportunity_id : ''}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  onChange={(e) => onFieldChange(e, 'zoho_opportunity_id', e.target.value)}
                />
              </Grid>
              {
                budgetManager || smbcInstance? null :
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox color="primary" checked={project.opr_required} onChange={(e) => onFieldChange(e, 'opr_required', e.target.checked)} value="OPR (Operator Performance Rating) Required" />
                      }
                      label={<span style={{ fontSize: '14px' }}>OPR (Operator Performance Rating) Required</span>}
                    />
                  </Grid>
              }
              {/* {
                budgetManager ? null :
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox color="primary" checked={project.cor_required} onChange={(e) => onFieldChange(e, 'cor_required', e.target.checked)} value="COR (Confidential Operator Report) Required" />
                      }
                      label={<span style={{ fontSize: '14px' }}>COR (Confidential Operator Report) Required</span>}
                    />
                  </Grid>
              } */}
              {
                budgetManager ? null :
                  <Grid className="flex-centered" item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox color="primary" checked={project.project_form} onChange={(e) => onFieldChange(e, 'project_form', e.target.checked ? 1 : 0)} value="Miscellaneous Services" />
                      }
                      label={<span style={{ fontSize: '14px' }}>Miscellaneous Services</span>}
                    />
                    <Tooltip title="Selecting this box means that the Project does not include any specific asset for inspection. Only T012 - Miscellaneous Services project report form will be assigned to Project Engineer" arrow><InfoIcon /></Tooltip>
                  </Grid>
              }
              <Grid item xs={12} md={12}>
                <TextField
                  id="project_remarks"
                  label="Project Description"
                  fullWidth
                  margin="normal"
                  multiline
                  rows="3"
                  value={project.project_remarks ? project.project_remarks : ''}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  onChange={(e) => onFieldChange(e, 'project_remarks', e.target.value)}
                />
              </Grid>
              {!project.id ?
                <Grid item xs={12} md={12}>
                  <label className="for-label">Project Attachment</label>
                  <div>
                    <DropzoneArea
                      filesLimit={10}
                      showPreviewsInDropzone={false}
                      useChipsForPreview={true}
                      showPreviews={true}
                      dropzoneText={<p>Drag & Drop Documents OR Click Here </p>}
                      dropzoneClass="full-w-drag-drop-zone"
                      maxWidth="sm"
                      alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                      showAlerts={['error', 'info']}
                      onChange={(files) => onFieldChange('', 'files', files)}
                    />
                  </div>
                </Grid> : null
              }
              <Grid item xs={12} md={12}>
                <div>
                  <div className='project-other-info'>
                    <span className='info-text'>{moreInfo ? 'Less Information' : 'More Information'}</span>
                    <span className='info-icon'>
                      {moreInfo ? <KeyboardArrowDownIcon onClick={() => setMoreInfo(false)} /> : <KeyboardArrowRightIcon onClick={() => setMoreInfo(true)} />}
                    </span>
                  </div>
                  {moreInfo ?
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12}>
                        {[{ label: 'Client Contact', key: 'client_contacts' }, { label: 'Lessee Contact', key: 'operator_contacts' }, { label: 'Third Party Contact', key: 'third_party_contacts' }].map((item, itemIndex) =>
                          <div className="project-contact" key={itemIndex}>
                            <h4 className='flex-centered'>
                              {item.label}
                              <Button
                                color="primary"
                                variant="outlined"
                                size="small"
                                onClick={() => addRemoveContact(item.key, 'add')}
                              >
                                Add Contact
                              </Button>
                            </h4>
                            <TableListComp
                              heads={projectContactHd}
                              data={project[item.key].map((contact, index) =>
                                <ProjectContact
                                  item={contact}
                                  rowCount={project[item.key].length}
                                  index={index + 1}
                                  error={{}}
                                  onFieldChange={(event, keyParam, data) => onFieldChange(event, keyParam, data, item.key, index)}
                                  addRemoveContact={() => addRemoveContact(item.key, 'remove', index)}
                                />
                              )}
                            />
                          </div>
                        )}
                      </Grid>
                      {[{ label: 'General Instruction', key: 'instructions' }, { label: 'Miscellaneous comments', key: 'miscellaneous_comments' }, { label: 'Equipment requirement', key: 'equipment_requirements' }].map((item) =>
                        <Grid item xs={12}>
                          <div className='project-instructions'>
                            <label className="for-label">{item.label}</label>
                            <CkEditorText
                              htmlData={project[item.key]}
                              onChangeData={(data) => onFieldChange('', item.key, data)}
                            />
                          </div>
                        </Grid>
                      )}
                    </Grid> : null
                  }
                </div>
                <Autocomplete
                  options={expenseUsers}
                  getOptionLabel={option => option.name}
                  id="expense_users"
                  multiple
                  value={expenseUsers.length && project.expense_users && project.expense_users.length ? expenseUsers.filter(item => project.expense_users.includes(item.id)) : []}
                  onChange={(e, value) => onFieldChange(e, 'expense_users', value ? value.map(item => item.id) : [])}
                  renderInput={params => <TextField
                    {...params}
                    label="Expense Users"
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  />
                  }
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              {project.id ?
                <ul className='list-inline'>
                  <li className='list-inline-item'>
                    <Button disabled={isLoading} onClick={() => setProjectStatus({ ...projectStatus, statusType: 1 })} size="small" color="secondary" variant="outlined">Cancel Project</Button>
                  </li>
                  <li className='list-inline-item'>
                    <Button disabled={isLoading} onClick={() => setProjectStatus({ ...projectStatus, statusType: 2 })} size="small" color="secondary" variant="outlined">Archive Project</Button>
                  </li>
                  <li className='list-inline-item'>
                    <Button disabled={isLoading} onClick={() => setProjectStatus({ ...projectStatus, statusType: 3 })} size="small" color="secondary" variant="outlined">Close Project</Button>
                  </li>
                </ul> : null
              }
            </Grid>
            <Grid item xs={12} md={6}>
              <ul className='list-inline' style={{ float: 'right' }}>
                <li className='list-inline-item'>
                  <Button disabled={isLoading} onClick={toggleModalFn} size="small" color="primary" variant="outlined">Cancel</Button>
                </li>
                <li className='list-inline-item'>
                  <Button disabled={isLoading} onClick={addEditProjectFn} size="small" color="primary" variant="contained">
                    {isLoading ? <CircularProgress size={20} /> : 'Save'}
                  </Button>
                </li>
              </ul>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog
        open={projectStatus.statusType ? true : false}
        onClose={() => setProjectStatus({ statusType: null, closure_date: null, archiveReason: '' })}
        maxWidth={'md'}
        className='project-management-modal'
      >
        <DialogTitle id="scroll-dialog-title">
          {projectStatus.statusType === 1 ? 'Do you want to Cancel Project?' : projectStatus.statusType === 2 ? 'Do you want to Archive Project?' : projectStatus.statusType === 3 ? 'Do you want to Close Project?' : ''}
        </DialogTitle>
        <DialogContent dividers={true}>
          <div style={{ width: '380px' }}>
            {projectStatus.statusType === 3 ?
              <div>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        margin="normal"
                        required
                        id="date_of_closure"
                        label="Date Of Closure"
                        format={fieldDateFormat}
                        fullWidth
                        clearable={true}
                        InputLabelProps={{ shrink: true }}
                        value={projectStatus.closure_date ? projectStatus.closure_date : null}
                        inputVariant="outlined"
                        onChange={(data, value) => setProjectStatus({ ...projectStatus, closure_date: data ? moment(data).format(backendDateFormat) : data })}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </div> : null
            }
            {projectStatus.statusType === 1 || projectStatus.statusType === 2 ?
              <div>
                <TextField
                  required
                  id="reason"
                  label="Reason"
                  value={projectStatus.archiveReason}
                  multiline
                  maxRows={4}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setProjectStatus({ ...projectStatus, archiveReason: e.target.value })}
                  variant="outlined"
                />
              </div> : null
            }
            <div style={{ textAlign: 'center', margin: '15px 0' }}>
              <ul className='list-inline'>
                <li className='list-inline-item'>
                  <Button disabled={isLoading} onClick={onChangeProjectStatus} color='secondary' size='small' variant='contained'>
                    {isLoading ? <CircularProgress size={20} /> : 'YES'}
                  </Button>
                </li>
                <li className='list-inline-item'>
                  <Button disabled={isLoading} onClick={() => setProjectStatus({ statusType: null, closure_date: null, archiveReason: '' })} color='primary' size='small' variant='contained'>NO</Button>
                </li>
              </ul>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}
export default withRouter(ProjectCRU)
