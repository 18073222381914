import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { TableRowActions } from '../../../shared_elements';
export default function DisassemblyFinding({currentFormDetail, index, item, previewDetail}){
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>{index}</TableCell>
      <TableCell>{item.title ? item.title:'--'}</TableCell>
      <TableCell>
        <div dangerouslySetInnerHTML={{__html: item.remarks}}></div>
      </TableCell>
      <TableCell className="actions-cell" style={{width: '120px'}}>
        <TableRowActions
          actions={ currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ? ['E','D']:['V']}
          previewDetail={(mode) => previewDetail(mode)}
        />
      </TableCell>
    </TableRow>
  )
}
