import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Button, Grid, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@material-ui/core';
import ComponentSection from './ComponentSection'
import { checkApiStatus } from '../../../utils_v2';
import { globalPutService, globalPostService } from '../../../utils_v2/globalApiServices';
const ComponentFields = ({params, componentFields, toggleModalFn, getResponseBack}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [componentSections, setComponentSections] = useState(componentFields.data)
    const [fieldsError, setFieldsError] = useState({sectionDuplicate:'', sectionsError:[]});
    const findDuplicates = (strArray) => {
        return strArray.filter((item, index) => strArray.indexOf(item.trim()) !== index)
    }
    const onFieldChange = (value, key, sectionIndex, questionIndex=null) => {
        if(questionIndex !== null){
            setComponentSections(componentSections.map((section, index) => index !== sectionIndex ? section : {
                ...section, question_answers:section.question_answers.map((item, itemIndex) => questionIndex !== itemIndex ? item : {...item, [key]:value})
            }))
        }else{
            setComponentSections(componentSections.map((section, index) => index !== sectionIndex ? section : {...section, [key]:value}))
        }
    }
    const onAddRemoveFields = (type, sectionIndex, questionIndex) => {
        if(type === 'add'){
            setComponentSections(componentSections.map((section, index) => index !== sectionIndex ? section:{...section , question_answers: [...section.question_answers, {label:'',value:''}]}))
        }else{
            setComponentSections(componentSections.map((section, index) => index !== sectionIndex ? section : {...section, question_answers:section.question_answers.filter((item, itemIndex) => itemIndex !== questionIndex)}))
        }
    }
    const onAddRemoveSection = (type, sectionIndex) => {
        if(type === 'add'){
            const section = {title:'',order:componentSections.length+1, inspection_asset_section:componentFields.sectionId,question_answers:[{label:'', value:''}]}
            setComponentSections([...componentSections, section])
        }else{
            setComponentSections(componentSections.filter((section, index) => index !== sectionIndex))
        }
    }
    const onUpdateComponentFields  = () => {
        let compErrors = [], validationInputs={}, isError=false, isSectionDuplicate=false;
        compErrors = componentSections.map((item) => {
            let isDuplicate = '';
            let errors = item.question_answers.map((question) => {
                return {label:question?.label?.trim()?.length ? '':'Please enter Label'}
            });
            if(item.question_answers.length){
                let duplicates = findDuplicates(item.question_answers.filter(qus => qus?.label?.trim()?.length).map(qus => qus.label.toLowerCase()));
                if(duplicates.length){
                    isDuplicate='Label cannot be duplicates'
                }
            }
            return {isDuplicate:isDuplicate,questionsError:errors}
        });
        if(componentSections?.length > 1){
            let duplicates=findDuplicates(componentSections.filter((item) => item?.title?.trim()?.length).map(item => item.title.toLowerCase()));
            if(duplicates.length){
                isSectionDuplicate=true
            }
        }
        if(compErrors.length){
            compErrors.map(errorItems => {
                if(errorItems.isDuplicate?.trim()?.length){
                    isError = true;
                     return
                }else{
                    errorItems.questionsError.map((error, index) => {
                        if(Object.keys(error).some((k) => { return error[k]?.trim()?.length})){
                            isError = true
                            return
                        }
                    })
                }
            })
        }
        if(isSectionDuplicate || isError){
            setFieldsError({sectionDuplicate:isSectionDuplicate?'Section cannot be duplicate':'', sectionsError:isError?compErrors:[]});
        }else{
            globalPostService(`technical/v2/workorder/${params.workOrderSlug}/${componentFields.sectionId}/asset-sections-components/`, componentSections)
            .then(response => {
                if(checkApiStatus(response)){
                    enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    toggleModalFn();getResponseBack(response.data.data);
                }else{
                    enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                }
            })
        }
    }
    const onResetError = (key,sectionIndex, questionIndex) => {
        if(key === 'section'){
            setFieldsError({...fieldsError, sectionDuplicate:''});
        }else{
            setFieldsError({...fieldsError, sectionsError:fieldsError.sectionsError.map((section, secIndex) => secIndex !== sectionIndex ? {...section, isDuplicate:''} : {
                ...section, isDuplicate:'', questionsError:section.questionsError.map((qus, qIndex) => qIndex !== questionIndex ? qus:{
                    ...qus, [key]:''
                })
            })})
        }
        
    }
    return(
        <Dialog
            open={componentFields.modal}
            onClose={toggleModalFn}
            className='project-management-modal'
            maxWidth='lg'
        >
            <DialogTitle id="scroll-dialog-title">
                {componentFields.mode === 'add' ? 'Add':'Edit'} Component Fields
            </DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item md={12}>
                        <div style={{marginBottom:'10px', textAlign:'right'}}>
                            <Button onClick={(e) => {onAddRemoveSection('add'); componentSections.length > 2 ? enqueueSnackbar('Section added, scroll to bottom', { variant: 'info',anchorOrigin:{horizontal:'right', vertical:'top'}}):e.preventDefault();}} color='primary' size='small' variant='contained'>Add Section</Button>
                        </div>
                        <div className='t004-component-fields-modal' style={{width:'580px'}}>
                            {componentSections.map((section, sectionIndex) => 
                                <ComponentSection 
                                    sectionError={fieldsError?.sectionsError?.length && fieldsError?.sectionsError[sectionIndex] ? fieldsError?.sectionsError[sectionIndex]:{}}
                                    section={section}
                                    sectionIndex={sectionIndex}
                                    onAddRemoveSection={onAddRemoveSection}
                                    onAddRemoveFields={onAddRemoveFields}
                                    onFieldChange={onFieldChange}
                                    onResetError={(key, questionIndex) => onResetError(key, sectionIndex, questionIndex)}
                                />
                            )}
                            {fieldsError.sectionDuplicate ? <p className='error-msg'>{fieldsError.sectionDuplicate}</p>:null}
                        </div>
                    </Grid>
                </Grid>
                
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size="small" variant="outlined">Cancel</Button>
                { componentSections.length ?
                    <Button disabled={isLoading} onClick={onUpdateComponentFields} color='primary' size="small" variant="contained">
                        {isLoading ? <CircularProgress size={24} />:'Save'}
                    </Button>:null
                }
            </DialogActions>
        </Dialog>
    )
}

export default withRouter(ComponentFields);