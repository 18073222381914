import React from 'react'
import { regexConstants, errorMsg1 } from '../../constants/regEx';

export const te002AccessoriesListHd = [
  { label: 'ATA', sortOption: false },
  { label: 'Description', sortOption: false },
  { label: <table style={{width:'100%'}}><thead><tr><th colSpan="2" align="center">Delivery</th></tr><tr><th align="center">PN</th><th align="center">SN</th></tr></thead></table>, sortOption: false },
  { label: <table style={{width:'100%'}}><thead><tr><th colSpan="2" align="center">Installed</th></tr><tr><th align="center">PN</th><th align="center">SN</th></tr></thead></table>, sortOption: false },
  { label: 'TSN', sortOption: false },
  { label: 'CSN', sortOption: false },
  { label: 'Remarks', sortOption: false }
];
export const te002AdditionalDataHd = [
  { label: 'Item No.', sortOption: false },
  { label: 'Description', sortOption: false },
  { label: 'P/N', sortOption: false },
  { label: 'S/N', sortOption: false },
  { label: 'Remarks', sortOption: false }
];
export const te002MissingHardwareHd = [
  { label: 'ATA', sortOption: false },
  { label: 'Description', sortOption: false },
  { label: 'P/N', sortOption: false },
  { label: 'S/N', sortOption: false },
  { label: 'Remarks', sortOption: false },
];

export const accessoriesList = {
  ata: null,
  description: null,
  delivery_part_number: null,
  delivery_serial_number: null,
  installed_part_number: null,
  installed_serial_number: null,
  tsn: null,
  csn: null,
  remarks: null
}
export const additionalData = {
  description: null,
  item: null,
  part_number: null,
  remarks: null,
  serial_number: null
}
export const missingHardware = {
  description: null,
  ata: null,
  part_number: null,
  remarks: null,
  serial_number: null
}

export const errorCode = {
  place_of_inspection:{
    0:'',
    1: 'Please enter Place of Inspection',
  },
  place_of_inspectionObj:{
    required: true,
  },
  date_of_inspection:{
    0:'',
    1: 'Please enter Date of Inspection',
  },
  date_of_inspectionObj:{
    required: true,
  },
  report_date:{
    0:'',
    1: 'Please enter Report Date',
  },
  report_dateObj:{
    required: true,
  },
  report_issued_place:{
    0:'',
    1: 'Please enter Report Issued Place',
  },
  report_issued_placeObj:{
    required: true,
  },
}
