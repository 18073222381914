import React from 'react';
import { TableRow, TableCell, TextField } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { regexConstants } from '../../../constants/regEx';
export default function AdditinalData({currentFormDetail, item, error, onFieldChange, previewDetail}){
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>
        { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
          <TextField
            id="item"
            fullWidth
            margin="normal"
            multiline
            rowsMax={4}
            inputProps={{maxLength: 20}}
            value={item.item ? item.item:''}
            onChange={(e) => onFieldChange(e, 'item', e.target.value)}
            error={false}
            helperText=''
            InputLabelProps={{shrink: true}}
          />:
          item.item ? item.item:'--'
        }
      </TableCell>
      <TableCell>
        { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
          <TextField
            id="description"
            fullWidth
            margin="normal"
            multiline
            rowsMax={4}
            inputProps={{maxLength: 255}}
            value={item.description ? item.description:''}
            onChange={(e) => onFieldChange(e, 'description', e.target.value)}
            error={false}
            helperText=''
            InputLabelProps={{shrink: true}}
          />:
          item.description ? item.description:'--'
        }
      </TableCell>
      <TableCell>
        { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
          <TextField
            id="part_number"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 20}}
            value={item.part_number ? item.part_number:''}
            onChange={(e) => onFieldChange(e, 'part_number', e.target.value)}
            error={error.part_number ? true:false}
            helperText={error.part_number ? error.part_number:''}
            InputLabelProps={{shrink: true}}
          />:
          item.part_number ? item.part_number:'--'
        }
      </TableCell>
      <TableCell>
        { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
          <TextField
            id="serial_number"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 20}}
            value={item.serial_number?item.serial_number:''}
            onChange={(e) => onFieldChange(e, 'serial_number', e.target.value)}
            error={error.serial_number ? true:false}
            helperText={error.serial_number ? error.serial_number:''}
            InputLabelProps={{shrink: true}}
          />:
          item.serial_number?item.serial_number:'--'
        }
      </TableCell>
      <TableCell>
        { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
          <TextField
            id="remarks"
            fullWidth
            margin="normal"
            multiline
            rowsMax={4}
            inputProps={{maxLength: 255}}
            value={item.remarks?item.remarks:''}
            onChange={(e) => onFieldChange(e, 'remarks', e.target.value)}
            error={false}
            helperText=''
            InputLabelProps={{shrink: true}}
          />:
          item.remarks?item.remarks:'--'
        }
      </TableCell>
      { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
        <TableCell className="actions-cell">
          <DeleteOutlineIcon onClick={() => previewDetail('D')} color="error" />
        </TableCell>:null
      }
    </TableRow>
  )
}
