import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { CircularProgress } from '../../../shared_elements';
import EditIcon from '@material-ui/icons/Edit';
import { onCheckPermission } from '../../../utils_v2'
const assetTypes = {
  3: 'APU',
  4: 'Landing Gear',
  5: 'Propeller'
}
const WorkOrderAsset = ({project, icon, serialNo, type, progress, onEditWorkOrder,work_order_number}) => {
  return(
    <div className="work-order-asset flex-centered">
      <CircularProgress value={progress} />
      <div className="asset-info">
        <div style={{marginLeft:'6px'}}>
         { type ? <p>{type}</p>:null}
          <h4>
            {serialNo}
            { onCheckPermission('technical', 'work_orders','U') && project.archive_status !==1 && (project.status && project.status.id && ![7,8].includes(project.status.id)) ? <span onClick={(e) => {e.preventDefault(); e.stopPropagation(); onEditWorkOrder()}}>Edit Workorder<EditIcon color="primary" fontSize='small' /></span>:null}
          </h4>
         <p>{ work_order_number ?  'WO No.' + ' ' + work_order_number : ''}</p> 
        </div>
      </div>
    </div>
  )
}
export default withRouter(WorkOrderAsset);
