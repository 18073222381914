import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TableRow, TableCell, TextField } from '@material-ui/core';
import { TableRowActions } from '../../../shared_elements';
import { regexConstants } from '../../../constants/regEx';
export default function LLPDetails({currentFormDetail, item, index, error, onFieldChange, previewDetail}){
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>{index}</TableCell>
      <TableCell>
        { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
          <TextField
            id="description"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 255}}
            value={item.description ? item.description:''}
            onChange={(e, value) => onFieldChange(e, 'description', e.target.value)}
            InputLabelProps={{shrink: true}}
          />:
          item.description ? item.description:'--'
        }

      </TableCell>
      <TableCell>
        { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
          <TextField
            id="part_number"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 20}}
            value={item.part_number ? item.part_number:''}
            error={error.part_number ? true:false}
            helperText={error.part_number ? error.part_number:''}
            onChange={(e, value) => onFieldChange(e, 'part_number', e.target.value)}
            InputLabelProps={{shrink: true}}
          />:
          item.part_number ? item.part_number:'--'
        }
      </TableCell>
      <TableCell>
        { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
          <TextField
            id="serial_number"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 20}}
            value={item.serial_number ? item.serial_number:''}
            error={error.serial_number ? true:false}
            helperText={error.serial_number ? error.serial_number:''}
            onChange={(e, value) => onFieldChange(e, 'serial_number', e.target.value)}
            InputLabelProps={{shrink: true}}
          />:
          item.serial_number ? item.serial_number:'--'
        }
      </TableCell>
      <TableCell>
        { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
          <TextField
            id="csn"
            fullWidth
            margin="normal"
            value={item.csn ? item.csn:''}
            onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'csn', e.target.value) : e.preventDefault() }}
            InputLabelProps={{shrink: true}}
          />:
          item.csn ? item.csn:'--'
        }
      </TableCell>
      <TableCell>
        { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
          <TextField
            id="cycles_remaining"
            fullWidth
            margin="normal"
            value={item.cycles_remaining ? item.cycles_remaining:''}
            onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'cycles_remaining', e.target.value) : e.preventDefault() }}
            InputLabelProps={{shrink: true}}
          />:
          item.cycles_remaining ? item.cycles_remaining:'--'
        }
      </TableCell>
      <TableCell style={{minWidth:'110px'}}>
        { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
          <Autocomplete
            options = {[{label: 'Scrap', value:1}, {label: 'Reuse', value:2}]}
            getOptionLabel={option => option.label}
            id="status"
            value={ item.status ? item.status === 1 ? {label: 'Scrap', value:1} : {label: 'Reuse', value:2} : null }
            onChange={(e, value) => onFieldChange(e, 'status', value ? value.value:'')}
            renderInput={params => <TextField required {...params} margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
          />:
          item.status ? [{label: 'Scrap', value:1}, {label: 'Reuse', value:2}].find(status => status.value === item.status).label:'--'
        }
      </TableCell>
      <TableCell>
        { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
          <TextField
            id="remarks"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 255}}
            value={item.remarks ? item.remarks:''}
            onChange={(e, value) => onFieldChange(e, 'remarks', e.target.value)}
            InputLabelProps={{shrink: true}}
          />:
          item.remarks ? item.remarks:'--'
        }
      </TableCell>
      { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
        <TableCell>
          <TableRowActions
            actions={['D']}
            previewDetail={(mode) => previewDetail(mode)}
          />
        </TableCell>:null
      }

    </TableRow>
  )
}
