import React from 'react';
import { TableRow, TableCell, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
const status = [{
		"value": 2,
		"label": "Closed",
		"type": "engine_gap_report_status",
		"remarks": null
	}, {
		"value": 1,
		"label": "Open",
		"type": "engine_gap_report_status",
		"remarks": null
	}, {
		"value": 3,
		"label": "Unresolved",
		"type": "engine_gap_report_status",
		"remarks": null
	}]
export default function RecordDetail({currentFormDetail, index, item, onFieldChange, previewDetail}){
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>{index}</TableCell>
      <TableCell>
				{ currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
					<TextField
	          id="description"
	          fullWidth
	          margin="normal"
	          multiline
	          rowsMax={4}
						inputProps={{maxLength: 255}}
	          value={item.description?item.description:''}
	          onChange={(e) => onFieldChange(e, 'description', e.target.value)}
	          error={false}
	          helperText=''
	          InputLabelProps={{shrink: true}}
	        />:
					item.description?item.description:'--'
				}
      </TableCell>
      <TableCell>
				{ currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
					<TextField
	          id="acumen_remarks"
	          fullWidth
	          margin="normal"
	          multiline
	          rowsMax={4}
						inputProps={{maxLength: 255}}
	          value={item.acumen_remarks?item.acumen_remarks:''}
	          onChange={(e) => onFieldChange(e, 'acumen_remarks', e.target.value)}
	          error={false}
	          helperText=''
	          InputLabelProps={{shrink: true}}
	        />:
					item.acumen_remarks?item.acumen_remarks:'--'
				}
      </TableCell>
      <TableCell>
				{ currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
					<Autocomplete
						disableClearable={true}
	          options = {status}
	          getOptionLabel={option => option.label}
	          id={`status${index}`}
	          value={item.status ? status.find(s => s.value === item.status):null}
	          onChange={(e, data) => onFieldChange(e, 'status', data?data.value:'')}
	          renderInput={params => <TextField placeholder="Select Status" style={{width:'150px'}} {...params} margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
	        />:
					item.status ? status.find(s => s.value === item.status).label:'--'
				}
      </TableCell>
      <TableCell>
				{ currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
					<TextField
	          id="comments"
	          fullWidth
	          margin="normal"
	          multiline
	          rowsMax={4}
						inputProps={{maxLength: 255}}
	          value={item.comments?item.comments:''}
	          onChange={(e) => onFieldChange(e, 'comments', e.target.value)}
	          error={false}
	          helperText=''
	          InputLabelProps={{shrink: true}}
	        />:
					item.comments?item.comments:'--'
				}
      </TableCell>
      <TableCell>
				{ currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
					<TextField
	          id="ref_document"
	          fullWidth
	          margin="normal"
	          multiline
	          rowsMax={4}
						inputProps={{maxLength: 255}}
	          value={item.ref_document?item.ref_document:''}
	          onChange={(e) => onFieldChange(e, 'ref_document', e.target.value)}
	          error={false}
	          helperText=''
	          InputLabelProps={{shrink: true}}
	        />:
					item.ref_document?item.ref_document:'--'
				}
      </TableCell>
      <TableCell>
				{ currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
					<TextField
	          id="operator_comments"
	          fullWidth
	          margin="normal"
	          multiline
	          rowsMax={4}
						inputProps={{maxLength: 255}}
	          value={item.operator_comments?item.operator_comments:''}
	          onChange={(e) => onFieldChange(e, 'operator_comments', e.target.value)}
	          error={false}
	          helperText=''
	          InputLabelProps={{shrink: true}}
	        />:
					item.operator_comments?item.operator_comments:'--'
				}
      </TableCell>
			{ currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
				<TableCell className="actions-cell">
					<DeleteOutlineIcon onClick={() => previewDetail('D')} color="error" />
	      </TableCell>:null
			}
    </TableRow>
  )
}
