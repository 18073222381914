import { regexConstants, errorMsg1 } from '../../constants/regEx';
export const engineShopVist = {
    added_from: 0,
    arc_date: null,
    attachments: [],
    build_target: "",
    cshi: null,
    cslsv: null,
    csn: null,
    cso: null,
    csr: null,
    date_of_reinstallation: null,
    date_of_removal: null,
    engine_work_order: 0,
    final_workscope: "",
    induction_date: null,
    llp_status: "",
    reason_for_removal: "",
    shop_facility: "",
    shop_visit_number: "",
    status: 0,
    test_run_egt_margin: "",
    total_air_temperature: "",
    tshi: null,
    tslsv: null,
    tsn: null,
    tso: null,
    tsr: null,
  }
export const errorCode = {
  report_date:{
    0:'',
    1: 'Please enter Report Date',
  },
  report_dateObj:{
    required: true,
  },
  report_issued_place:{
    0:'',
    1: 'Please enter Report Issued Place',
  },
  report_issued_placeObj:{
    required: true,
  }
}
