import { regexConstants, errorMsg1 } from '../../constants/regEx';
export const te001ComponenttHd = [
    { label: 'SI No.', sortOption: false },
    { label: 'Description', sortOption: false },
    { label: 'Findings', sortOption: false },
    { label: 'Status', sortOption: false }
  ];
export const errorCode = {
  type_of_report:{
    0:'',
    1: 'Please select Report Type'
  },
  type_of_reportObj:{
    required: true,
  },
  place_of_inspection:{
    0:'',
    1: 'Please enter Place of Inspection',
  },
  place_of_inspectionObj:{
    required: true,
  },
  amm_revision_number:{
    0:'',
    1: 'Please enter AMM Revision Number'
  },
  amm_revision_numberObj:{
    required: true
  },
  report_date:{
    0:'',
    1: 'Please enter Report Date',
  },
  report_dateObj:{
    required: true,
  },
  amm_revision_date:{
    0:'',
    1: 'Please enter AMM Revision Date',
  },
  amm_revision_dateObj:{
    required: true,
  },
  date_of_inspection_from:{
    0:'',
    1: 'Please enter Date of Inspection from',
  },
  date_of_inspection_fromObj:{
    required: true,
  },
  report_issued_place:{
    0:'',
    1: 'Please enter Report Issued Place',
  },
  report_issued_placeObj:{
    required: true
  },
}
