import React from 'react';
import { Paper, TextField, Grid } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { LabelValueCard } from '../../../shared_elements';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
export default function GeneralDetail({currentFormDetail, generalDetail, onFieldChange, error, handleError, resetErrorKey}){
  const canEdit = currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save; 
  return(
    <Paper className="form-detail-card">
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          <TextField
            disabled={!canEdit}
            id="place_of_inspection"
            label="Place of Inspection"
            fullWidth
            required
            margin="normal"
            inputProps={{maxLength: 50}}
            value={generalDetail.place_of_inspection ? generalDetail.place_of_inspection:'' }
            onChange={(e) => onFieldChange(e, 'place_of_inspection', e.target.value)}
            error={error.place_of_inspection ? true:false}
            helperText={error.place_of_inspection ? error.place_of_inspection: ''}
            InputLabelProps={{shrink: true}}
            onBlur={(e) => handleError(e.target.value,'place_of_inspection')}
            onFocus={(e) => resetErrorKey('place_of_inspection')}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                disabled={!canEdit}
                margin="normal"
                id="date_of_inspection"
                label="Date of Inspection"
                format={fieldDateFormat}
                fullWidth
                required
                InputLabelProps={{shrink: true}}
                disableFuture={true}
                inputProps={{readOnly: true}}
                clearable={true}
                value={generalDetail.date_of_inspection ? generalDetail.date_of_inspection:null}
                error={error.date_of_inspection ? true:false }
                helperText={error.date_of_inspection ? error.date_of_inspection: ''}
                onChange={(data, value) => {onFieldChange(value, 'date_of_inspection', data ? moment(data).format(backendDateFormat):data); resetErrorKey('date_of_inspection')}}
                inputVariant="outlined"
              />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={3}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              disabled={!canEdit}
              margin="normal"
              id="report_date"
              label="Report Date"
              format={fieldDateFormat}
              fullWidth
              required
              InputLabelProps={{shrink: true}}
              disableFuture={true}
              inputProps={{readOnly: true}}
              clearable={true}
              value={generalDetail.report_date ? generalDetail.report_date:null}
              error={error.report_date ? true:false }
              helperText={error.report_date ? error.report_date: ''}
              onChange={(data, value) => {onFieldChange(value, 'report_date', data ? moment(data).format(backendDateFormat):data); resetErrorKey('report_date')}}
              inputVariant="outlined"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            disabled={!canEdit}
            id="report_issued_place"
            label="Report Issued Place"
            fullWidth
            required
            margin="normal"
            value={generalDetail.report_issued_place ? generalDetail.report_issued_place:'' }
            error={error.report_issued_place ? true:false}
            helperText={error.report_issued_place ? error.report_issued_place: ''}
            InputLabelProps={{shrink: true}}
            inputProps={{maxLength: 50}}
            onChange={(e) => onFieldChange(e, 'report_issued_place', e.target.value)}
            onBlur={(e) => handleError(e.target.value,'report_issued_place')}
            onFocus={(e) => resetErrorKey('report_issued_place')}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </Paper>
  )
}
