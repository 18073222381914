import React, { Fragment, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link, withRouter } from 'react-router'
import { DropzoneArea } from 'material-ui-dropzone';
import { CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { globalGetService, globalPostService, globalPutService } from '../../../utils_v2/globalApiServices'
import { checkApiStatus, downloadFileType } from '../../../utils_v2';
import { templateObj } from '../';
import { globalExportService } from '../../../globalServices';
import { GetApp } from '@material-ui/icons';
const AddEditFormTemplate = ({params, forms, templates, getResponseBack, toggleModalFn, modal, id}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [templateCrud, setTemplateCrud] = useState(templateObj);
    const [error, setError] = useState({});
    useEffect(() => {
        if(id){
            let template = templates.find((item) => item.id === id);
            if(template){
                setTemplateCrud(template);
            }
        }
    }, []);
    const onFieldChange = (e, keyParam, value) => {
        setTemplateCrud({
            ...templateCrud,
            [keyParam]: value
        });
    }
    const onResetErrorKey = (key) => {
        setError({
            ...error,
            [key]:''
        })
    }
    const downloadSampleTemplate = () => {     
        let url = '';
        if(templateCrud.type === "T003"){
            url = 'technical/v2/workorder/sample_sections_download/'
        }else if(templateCrud.type === "T004"){
            url = 'api/technical/v2/workorder/sample-observation-sections/'
        }else{
            url = 'technical/v2/sections/sampleDownload/'
        }
        globalExportService(url)
        .then(response=>{
                downloadFileType(response, templateCrud.type +'_Sample_Template.', 'xls' )
        })   
    }
    const onAddEditTemplate = () => {
        let validationInputs = {
            name:templateCrud.name ? '':'Please enter Name',
            file:templateCrud.id ? '':templateCrud.file && templateCrud.file.length ? '':'Please upload file',
            type: templateCrud.type ? '':'Please select form type',
        };
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            let formData = new FormData();
            formData.append('name', templateCrud.name)
            formData.append('description', templateCrud.description)
            formData.append('type', templateCrud.type)
            if(templateCrud.file){
                formData.append('file', templateCrud.file[0])
            }
            setLoading(true);
            if(templateCrud.id){
                globalPutService(`technical/templates/${templateCrud.id}/`, formData)
                .then(response => {
                    setLoading(false);
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                        toggleModalFn();
                        getResponseBack();
                    }else{
                        enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                    }
                })
            }else{
                globalPostService(`technical/templates/`, formData)
                .then(response => {
                    setLoading(false);
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                        toggleModalFn();
                        getResponseBack();
                    }else{
                        enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                    }
                })
            }
        }else{
            setError(validationInputs);
        }
    }
    return(
        <Fragment>
            <Dialog
                maxWidth="md"
                open={modal}
                onClose={toggleModalFn}
                aria-labelledby="scroll-dialog-title"
                className='project-management-modal'
            >
                <DialogTitle id="scroll-dialog-title">
                        {id ? 'Edit':'Add'} Form Template
                </DialogTitle>
                <DialogContent dividers={true}>
                    <form>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="name"
                                    label="Name"
                                    fullWidth
                                    margin="normal"
                                    error={error.name} 
                                    helperText={error.name}
                                    value={templateCrud.name ? templateCrud.name : ''}
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{maxLength: 50}}
                                    onChange={(e) => onFieldChange(e, 'name', e.target.value)}
                                    onFocus={() => onResetErrorKey('name')}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    options = {forms.filter(i => [1,2,3,4].includes(i.id)) }
                                    getOptionLabel={option => option.name}
                                    id="type"
                                    value={templateCrud.type && forms.length ? forms.find(item => item.name === templateCrud.type) : null}
                                    onChange={(e, value) => onFieldChange(e, 'type', value?value.name:null)}
                                    renderInput={params => <TextField required error={error.type} helperText={error.type} onFocus={() => onResetErrorKey('type')} {...params} label="Form Type" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="description"
                                    label="Description"
                                    fullWidth
                                    margin="normal"
                                    value={templateCrud.description ? templateCrud.description : ''}
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{maxLength: 255}}
                                    onChange={(e) => onFieldChange(e, 'description', e.target.value)}
                                    variant='outlined'
                                    multiline
                                    maxRows={3}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <label style={{ display: 'inline-flex', alignItems: 'center' }} className="for-label">Template Attachment{!templateCrud.id ? <sup>*</sup> : null}
                                    {
                                        templateCrud.type ?
                                            <span className='download-sample-temp' onClick={() => downloadSampleTemplate()} > <GetApp /> Download Sample Template </span>
                                            : null}
                                </label>
                                <div>
                                    <DropzoneArea
                                        acceptedFiles={['.xls, .xlsx']}
                                        filesLimit={1}
                                        showPreviewsInDropzone={false}
                                        maxFileSize={10428800}
                                        useChipsForPreview={true}
                                        showPreviews={ true }
                                        dropzoneText={<p>Drag & Drop Attachments Or Click Here </p>}
                                        dropzoneClass="full-w-drag-drop-zone"
                                        maxWidth="sm"
                                        alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                                        showAlerts={['error', 'info']}
                                        onChange={(files) => onFieldChange('', 'file', files)}
                                    />
                                    <p><span style={{background:'#ffe160', fontSize:'11px', padding:'2px 3px'}}>Format Support: .xls, .xlsx</span></p>
                                    { error.file ? <p className='error-msg'>{error.file}</p>:null}
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={toggleModalFn} color="primary" variant='outlined' size='small'>
                        Cancel
                    </Button>                 
                    <Button disabled={isLoading} onClick={() => onAddEditTemplate()} color="primary" variant='contained' size='small'>
                        {isLoading ? 
                            <CircularProgress size={20} />:'Save'
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}
export default withRouter(AddEditFormTemplate);