import React, { Component, Fragment } from 'react';
import { Paper, TextField, Grid, Tabs, Tab, Button } from '@material-ui/core';
import { withRouter } from 'react-router';
import { isMobile } from 'react-device-detect';
import { TableListComp, EmptyCollection, DeletePopUp, PageLoader } from '../../../shared_elements';
import { RecordDetail, LLPDetail, GeneralDetail, PrerequisiteContent } from '../components';
import { FormWrapper, ReadPrerequisite } from '../../Elements';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { getCurrentFormDetailApi, changeFormStatusApi, getGenReportDetailApi, editGenReportDetailApi, deleteGenReportDetailApi, exportGenReportDetailApi } from '../apiServices';
import { fieldValidation } from '../../../utils_v2/formValidation';
import { errorCode, recordDetail, llpDetail, te003RecordDetailHd, te003LLPDetailHd } from '../';
class GapReports extends Component{
    constructor(props){
        super(props);
        this.state = {
          skeletonLoader: true,
          pageLoader: false,
          currentFormDetail:{},
          tabIndex: 0,
          generalReportInfo:{
            general_details: {},
            list:[],
            summary: {}
          },
          error: {
            general_details: {},
            llpDetails:[],
            recordDetails:[]
          },
          deleteModal: false,
          deleteIds:{},
        }
        this.getCurrentFormDetailApi = getCurrentFormDetailApi.bind(this);
        this.changeFormStatusApi = changeFormStatusApi.bind(this);
        this.getGenReportDetailApi = getGenReportDetailApi.bind(this);
        this.editGenReportDetailApi = editGenReportDetailApi.bind(this);
        this.deleteGenReportDetailApi = deleteGenReportDetailApi.bind(this);
        this.exportGenReportDetailApi = exportGenReportDetailApi.bind(this);
    }
    componentDidMount(){
        this.getCurrentFormDetailApi(this.props);
        this.getGenReportDetailApi(this.props);
    }
    handleChange = (event, newValue) => {
        this.setState(prevState => ({
        ...prevState,
        tabIndex:newValue
        }))
    }
    onFieldChange = (event, keyParam, data) => {
        this.setState(prevState => ({
        ...prevState,
            generalReportInfo: {
                ...prevState.generalReportInfo,
                general_details: {
                ...prevState.generalReportInfo.general_details,
                [keyParam]: data
                }
            }
        }))
    }
    onComponentChange = (event, keyParam, data, name, index) => {
        this.setState(prevState => ({
          ...prevState,
          generalReportInfo: {
            ...prevState.generalReportInfo,
            list: prevState.generalReportInfo.list.map(item => item.name === name ? {...item, gap_reports: item.gap_reports.map((report,rptIndex) => rptIndex === index ? {...report, [keyParam]: data} : report)} :item)
          }
        }));
    }
    addComponent = (tableType, data) => {
        this.setState(prevState => ({
        ...prevState,
        generalReportInfo: {
            ...prevState.generalReportInfo,
            list: prevState.generalReportInfo.list.map(item => item.name === tableType ? {...item, gap_reports: [...item.gap_reports, data]} : item)
        }
        }))
    }
    deleteTableComFn = () => {
        const { deleteIds, generalReportInfo } = this.state;
        if(deleteIds.id){
          this.deleteGenReportDetailApi(this.props, deleteIds)
        }else{
          this.setState(prevState => ({
            ...prevState,
            generalReportInfo: {
              ...prevState.generalReportInfo,
              list: [...prevState.generalReportInfo.list].map(item => item.name === deleteIds.type ? {
                ...item, gap_reports: [...item.gap_reports].filter((rpt, index) => index !== deleteIds.deleteIndex)
              } :item)
            },
            deleteIds:{},
            deleteModal:false
          }))
        }
    }

    handleError = (value, key) => {
        this.setState(prevState => ({
          ...prevState,
          error: {
            ...prevState.error,
            general_details: {
              ...prevState.error.general_details,
              [key]: errorCode[key][fieldValidation({...errorCode[key+'Obj'], fieldval: value})]
            }
          }
        }));
    }

    render(){
        const { currentFormDetail, generalReportInfo, tabIndex, deleteModal, deleteIds, skeletonLoader, pageLoader, error } = this.state;
        return(
            <section className='project-forms-section'>
                { currentFormDetail && Object.keys(currentFormDetail).length ?
                    <FormWrapper formInfo={currentFormDetail} />:null
                }
                <div className='gap-reports'>
                    <div className="technical-engine-forms">
                        <h4 className="gen-detail-title">General Details <ReadPrerequisite content={<PrerequisiteContent />} /></h4>
                        <GeneralDetail
                            currentFormDetail={currentFormDetail}
                            generalDetail={generalReportInfo.general_details}
                            onFieldChange={this.onFieldChange}
                            error={error.general_details}
                            handleError={this.handleError}
                            resetErrorKey={(key) => {
                                this.setState(prevState => ({
                                ...prevState,
                                error: {
                                    ...prevState.error,
                                    general_details: {
                                    ...prevState.error.general_details,
                                    [key]: ''
                                    }
                                }
                                }));
                            }}
                        />
                        <Tabs
                            value={tabIndex}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons={isMobile ? 'on':'auto'}
                            >
                                <Tab label="Record Details" />
                                <Tab label="LLP Details" />
                        </Tabs>
                        { tabIndex === 0 &&
                            <div className="">
                                { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
                                    <div className="text-right" style={{margin: '0 0 20px 0'}}>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            color="primary"
                                            disableElevation={true}
                                            onClick={() => {this.addComponent('Record Details', recordDetail); this.tabEnd.scrollIntoView({ behavior: 'smooth' });}}
                                            startIcon={<ControlPointIcon fontSize="small" color="primary" />}
                                        >
                                        Add Item
                                        </Button>
                                    </div>:null
                                }
                                <TableListComp
                                    heads={ currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ? [...te003RecordDetailHd, { label: 'Action', sortOption: false, actionCell: true }]:te003RecordDetailHd}
                                    data={generalReportInfo.list.length ? generalReportInfo.list.find(item => item.name === 'Record Details').gap_reports.map((item,index) =>
                                        <RecordDetail
                                        currentFormDetail={currentFormDetail}
                                        key={index}
                                        index={index+1}
                                        item={item}
                                        onFieldChange={(event, keyParam, data) => this.onComponentChange(event, keyParam, data, 'Record Details', index)}
                                        previewDetail={(mode) => { this.setState({deleteModal:true, deleteIds:{...item, deleteIndex: index, type: 'Record Details'}}) }}
                                        />
                                    ):null}
                                    noRecord={ generalReportInfo.list.length && generalReportInfo.list.find(item => item.name === 'Record Details').gap_reports.length ? null:
                                        <EmptyCollection title="No records found" />
                                    }
                                />
                            </div>
                        }
                        { tabIndex === 1 &&
                            <div className="">
                                { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
                                    <div className="text-right" style={{margin: '0 0 20px 0'}}>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            color="primary"
                                            disableElevation={true}
                                            onClick={() => {this.addComponent('LLP Details', llpDetail); this.tabEnd.scrollIntoView({ behavior: 'smooth' });}}
                                            startIcon={<ControlPointIcon fontSize="small" color="primary" />}
                                        >
                                        Add Item
                                        </Button>
                                    </div>:null
                                }
                                <TableListComp
                                    heads={ currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ? [...te003LLPDetailHd, { label: 'Action', sortOption: false, actionCell: true }]:te003LLPDetailHd}
                                    data={generalReportInfo.list.length ? generalReportInfo.list.find(item => item.name === 'LLP Details').gap_reports.map((item,index) =>
                                        <LLPDetail
                                        currentFormDetail={currentFormDetail}
                                        key={index}
                                        index={index+1}
                                        item={item}
                                        error={error.llpDetails.length && error.llpDetails[index] ? error.llpDetails[index]:{}}
                                        onFieldChange={(event, keyParam, data) => this.onComponentChange(event, keyParam, data, 'LLP Details', index)}
                                        previewDetail={(mode) => { this.setState({deleteModal:true, deleteIds:{...item, deleteIndex: index, type:'LLP Details'}}) }}
                                        />):null
                                    }
                                    noRecord={ generalReportInfo.list.length && generalReportInfo.list.find(item => item.name === 'LLP Details').gap_reports.length ? null:
                                        <EmptyCollection title="No records found" />
                                    }
                                />
                            </div>
                        
                        }
                        <div ref={el => { this.tabEnd = el; }} />
                        <DeletePopUp
                            modal={deleteModal}
                            toggleModalFn={() => {this.setState({deleteModal:false, deleteIds:{}})}}
                            title="Delete"
                            content={<h4>Are you sure you want to delete?</h4>}
                            deleteRecordFn={this.deleteTableComFn}
                        />
                    </div>
                </div>
            </section>
        )
    }
}
export default GapReports;