import React from 'react';
import { TableRow, TableCell, TextField } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
export default function MissingHardware({currentFormDetail, item, error, onFieldChange, previewDetail}){
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>
        { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
          <TextField
            id="ata"
            fullWidth
            margin="normal"
            multiline
            rowsMax={4}
            inputProps={{ maxLength: 20}}
            value={item.ata?item.ata:''}
            onChange={(e) => onFieldChange(e, 'ata', e.target.value)}
            error={error.ata ? true:false}
            helperText={error.ata ? error.ata:''}
            InputLabelProps={{shrink: true}}
          />:
          item.ata?item.ata:'--'
        }

      </TableCell>
      <TableCell>
        { currentFormDetail &&  currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
          <TextField
            id="description"
            fullWidth
            margin="normal"
            multiline
            rowsMax={4}
            value={item.description?item.description:''}
            onChange={(e) => onFieldChange(e, 'description', e.target.value)}
            error={false}
            helperText=''
            InputLabelProps={{shrink: true}}
            inputProps={{ maxLength: 255}}
          />:
          item.description?item.description:'--'
        }

      </TableCell>
      <TableCell>
        { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
          <TextField
            id="part_number"
            fullWidth
            margin="normal"
            inputProps={{ maxLength: 20}}
            value={item.part_number?item.part_number:''}
            onChange={(e) => onFieldChange(e, 'part_number', e.target.value)}
            error={error.part_number ? true:false}
            helperText={error.part_number ? error.part_number:''}
            InputLabelProps={{shrink: true}}
          />:
          item.part_number?item.part_number:'--'
        }

      </TableCell>
      <TableCell>
        { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
          <TextField
            id="serial_number"
            fullWidth
            margin="normal"
            inputProps={{ maxLength: 20}}
            value={item.serial_number?item.serial_number:''}
            onChange={(e) => onFieldChange(e, 'serial_number', e.target.value)}
            error={error.serial_number ? true:false}
            helperText={error.serial_number ? error.serial_number:''}
            InputLabelProps={{shrink: true}}
          />:
          item.serial_number?item.serial_number:'--'
        }

      </TableCell>
      <TableCell>
        { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
          <TextField
            id="remarks"
            fullWidth
            margin="normal"
            multiline
            rowsMax={4}
            value={item.remarks?item.remarks:''}
            onChange={(e) => onFieldChange(e, 'remarks', e.target.value)}
            error={false}
            helperText=''
            InputLabelProps={{shrink: true}}
            inputProps={{ maxLength: 255}}
          />:
          item.remarks?item.remarks:'--'
        }

      </TableCell>
      { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions.can_save ?
        <TableCell className="actions-cell">
          <DeleteOutlineIcon onClick={() => previewDetail('D')} color="error" />
        </TableCell>:null
      }
    </TableRow>
  )
}
