import React, {Component, Fragment} from 'react';
import {Row} from 'reactstrap';
import {Link} from 'react-router';
import {TableUIComp, SecondarySideNav} from '../../../shared';
import { globalGetService } from '../../../globalServices'
import {Roles, RolesDetail} from '../components';
import {roleHd} from '../index';
class Role extends Component {
  constructor(props){
    super(props);
    this.state = {
      roleDetail:{
        modal:false,
      },
      roles: [],
      users: [],
      permissions: {permission: {},permission_headers:[]}
    }
  }

  componentDidMount(){
    this.getRolesList()
  }

  getRolesList = () =>{
    globalGetService(`/v2/get-sparta-groups/?application=technical`)
    .then(response => {
      if(response.data.statusCode === 200){
        this.setState({roles: response.data.data})
      }
    })
  }

  getRoleUsers = (id) => {
    globalGetService(`/v2/get-sparta-group-users/?group_id=${id}`)
    .then(response => {
      if(response.data.statusCode === 200 && response.data.data ){
        this.setState({users: response.data.data.users, roleDetail:{modal:true}})
        this.getRolePermissions(id)
      }
    })
  }

  getRolePermissions = (id) => {
    globalGetService(`/v2/get-sparta-group-permissions/?application=technical&group_id=${id}`)
    .then(response =>{
      if(response.data.statusCode === 200){
        this.setState({permissions: response.data.data})
      }
    })
  }

  render() {
    const { roleDetail, roles } = this.state;
    return(
    <section className="roles-section">
      <div className="template-heading">
          <h3>Roles</h3>
        </div>
        <SecondarySideNav/>
        <TableUIComp
            theads={roleHd}
            content={roles.map((item, index) =>
            <Roles
                item={item}
                index={index}
                onRoleDetail={() => this.getRoleUsers(item.id)}
            />
            )}
        />
        <RolesDetail
          roleDetail={roleDetail}
          toggleModalFn={() => this.setState({roleDetail:{modal:false}})}
          closeModal={() => this.setState({roleDetail:{modal: false}})}
          users={this.state.users}
          permissions={this.state.permissions}
        />
    </section>
    )
  }
}
export default Role;
