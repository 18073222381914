import React, {Component, Fragment} from 'react';
import {Row, Col, UncontrolledTooltip } from 'reactstrap';
import { FieldCol, FieldGroup, FieldLabel } from '../../../applications/technicalInspection/Elements_V';
import { EditFormBar, DeleteModal } from '../../../applications/technicalInspection/Elements';
import {Link} from 'react-router';
import Select from 'react-select';
import userIcon from '../../../shared/assets/img/user.svg';
import greenTickIcon from '../../../shared/assets/img/greenTick.svg';
import deactivateIcon from '../../../shared/assets/img/deactivate.svg';
import createIcon from '../../../shared/assets/img/edit_icon.svg';
import hightlightOffIcon from '../../../shared/assets/img/highlight_off_icon.svg';
import { getLocalStorageInfo } from '../../../utils'
import moment from 'moment'
class User extends Component {
  constructor(props){
    super(props)
    this.state = {
        editUser: false,
        modalDelete: false,
        activeUser: {},
        roleId: ''
    }
  }
  toggleDeleteModal = () => {
    this.setState({
      deleteModal: !this.state.deleteModal,
      activeUser: {}
    });
  }
  render() {
    return(
        <>
        <Row className="flex-not-centered">
            {
              this.props.users.list.map(user =>
                <Col md="3" className="flex-not-centered">
                    <div className="user-card text-center">
                      {
                        getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.console && getLocalStorageInfo().user.permission.console.manage_user ?
                        <ul className="list-inline">
                          {
                            getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.console && getLocalStorageInfo().user.permission.console.manage_user && (getLocalStorageInfo().user.permission.console.manage_user.indexOf('U') != -1 || getLocalStorageInfo().user.permission.console.manage_user.indexOf('C') != -1) ?
                            <li className="list-inline-item">
                                <img className="change-role" src={createIcon} alt="icon" id="changeRole" onClick={()=> this.setState({editUser: true, activeUser: user, roleId: ''})}/>
                                <UncontrolledTooltip placement="down" target="changeRole">
                                    Change Role
                                </UncontrolledTooltip>
                            </li>
                            :null
                          }
                          {
                            getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.console && getLocalStorageInfo().user.permission.console.manage_user && getLocalStorageInfo().user.permission.console.manage_user.indexOf('D') != -1 ?
                            <li className="list-inline-item">
                                <img className="remove" src={hightlightOffIcon} alt="icon" id="remove" onClick={()=> this.setState({deleteModal: true, activeUser: user})}/>
                                <UncontrolledTooltip placement="down" target="remove">
                                    Remove
                                </UncontrolledTooltip>
                            </li>
                            :null
                          }
                        </ul>
                        :null
                      }
                        <span className="user-pic">
                            <img className="profile-pic"src={this.state.activeUser.profile_pic ? this.state.activeUser.profile_pic : userIcon} alt="user icon"/>
                            <img className="account-status" src={greenTickIcon} alt="icon" />
                        </span>
                        <div className="user-name-desg">
                            <h3>{user.first_name + " " + user.last_name}</h3>
                            <p>{user.designation}</p>
                        </div>
                        <div className="user-other-info">
                            <h6>{user.email}</h6>
                            <p>Last Login On: {moment(user.last_login).format('HH:MM A, DD-MM-YYYY')}</p>
                        </div>
                    </div>
                </Col>
              )
            }
        </Row>
        <EditFormBar
            titleContent= 'Change Role'
            toggleEditSideBar={()=> this.setState({editUser: false})}
            style={this.state.editUser ? {width: '400px'} : {width: '0px'}}
            isOpen={this.state.editUser}
        >
        <form >
          <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
            <Col md="12">
                <div class="user-info">
                    <h4>{this.state.activeUser.first_name + " " + this.state.activeUser.last_name}</h4>
                    <p>{this.state.activeUser.email}</p>
                    <span>{this.state.activeUser.sparta_group ? this.state.activeUser.sparta_group : '--'}</span>
                </div>
            </Col>
            <FieldCol md="12" style={{margin:"10px"}}>
              <FieldGroup className="form-group">
                <FieldLabel className="label">Select Role</FieldLabel>
                  <Select
                    value={this.state.roleId}
                    isClearable={true}
                    onChange={(option) => this.setState({roleId: option.id})}
                    options={this.props.roles}
                    className="custom-select-block"
                    placeholder="Select Role"
                    valueKey="id"
                    labelKey="name"
                    multi={false}
                 />
              </FieldGroup>
            </FieldCol>
          </Row>
          </div>
          <Row>
            <div className="submit-block" style={{position:"relative", top:'-41px', marginLeft: '15px'}} >
              <input type="button" onClick={() => {this.props.updateUserPermission(this.state.activeUser, this.state.roleId);this.setState({editUser: false})}} className="primary-btn" value="APPLY"/>
              <Link style={{color:'#3f51b5', marginLeft: '10px'}} onClick={() => this.setState({editUser: false})}>CANCEL</Link>
            </div>
          </Row>
        </form>
      </EditFormBar>
      <DeleteModal isOpen={this.state.deleteModal}
        toggle={() => this.toggleDeleteModal()}
        title={`Remove User "${this.state.activeUser.first_name} ${this.state.activeUser.last_name}"`}>
          <ul className="list-inline">
            <li className="list-inline-item">
              <button type="button" className="btn btn-danger" onClick={() => this.setState({deleteModal: false, activeUser: {}})}>CANCEL</button>
            </li>
            <li className="list-inline-item">
              <button type="button" style={{backgroundColor:'#3f51b5'}} className="btn btn-primary" onClick={() => {this.props.revokeUser(this.state.activeUser);this.setState({deleteModal: false, activeUser: {}});}}>REMOVE</button>
            </li>
          </ul>
        </DeleteModal>
      </>
    )
  }
}
export default User;
