import React, { Component } from 'react';
import ShopVisitView from "./ShopVisitView";
import EngineShopList from "./EngineShopList";
import {NoRecordFound,TableUIComp} from '../../../../shared';
import { Link } from 'react-router';
import BulkOperationShopVisit from "./BulkOperationShopVisit";

export default class ShopVisitDetails  extends Component {
  constructor(props) {
    super(props);
  }

  updateForm = () => {

  }
  render() {
  const { content,error } = this.props;
  return(
    <div className="general-detail-block" style={{background: '#fff'}}>
      <div className="bulk-operation" >
          <Link className="add-attachment" style={{color:'#3f51b5'}} onClick={()=>this.props.addShopVisit()}>+ Add Shop Visit </Link>
      </div>
      { content.shop_visits && content.shop_visits.length ?content.shop_visits.map((shopDetails,index) =>
        <div style={{marginBottom:'80px'}}>
          <h3 style={{fontSize: '18px', marginBottom: '10px', color: '#2f8cff'}}>ShopVisit #{index+1}</h3>
          <div style={{border:'1px solid #eeeeee',padding:'20px',}}>
            <ShopVisitView
              type = { 'edit' }
              shopVisit = {shopDetails}
              index={index}
              toggleDelModal={this.props.toggleDelModal}
              svError={this.props.svError}
              updateForm = {this.props.updateForm}
            />
            <TableUIComp
              noStickyHeader={true}
              hover={true}
              tableBulkOp = {<BulkOperationShopVisit GenUrlFn = {this.props.GenUrlFn} index={index} addText= "Create" addComponent= {() => ''}/>}
              filter={false}
              tableHeight= "auto"
              theads={[{label:'File Name', sortKey: ''}, {label:'Description', sortKey: ''}, {label:'Uploaded on', sortKey: '' },{label:'Uploaded by', sortKey: ''}, {label: 'Action', sortKey: ''}]}
              bulkOperation={false}
              pagination={false}
              content={shopDetails.attachments&& shopDetails.attachments.length ? shopDetails.attachments.map((contentDetails, childIndex) => <EngineShopList index={childIndex} shopVisit = {contentDetails} key={index} parentIndex={index} updateForm = {this.props.updateForm}  toggleDelModal={this.props.toggleDelModal} />)  : null}
              recordsFound = {shopDetails.attachments&& shopDetails.attachments.length ?false:true}
            />
          </div>
        </div>
        ):<NoRecordFound/>}
      </div>
    );
  }
}
