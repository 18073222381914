import { regexConstants, errorMsg1 } from '../../constants/regEx';
export const te005LLPDetailHd = [
    { label: 'SI No.', sortOption: false },
    { label: 'LLP Description', sortOption: false },
    { label: 'P/N', sortOption: false },
    { label: 'S/N', sortOption: false },
    { label: 'LLP CSN', sortOption: false },
    { label: 'Cycles Remaining', sortOption: false },
    { label: 'Status', sortOption: false },
    { label: 'Comments', sortOption: false },
];
export const llpDetail005 = {
    delAttachmentModal: false,
    deleteId: '',
    deleteIndex: '',
    preReqModal: false,
    deleteType: '',
    description : '',
    part_number: '',
    serial_number: '',
    csn: '',
    cycles_remaining: '',
    status: '',
    remarks:'',
    engine_work_order: ''
}
export const errorCode = {

}
