import { regexConstants, errorMsg1 } from '../../constants/regEx';
export const recordDetail = {
  description: null,
  acumen_remarks: null,
  status: 1,
  comments:null,
  ref_document: null,
  operator_comments: null,
  engine_gap_report_section: null,
  engine_work_order: null
}
export const llpDetail = {
  description: null,
  part_number: null,
  serial_number: null,
  cycles_remaining: null,
  acumen_remarks: null,
  status: 1,
  comments:null,
  ref_document: null,
  operator_comments: null,
  engine_gap_report_section: null,
  engine_work_order: null
}

export const te003RecordDetailHd = [
  { label: 'Item No', sortOption: false },
  { label: 'Description', sortOption: false },
  { label: 'Remarks	', sortOption: false },
  { label: 'Status', sortOption: false },
  { label: 'Comments/Notes	', sortOption: false },
  { label: 'Ref Documents	', sortOption: false },
  { label: 'Operator Comments	', sortOption: false },
];
export const te003LLPDetailHd = [
  { label: 'Item No', sortOption: false },
  { label: 'Description', sortOption: false },
  { label: 'P/N', sortOption: false },
  { label: 'S/N', sortOption: false },
  { label: 'Cycle Remaining', sortOption: false },
  { label: 'Remarks', sortOption: false },
  { label: 'Status', sortOption: false },
  { label: 'Comments/Notes', sortOption: false },
  { label: 'Ref Documents', sortOption: false },
  { label: 'Operator Comments', sortOption: false },
];
export const errorCode = {
  report_date:{
    0:'',
    1: 'Please enter Report Date',
  },
  report_dateObj:{
    required: true,
  },
  report_issued_place:{
    0:'',
    1: 'Please enter Report Issued Place'
  },
  report_issued_placeObj:{
    required: true
  },
}
