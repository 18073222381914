export const statusOptions = [{label:'Open',value:0},{label:'Closed',value:1}, {label:'Reopen',value:2}, {label:'Unresolved',value:3}];
export const prsHds = [
    {id:'',label:'Issue', sortOption:false},
    {id:'',label:'Attachment(s)	', sortOption:false},
    {id:'',label:'Category', sortOption:false},
    {id:'',label:'Priority', sortOption:false},
    {id:'',label:'Status', sortOption:false},
    {id:'',label:'Status Updated By', sortOption:false},
    {id:'',label:'Assignee', sortOption:false},
    {id:'',label:'Discrepancy Raised on', sortOption:false},
    {id:'',label:'Created By', sortOption:false},
    {id:'',label:'P/N', sortOption:false},
    {id:'',label:'S/N', sortOption:false},
    {id:'',label:'Action', sortOption:false}
];
export const addNewIssueObj = {
    title:'',
    description:'',
    priority:null,
    status:null,
    assignee:null,
    started_date:null,
    estimated_completion_date:null,
    category:null,
    ata:'',
    cycles_remaining:null,
    part_number:'',
    serial_number:'',
    install_date:null,
    position:'',
    requirement_remarks:'',
    background_remarks:'',
    attachment:[],
    disposition:0
}
export const prsFilters = {
    priority: {
        inputType: 'dropdown',
        placeholder: 'Please Select Priority',
        title: 'Priority',
        options: [{ label: 'None', value: 0 }, { label: 'P1', value: 1 }, { label: 'P2', value: 2 }],
        labelKey: 'label',
        valueKey: 'value'
    },
    assignee: {
        inputType: 'dropdown',
        placeholder: 'Please Select Assignee',
        title: 'Assignee',
        options: [],
        labelKey: 'name',
        valueKey: 'id'
    },
    created_by: {
        inputType: 'dropdown',
        placeholder: 'Please Select Created By',
        title: 'Created By',
        options: [],
        labelKey: 'name',
        valueKey: 'id'
    },
    tags: {
        inputType: 'dropdown',
        placeholder: 'Please Select Tags',
        title: 'Tags',
        multiple: true,
        options: [],
        labelKey: 'name',
        valueKey: 'id'
    },
    created_at_from: {
        inputType: 'date',
        placeholder: 'Please Select Discrepancy Raised on (from)',
        title: 'Discrepancy Raised on (from)',
    },
    created_at_to: {
        inputType: 'date',
        placeholder: 'Please Select Discrepancy Raised on (to)',
        title: 'Discrepancy Raised on (to)',
    },
    section: {
        inputType: 'dropdown',
        placeholder: 'Please Select Category',
        title: 'Category',
        options: [],
        labelKey: 'name',
        valueKey: 'id'
    }, 
    part_number: {
        inputType: 'text',
        placeholder: 'Please enter Part Number',
        title: 'Part Number',
    },
    serial_number: {
        inputType: 'text',
        placeholder: 'Please enter Serial Number',
        title: 'Serial Number',
    },
    ata: {
        inputType: 'text',
        placeholder: 'Please enter ATA',
        title: 'ATA',
    }, 
}