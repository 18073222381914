import moment from "moment"
import {
  globalGetService,
  globalExportService,
  globalPostService,
  globalPutService,
  globalDeleteService,
} from "../../utils_v2/globalApiServices"
import {
  checkApiStatus,
  downloadFileType,
  getLocalStorageInfo,
  dataURItoBlob,
} from "../../utils_v2"
import { fieldValidation } from "../../utils_v2/formValidation"
import { regexConstants } from "../../constants/regEx"
import { errorCode, aircraftWorkOrderErrorCode } from "./"

export function getProjectInitiateApi() {
  return new Promise(function(resolve, reject) {
    globalGetService(`technical/project-initiate/`).then((response) => {
      if (checkApiStatus(response)) {
        resolve(response)
      } else {
        reject(response)
      }
    })
  })
}

export function getProjectUsersApi(props = {}, query = {}) {
  return new Promise(function(resolve, reject) {
    globalGetService(`technical/users/`, query).then((response) => {
      resolve(response)
      // if(checkApiStatus(response)){
      //   if(query.role === 'lead'){
      //     this.setState({projectLeads: response.data.data});
      //   }
      //   if(query.role === 'engineer'){
      //     this.setState({projectEngineers: response.data.data});
      //   }
      //   if(query.role === 'member'){
      //     this.setState({projectMembers: response.data.data})
      //   }
      // }
    })
  })
  globalGetService(`technical/users/`, query).then((response) => {
    if (checkApiStatus(response)) {
      if (query.role === "lead") {
        this.setState({ projectLeads: response.data.data })
      }
      if (query.role === "engineer") {
        this.setState({ projectEngineers: response.data.data })
      }
      if (query.role === "member") {
        this.setState({ projectMembers: response.data.data })
      }
    }
  })
}

export function getProjectsApi(props = {}, query = {}, loaderType) {
  this.setState({ [loaderType]: true })
  globalGetService(`technical/projects/`, query).then((response) => {
    this.setState({ [loaderType]: false })
    if (checkApiStatus(response)) {
      delete query.sort
      delete query.sort_by
      delete query.page
      delete query.per_page
      delete query.project_status
      this.setState({
        projectsInfo: response.data.data,
        filter: query,
      })
    }
  })
}
  export function exportProjectsApi(props={}, query={}, file){
    this.props.enqueueSnackbar('Downloading Projects Report ...', {autoHideDuration:30000, variant: 'info', anchorOrigin:{vertical: 'bottom',horizontal: 'right'}});
    globalExportService(`technical/projects/`,{...query, download:file.extension})
    .then(response => {
      downloadFileType(response, (`Project_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD.')}`), file.extension);
      this.props.closeSnackbar();
      this.props.enqueueSnackbar('Projects Report Downloaded', {autoHideDuration:2000, variant: 'success', anchorOrigin:{vertical: 'bottom',horizontal: 'right'}});
    })
}

export function getProjectDetailsApi(props={}, projectSlug, query={}){
  this.setState({pageLoader:true});
  globalGetService(`technical/projects/${ projectSlug ? projectSlug:props.params.projectSlug}/`,query)
  .then(response => {
    this.setState({pageLoader:false});
    let res = response.data.data;
    delete res['assets'];
    res = {
      ...res,
      lessee_id:res.lessee && res.lessee.id ? res.lessee.id : null,
      department:res.department && res.department.value ? res.department.value : null, 
    }
    if(checkApiStatus(response)){
      this.setState({
        projectCrud: res,
        projectModal:true
      })
    }
  })
}

export function addEditProjectApi(props = {}, data = {}) {
  let clientContactFlag = true
  let clientContactError = []
  let operatorContactFlag = true
  let operatorContactError = []
  let thirdPartyContactFlag = true
  let thirdPartyContactError = []
  let validationInputs = {
    name:
      errorCode["name"][
        fieldValidation({ ...errorCode["nameObj"], fieldval: data.name })
      ],
    start_date:
      errorCode["start_date"][
        fieldValidation({
          ...errorCode["start_dateObj"],
          fieldval: data.start_date,
        })
      ],
    expected_end_date:
      errorCode["expected_end_date"][
        fieldValidation({
          ...errorCode["expected_end_dateObj"],
          fieldval: data.expected_end_date,
        })
      ],
    leads:
      errorCode["leads"][
        fieldValidation({ ...errorCode["leadsObj"], fieldval: data.leads })
      ],
    engineers:
      errorCode["engineers"][
        fieldValidation({
          ...errorCode["engineersObj"],
          fieldval: data.engineers,
        })
      ],
    project_remarks:
      data.project_form === 1
        ? errorCode["project_remarks"][
            fieldValidation({
              ...errorCode["project_remarksObj"],
              fieldval: data.project_remarks,
            })
          ]
        : "",
  }
  clientContactError = data.client_contacts.map((item) => {
    return {
      email:
        item.email && item.email.trim() !== ""
          ? regexConstants.email.test(item.email)
            ? ""
            : "Valid email only"
          : "",
      phone_number:
        item.phone_number && item.phone_number.trim() !== ""
          ? regexConstants.phoneNumber.test(item.phone_number)
            ? ""
            : "Valid phone number only"
          : "",
    }
  })
  clientContactError.map((data) => {
    if (
      !Object.keys(data).every((k) => {
        return data[k] === ""
      })
    ) {
      clientContactFlag = false
    }
  })
  operatorContactError = data.operator_contacts.map((item) => {
    return {
      email:
        item.email && item.email.trim() !== ""
          ? regexConstants.email.test(item.email)
            ? ""
            : "Valid email only"
          : "",
      phone_number:
        item.phone_number && item.phone_number.trim() !== ""
          ? regexConstants.phoneNumber.test(item.phone_number)
            ? ""
            : "Valid phone number only"
          : "",
    }
  })
  operatorContactError.map((data) => {
    if (
      !Object.keys(data).every((k) => {
        return data[k] === ""
      })
    ) {
      operatorContactFlag = false
    }
  })
  thirdPartyContactError = data.third_party_contacts.map((item) => {
    return {
      email:
        item.email && item.email.trim() !== ""
          ? regexConstants.email.test(item.email)
            ? ""
            : "Valid email only"
          : "",
      phone_number:
        item.phone_number && item.phone_number.trim() !== ""
          ? regexConstants.phoneNumber.test(item.phone_number)
            ? ""
            : "Valid phone number only"
          : "",
    }
  })
  thirdPartyContactError.map((data) => {
    if (
      !Object.keys(data).every((k) => {
        return data[k] === ""
      })
    ) {
      thirdPartyContactFlag = false
    }
  });
  
  if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''}) && clientContactFlag && operatorContactFlag && thirdPartyContactFlag){
    this.setState({formSubmitLoader:true});
    if(data.id){
      globalPutService(`technical/projects/${data.slug}/`, data)
      .then(response => {
        this.setState({formSubmitLoader:false});
        if(checkApiStatus(response)){
          window.location.reload();
        }
    })
    } else {
      let formData = new FormData()
      Object.keys(data).map((item) => {
        if (typeof data[item] == "object" && item != "files") {
          formData.append(item, JSON.stringify(data[item]))
        } else if (item === "files" && data.files) {
          let index = 1
          for (let file of data.files) {
            formData.append("file_" + index, file)
            index++
          }
        } else {
          formData.append(item, data[item])
        }
      })
      globalPostService(`technical/projects/`, formData).then((response) => {
        this.setState({ formSubmitLoader: false })
        if (checkApiStatus(response)) {
          props.history.push(`/project/view/${response.data.data.slug}`)
        }
      })
    }
  } else {
    let error = validationInputs
    error = {
      ...error,
      client_contacts: clientContactError,
      operator_contacts: operatorContactError,
      third_party_contacts: thirdPartyContactError,
    }
    this.setState((prevState) => ({
      ...prevState,
      project: {
        ...prevState.project,
        error: error,
      },
    }))
  }
}

export function getProjectFormsApi(props = {}, query = {}) {
  globalGetService(`technical/forms/`, query).then((response) => {
    if (query && query.inspection_type) {
      this.setState((prevState) => ({
        ...prevState,
        workOrderInfo: {
          ...prevState.workOrderInfo,
          data: {
            ...prevState.workOrderInfo.data,
            forms: response.data.data.map((form) => form.id),
          },
        },
      }))
    } else if (query && query.both) {
      this.setState({ allForms: response.data.data })
    } else {
      this.setState({ forms: response.data.data })
    }
  })
}

export function getPlatformConstApi(data) {
  return new Promise(function(resolve, reject) {
    globalPostService(`console/get-constants/`, data).then((response) => {
      resolve(response)
    })
  })
}
