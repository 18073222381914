import React, {useState} from 'react';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import { Button, Dialog, Grid, TextField, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@material-ui/core';
import { globalPostService, globalPutService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus } from '../../../utils_v2';
import { regexConstants } from '../../../constants/regEx';
const AddEditSection = ({params, sectionConfig, toggleModalFn, getResponseBack})  => {
    const [isLoading, setLoading] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [section, setSection] = useState(sectionConfig.data)
    const onFieldChange = (e, keyParam, value) => {
        setSection({...section, [keyParam]: value});
    }
    const onAddEditSection = () => {
        if(section.name){
            if(section.id){
                if(section?.pictures.length > section.max_limit){
                    enqueueSnackbar('Max limit cannot be less than the no. of photos uploaded ', { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                }
                else{
                setLoading(true);
                globalPutService(`technical/v2/workorder/${params.workOrderSlug}/sections/?type=T002`, section)
                .then(response => {
                    if(checkApiStatus(response)){
                        setLoading(false);
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                        toggleModalFn();
                        getResponseBack();
                    }else{
                        enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                    }
                })
            }
            }else{
                setLoading(true);
                globalPostService(`technical/v2/workorder/${params.workOrderSlug}/sections/?type=T002`,section)
                .then(response => {
                    if(checkApiStatus(response)){
                        setLoading(false);
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                        toggleModalFn();
                        getResponseBack();
                    }else{
                        enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                    }
                })
            }
        }else{
            enqueueSnackbar('Section Name is mandatory field', { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
        }        
    }
    return(
        <Dialog
            open={sectionConfig.modal}
            onClose={toggleModalFn}
            className='project-management-modal'
        >
            <DialogTitle id="scroll-dialog-title">
                {section.id ? 'Edit':'Add'} Section
            </DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item md={6}>
                        <TextField
                            required
                            id='name'
                            label='Name'
                            fullWidth
                            margin="normal"
                            value={section.name?section.name:''}
                            onChange={(e) => onFieldChange(e, 'name', e.target.value)}
                            InputLabelProps={{shrink: true}}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            id='max_limit'
                            label='Picture(s) Max Limit'
                            fullWidth
                            margin="normal"
                            value={section.max_limit?section.max_limit:''}
                            onChange={(e) => {regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'max_limit', e.target.value): e.preventDefault()}}
                            helperText=''
                            InputLabelProps={{shrink: true}}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            multiline
                            maxRows={3}
                            id='description'
                            label='Description'
                            fullWidth
                            margin="normal"
                            value={section.description?section.description:''}
                            onChange={(e) => onFieldChange(e, 'description', e.target.value)}
                            helperText=''
                            InputLabelProps={{shrink: true}}
                            variant='outlined'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color="primary" size='small' variant='outlined'>Cancel</Button>
                <Button disabled={isLoading} onClick={onAddEditSection} color="primary" size='small' variant='contained'>
                    {isLoading ? <CircularProgress size={20} /> : 'SAVE' }
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default withRouter(AddEditSection);