import React from "react"
import ReactDOM from "react-dom"
import configureStore from "./store/configureStore"
import { Provider } from "react-redux"
import { SnackbarProvider } from "notistack"
import { Router, Route, IndexRoute, browserHistory } from "react-router"
import { UsersList } from "./User"
import { projectsRoutes } from "./projects_management/routes"
import {
	ProjectCorForm,
	ProjectFeedbackForm,
	CreateTechnicalEngine,
	InspectionFormT001,
	InspectionFormT002,
	InspectionFormT003,
	InspectionFormT004,
	InspectionFormT005,
	InspectionFormT006,
	InspectionFormT007,
	InspectionFormT008,
	InspectionFormT009,
	InspectionFormT011,
	InspectionFormTE001,
	InspectionFormTE002,
	InspectionFormTE003,
	InspectionFormTE004,
	InspectionFormTE005,
	InspectionFormTE006,
	ProjectTracker,
	CorTracker,
	ManPowerTracker,
	ProgramScheduler,
	ProgramTracker,
	ReviewT001,
	ReviewT002,
	ReviewT003,
	ReviewT004,
	ReviewT005,
	ReviewT006,
	ReviewT007,
	ReviewT009
 } from './applications/technicalInspection';
import { Role, User } from './applications/settings/containers';
import {AnalyticTracker,ProjectsAnalyticTracker, ResourcesAnalyticTracker  } from './applications/tracker/containers';
import { InspectionFleetDb } from './applications/dashboards';
import { PageNotFound, NoAccessApps } from './shared';
import Faqs from './shared/components/Faqs';
import FeaturesList from './shared/components/FeaturesList';
import App from './App';
import TechnicalAssetReport from './TechnicalAssetReport';
import requireAuth from './utils/requireAuth';
import LoggedUserRed from './utils/LoggedUserRed';
import { Onboarding } from './phase2/auth';
import SecurityPermissions from './User/containers/SecurityPermissions';
import Tags from './User/containers/Tags';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
const store = configureStore();
const theme = createTheme({
  typography: {
    fontFamily: "Conv_IBMPlexSans-Regular",
  },
})
ReactDOM.render(
	<div>
	<Provider store={store}>
		<ThemeProvider theme={theme}>
		<SnackbarProvider maxSnack={3}>
		<Router history={browserHistory}>
			<Route path="/" component={App}>
				<IndexRoute component={requireAuth(InspectionFleetDb)}/>
				<Route path="/login" component={LoggedUserRed(Onboarding)} />
				<Route path="/select-lessor" component={requireAuth(Onboarding)} />
				<Route path="/restricted-access/:type" component={requireAuth(NoAccessApps)} />
				<Route path="/technical/project/create-engine/:project_slug" component={requireAuth(CreateTechnicalEngine)}/>
				<Route path="/technical/project/:project_slug/engine/edit/:workOrderSlug" component={requireAuth(CreateTechnicalEngine)}/>
				<Route path="/technical/project/view/:project_slug/project-cor-form" component={requireAuth(ProjectCorForm)} />
				<Route path="/technical/project/view/:project_slug/project-feedback-form" component={requireAuth(ProjectFeedbackForm)} />
				{/* <Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/t001" component={requireAuth(InspectionFormT001)} /> */}
				{/* <Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/t002" component={requireAuth(InspectionFormT002)} /> */}
				{/* <Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/v_t003" component={requireAuth(InspectionFormT003)} /> */}
				{/* <Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/t004" component={requireAuth(InspectionFormT004)} /> */}
				<Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/t005" component={requireAuth(InspectionFormT005)} />
				<Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/t007" component={requireAuth(InspectionFormT007)} />
				<Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/t008" component={requireAuth(InspectionFormT008)} />
				{/* <Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/t011(/:tab_name)" component={requireAuth(InspectionFormT011)} /> */}
				<Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/te001" component={requireAuth(InspectionFormTE001)} />
				<Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/te002" component={requireAuth(InspectionFormTE002)} />
				<Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/te003" component={requireAuth(InspectionFormTE003)} />
				<Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/te004" component={requireAuth(InspectionFormTE004)} />
				<Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/te005" component={requireAuth(InspectionFormTE005)} />
				<Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/te006" component={requireAuth(InspectionFormTE006)} /> 
				<Route path="/:type/:aircraft_slug/technical/review/:workOrderSlug/forms/:formSlug/t001" component={requireAuth(ReviewT001)} />
				<Route path="/:type/:aircraft_slug/technical/review/:workOrderSlug/forms/:formSlug/t002" component={requireAuth(ReviewT002)} />
				<Route path="/:type/:aircraft_slug/technical/review/:workOrderSlug/forms/:formSlug/t003" component={requireAuth(ReviewT003)} />
				<Route path="/:type/:aircraft_slug/technical/review/:workOrderSlug/forms/:formSlug/t004" component={requireAuth(ReviewT004)} />
				<Route path="/:type/:aircraft_slug/technical/review/:workOrderSlug/forms/:formSlug/t005" component={requireAuth(ReviewT005)} />
				<Route path="/:type/:aircraft_slug/technical/review/:workOrderSlug/forms/:formSlug/t006" component={requireAuth(ReviewT006)} />
				<Route path="/:type/:aircraft_slug/technical/review/:workOrderSlug/forms/:formSlug/t007" component={requireAuth(ReviewT007)} />
				<Route path="/:type/:aircraft_slug/technical/review/:workOrderSlug/forms/:formSlug/t009" component={requireAuth(ReviewT009)} />
				<Route path="/technical/projects/tracker" component={requireAuth(ProjectTracker)} />
				<Route path="/technical/cor/tracker" component={requireAuth(CorTracker)} />
				<Route path="/technical/man-power/tracker" component={requireAuth(ManPowerTracker)} />
				<Route path="/fleet/technical/dashboard" component={requireAuth(InspectionFleetDb)} />
				<Route path="/users/manage" component={requireAuth(UsersList)} />
				<Route path="/users/permissions" component={requireAuth(SecurityPermissions)} />
				<Route path="/users/tags" component={requireAuth(Tags)} />
				<Route path="/faqs" component={requireAuth(Faqs)} />
				<Route path="/whats-new" component={requireAuth(FeaturesList)} />
				<Route path="/analytics" component={requireAuth(AnalyticTracker)} />
				<Route path="/analytics/projects" component={requireAuth(ProjectsAnalyticTracker)} />
				<Route path="/analytics/resources" component={requireAuth(ResourcesAnalyticTracker)} />
				<Route path="/technical/program-planner" component={requireAuth(ProgramScheduler)} />
				<Route path="/technical/program-planner/create/program" component={requireAuth(ProgramScheduler)} />
				<Route path="/technical/program-planner/assets" component={requireAuth(ProgramScheduler)} />
				<Route path="/technical/program-planner/assets/program/:programId" component={requireAuth(ProgramScheduler)} />
				<Route path="/technical/program-planner/program/:programId" component={requireAuth(ProgramScheduler)} />
				<Route path="/technical/program-planner/program/:programId/details" component={requireAuth(ProgramScheduler)} />
				<Route path="/technical/program-planner/program/:programId/tracker" component={requireAuth(ProgramTracker)} />
				{ projectsRoutes.map((route) =>
					<Route key={route.key} path={route.path} component={route.component} />
				)}	
			</Route>
			<Router path="/techical/asset/report/:shareKey" component={TechnicalAssetReport}/>
			<Route path='*' exact={true} component={PageNotFound} />
		</Router>
		</SnackbarProvider>
		</ThemeProvider>
	</Provider></div>,
	document.querySelector('.root')
);
